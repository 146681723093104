import type { NodeJSONRaw, Normalizer } from '../types';

export const flattenClaimPart: Normalizer = (input, instance) => {
  if (input.type !== 'claim') {
    return {
      ...input,
      content:
        input.content?.map((child) => flattenClaimPart(child, instance)) ??
        null,
    };
  }

  if (!input.content) {
    return input;
  }

  const result: NodeJSONRaw[] = [];

  function flattenNested(part: NodeJSONRaw) {
    const flattenedPart = {
      ...part,
      content: Array.isArray(part.content)
        ? [...part.content].filter((child) => child.type !== 'claimPart')
        : part.content,
    };

    result.push(flattenedPart);

    if (part.content) {
      part.content.forEach((child) => {
        if (child.type === 'claimPart') {
          flattenNested(child);
        }
      });
    }
  }

  input.content.forEach((part) => {
    flattenNested(part);
  });

  return {
    ...input,
    content: result,
  };
};
