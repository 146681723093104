import { OBJECTIONS_LEGAL_BASIS } from '../../../constants';
import { ResponseLetterSection } from '../../../types';

type Annotation = {
  key: string;
  style: {
    backgroundColor?: string;
    legendColor?: string;
    underlineColor?: string;
  };
  parentKey?: string;
  legalBasis?: string;
};

const objectionStyle = {
  style: {
    backgroundColor: '#FF96D2',
    legendColor: '#FFDAEF',
  },
};

const objectionChildrenProps = {
  ...objectionStyle,
  parentKey: 'OBJECTIONS',
};

const usptoObjectionChildrenProps = {
  ...objectionStyle,
  parentKey: 'USPTO_OBJECTIONS',
};

const inventivenessChildrenProps = {
  parentKey: 'INVENTIVENESS',
  style: {
    underlineColor: '#FF96D2',
  },
};

const INVENTIVENESS_CHILDREN_ANNOTATIONS: Annotation[] = [
  {
    key: 'CLOSEST_PRIOR_ART',
    ...inventivenessChildrenProps,
  },
  {
    key: 'DISTINGUISHING_FEATURES',
    ...inventivenessChildrenProps,
  },
  {
    key: 'TECHNICAL_EFFECTS',
    ...inventivenessChildrenProps,
  },
  {
    key: 'OBJECTIVE_TECHNICAL_PROBLEMS',
    ...inventivenessChildrenProps,
  },
  {
    key: 'INVENTIVE_STEP_AND_SKILLED_PERSON',
    ...inventivenessChildrenProps,
  },
];

const OBJECTIONS_CHILDREN_ANNOTATIONS = [
  {
    key: 'UNSUPPORTED_AMENDMENTS',
    ...objectionChildrenProps,
  },
  {
    key: 'NOVELTY',
    ...objectionChildrenProps,
  },
  {
    key: 'INVENTIVENESS',
    ...objectionChildrenProps,
  },
  {
    key: 'CLARITY',
    ...objectionChildrenProps,
  },
  {
    key: 'INSUFFICIENT_DISCLOSURE',
    ...objectionChildrenProps,
  },
  {
    key: 'PATENTABILITY',
    ...objectionChildrenProps,
  },
  {
    key: 'INDUSTRIAL_APPLICABILITY',
    ...objectionChildrenProps,
  },
  {
    key: 'NON_UNITY',
    ...objectionChildrenProps,
  },
  {
    key: 'FORMALITIES',
    ...objectionChildrenProps,
  },
];

const USPTO_OBJECTIONS_CHILDREN_ANNOTATIONS = [
  {
    key: 'USPTO_ANTICIPATION',
    ...usptoObjectionChildrenProps,
    legalBasis: OBJECTIONS_LEGAL_BASIS[ResponseLetterSection.UsptoAnticipation],
  },
  {
    key: 'USPTO_INDEFINITENESS',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[ResponseLetterSection.UsptoIndefiniteness],
  },
  {
    key: 'USPTO_FORMALITIES_INFORMATION_DISCLOSURE_STATEMENT',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[
        ResponseLetterSection.UsptoFormalitiesInformationDisclosureStatement
      ],
  },
  {
    key: 'USPTO_PRIORITY',
    ...usptoObjectionChildrenProps,
    legalBasis: OBJECTIONS_LEGAL_BASIS[ResponseLetterSection.UsptoPriority],
  },
  {
    key: 'USPTO_FORMALITIES_INVENTORSHIP',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[
        ResponseLetterSection.UsptoFormalitiesInventorship
      ],
  },
  {
    key: 'USPTO_DOUBLE_PATENTING_STATUTORY',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[
        ResponseLetterSection.UsptoDoublePatentingStatutory
      ],
  },
  {
    key: 'USPTO_FORMALITIES_TITLE',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[ResponseLetterSection.UsptoFormalitiesTitle],
  },
  {
    key: 'USPTO_FORMALITIES_IMPROPER_MARKUSH',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[
        ResponseLetterSection.UsptoFormalitiesImproperMarkush
      ],
  },
  {
    key: 'USPTO_DOUBLE_PATENTING_OBVIOUSNESS',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[
        ResponseLetterSection.UsptoDoublePatentingObviousness
      ],
  },
  {
    key: 'USPTO_FORMALITIES_SPECIFICATION',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[
        ResponseLetterSection.UsptoFormalitiesSpecification
      ],
  },
  {
    key: 'USPTO_FORMALITIES_SEQUENCES',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[ResponseLetterSection.UsptoFormalitiesSequences],
  },
  {
    key: 'USPTO_WRITTEN_DESCRIPTION_ENABLEMENT_BEST_MODE',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[
        ResponseLetterSection.UsptoWrittenDescriptionEnablementBestMode
      ],
  },
  {
    key: 'USPTO_PLANT',
    ...usptoObjectionChildrenProps,
    legalBasis: OBJECTIONS_LEGAL_BASIS[ResponseLetterSection.UsptoPlant],
  },
  {
    key: 'USPTO_FORMALITIES_GENERAL',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[ResponseLetterSection.UsptoFormalitiesGeneral],
  },
  {
    key: 'USPTO_SUBJECT_MATTER_ELIGIBILITY',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[
        ResponseLetterSection.UsptoSubjectMatterEligibility
      ],
  },
  {
    key: 'USPTO_RESTRICTION_ELECTION',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[ResponseLetterSection.UsptoRestrictionElection],
  },
  {
    key: 'USPTO_OBVIOUSNESS',
    ...usptoObjectionChildrenProps,
    legalBasis: OBJECTIONS_LEGAL_BASIS[ResponseLetterSection.UsptoObviousness],
  },
  {
    key: 'USPTO_FORMALITIES_CLAIMS',
    ...usptoObjectionChildrenProps,
    legalBasis:
      OBJECTIONS_LEGAL_BASIS[ResponseLetterSection.UsptoFormalitiesClaims],
  },
];

export const COMMON_ANNOTATION_STRUCTURE: Annotation[] = [
  {
    key: 'BIBLIOGRAPHIC_DATA',
    style: {
      backgroundColor: '#7C52FF',
      legendColor: '#D1C2FF',
    },
  },

  {
    key: 'LINKS_TO_PRIOR_ART',
    style: {
      backgroundColor: '#699AFF',
      underlineColor: '#1C7FF6',
      legendColor: '#CBDCFF',
    },
  },

  {
    key: 'PRIOR_ART',
    style: {
      backgroundColor: '#37FFAF',
      legendColor: '#B9FFE3',
    },
  },

  {
    key: 'CLAIM_FEATURES',
    style: {
      backgroundColor: '#FFF942',
      legendColor: '#FFFCA0',
    },
  },

  {
    key: 'SUGGESTED_AMENDMENTS',
    style: {
      backgroundColor: '#FF9E1E',
      legendColor: '#FFDDB0',
    },
  },
];

export const ANNOTATION_STRUCTURE: Annotation[] = [
  ...COMMON_ANNOTATION_STRUCTURE,

  {
    key: 'OBJECTIONS',
    ...objectionStyle,
  },

  ...OBJECTIONS_CHILDREN_ANNOTATIONS,
  ...INVENTIVENESS_CHILDREN_ANNOTATIONS,
];

export const USPTO_ANNOTATION_STRUCTURE: Annotation[] = [
  ...COMMON_ANNOTATION_STRUCTURE,

  {
    key: 'USPTO_OBJECTIONS',
    ...objectionStyle,
  },

  ...USPTO_OBJECTIONS_CHILDREN_ANNOTATIONS,
];

const isUSPTO = (annotationSets: Record<string, string> | undefined) =>
  Boolean(
    annotationSets &&
      Object.values(annotationSets).some((id) => id.startsWith('USPTO_'))
  );

export const getAnnotationStructure = (isUSPTO: boolean) => {
  if (isUSPTO) {
    return USPTO_ANNOTATION_STRUCTURE;
  }

  return ANNOTATION_STRUCTURE;
};

export const getAnnotationStyle = (key: string) => {
  const annotation = [
    ...ANNOTATION_STRUCTURE,
    ...USPTO_ANNOTATION_STRUCTURE,
  ].find((a) => a.key === key);
  return annotation?.style;
};

export const getAnnotationLegalBasis = (key: string) => {
  const annotation = [
    ...ANNOTATION_STRUCTURE,
    ...USPTO_ANNOTATION_STRUCTURE,
  ].find((a) => a.key === key);
  return annotation?.legalBasis;
};

export const getAnnotationIdByKey = (
  key: string,
  annotationSets: Record<string, string> | undefined
) => {
  if (!annotationSets) {
    return;
  }

  const index = Object.values(annotationSets).indexOf(key);
  return Object.keys(annotationSets)[index];
};

export const getRootAnnotationKey = (key: string): string => {
  const annotation = ANNOTATION_STRUCTURE.find((a) => a.key === key);

  if (!annotation?.parentKey) {
    return key;
  }

  return getRootAnnotationKey(annotation.parentKey);
};

const findChildAnnotationIds = (
  annotationId: string,
  annotationSets: Record<string, string> | undefined
) => {
  if (!annotationSets) {
    return [];
  }

  const childIds: string[] = [];

  const annotationStructure = getAnnotationStructure(isUSPTO(annotationSets));

  // id might be
  // - an annotation set id that is coming from db
  // - a placeholder id that is generated by the frontend (e.g. placeholder-OBJECTIONS)
  const find = (id: string) => {
    const key = annotationSets[id] || id.replace('placeholder-', '');

    const childAnnotations = annotationStructure.filter(
      (a) => a.parentKey === key
    );

    childAnnotations.forEach((a) => {
      const childAnnotationId =
        getAnnotationIdByKey(a.key, annotationSets) || `placeholder-${a.key}`;
      childIds.push(childAnnotationId);
      find(childAnnotationId);
    });
  };

  find(annotationId);

  return childIds;
};

const findRootAnnotationIds = (
  annotationId: string,
  annotationSets: Record<string, string> | undefined
) => {
  if (!annotationSets) {
    return [];
  }

  const rootIds: string[] = [];

  const annotationStructure = getAnnotationStructure(isUSPTO(annotationSets));

  // id might be
  // - an annotation set id that is coming from db
  // - a placeholder id that is generated by the frontend (e.g. placeholder-OBJECTIONS)
  const find = (id: string) => {
    const key = annotationSets[id] || id.replace('placeholder-', '');
    const annotation = annotationStructure.find((a) => a.key === key);

    if (!annotation?.parentKey) {
      return;
    }

    const rootId =
      getAnnotationIdByKey(annotation.parentKey, annotationSets) ||
      `placeholder-${annotation.parentKey}`;
    rootIds.push(rootId);
    find(rootId);
  };

  find(annotationId);

  return rootIds;
};

export const produceSelectedAnnotationSetsOnToggleAnnotationSet = (
  annotationId: string,
  value: boolean,
  annotationSets: Record<string, string> | undefined,
  selectedAnnotationSets: Record<string, boolean> | undefined
) => {
  // if the annotation is not selected, then all its children should not be selected
  // if the annotation is selected, then all its children should be selected
  const childAnnotationIds: string[] = findChildAnnotationIds(
    annotationId,
    annotationSets
  );

  const newSelectedAnnotationSets = {
    ...selectedAnnotationSets,
    [annotationId]: value,
  };

  childAnnotationIds.forEach((id) => {
    newSelectedAnnotationSets[id] = value;
  });

  // if a annotation is selected, and it's parent annotations are not selected, we should make them selected.
  // if a child annotation is not selected, and all its siblings are not selected, then the root annotation should not be selected
  const rootAnnotationIds: string[] = findRootAnnotationIds(
    annotationId,
    annotationSets
  );

  rootAnnotationIds.forEach((id) => {
    if (value) {
      newSelectedAnnotationSets[id] = value;
      return;
    }

    const childIds = findChildAnnotationIds(id, annotationSets);
    const allChildrenAreNotSelected = childIds.every(
      (childId) => !newSelectedAnnotationSets[childId]
    );

    if (allChildrenAreNotSelected) {
      newSelectedAnnotationSets[id] = value;
    }
  });

  return newSelectedAnnotationSets;
};
