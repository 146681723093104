import type { Attrs, Node } from 'prosemirror-model';
import type {
  Decoration,
  DecorationSource,
  EditorView,
  NodeViewConstructor,
} from 'prosemirror-view';
import { createContext, useContext } from 'react';
import type { ReactNodeViewUserOptions } from './ReactNodeViewOptions';

export type NodeViewContentRef = (node: HTMLElement | null) => void;

export interface NodeViewContext {
  contentRef: NodeViewContentRef;
  view: EditorView;
  getPos: () => number | undefined;
  setAttrs: (attrs: Attrs) => void;
  node: Node;
  selected: boolean;
  decorations: readonly Decoration[];
  innerDecorations: DecorationSource;
}

export const nodeViewContext = createContext<NodeViewContext>({
  contentRef: () => {},
  view: null as never,
  getPos: () => 0,
  setAttrs: () => {},

  node: null as never,
  selected: false,
  decorations: [],
  innerDecorations: null as never,
});

export const useNodeViewContext = () => useContext(nodeViewContext);

export const createNodeViewContext = createContext<
  (options: ReactNodeViewUserOptions) => NodeViewConstructor
>(() => {
  throw new Error(
    'No ProsemirrorAdapterProvider detected, maybe you need to wrap the component with the Editor with ProsemirrorAdapterProvider?'
  );
});

export const useNodeViewFactory = () => useContext(createNodeViewContext);
