import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '../..';

type Variables = {
  documentId: string;
  documentName: string;
  contentLanguage: T.Language;
};

function useUpdateDocumentAuthenticLanguage(
  options?: UseMutationOptions<T.DocumentMetadata, AxiosError, Variables>
): UseMutationResult<T.DocumentMetadata, AxiosError, Variables> {
  return useMutation<T.DocumentMetadata, AxiosError, Variables>(
    ({ documentId, documentName, contentLanguage }) =>
      Api.document.updateAuthenticLanguage(
        documentId,
        documentName,
        contentLanguage
      ),
    options
  );
}

export default useUpdateDocumentAuthenticLanguage;
