import { EditorView } from 'prosemirror-view';
import type { EditorState, Transaction } from 'prosemirror-state';
import { DOMSerializer } from 'prosemirror-model';
import { schema } from '../schema/schema';
import { uniqueNodeId } from '../uniqueNodeId';
import { processImagesAndCopy } from '../util';

export type OnTransactionChange = (
  tr: Transaction,
  oldState: EditorState,
  newState: EditorState
) => void;

export interface CreateEditorViewProps {
  root: HTMLElement | null;
  readonlyState: boolean;
  getTargetEditorView: () => EditorView | null;
  editorState: EditorState;
  onTransactionChange: OnTransactionChange;
  attributes?: Record<string, string>;
}
export function createEditorView({
  root,
  readonlyState,
  getTargetEditorView,
  editorState,
  onTransactionChange,
  attributes,
}: CreateEditorViewProps) {
  const view = new EditorView(root, {
    attributes,
    editable: () => !readonlyState,
    handleScrollToSelection: () => true,
    dispatchTransaction: (tr) => {
      const targetEditorView = getTargetEditorView();
      bindTransaction(tr, view, targetEditorView, onTransactionChange);
    },
    handleDOMEvents: {
      copy: handleCopyDOMEvent,
    },
    state: editorState,
  });

  return view;
}

function bindTransaction(
  tr: Transaction,
  editingEditorView: EditorView,
  targetEditorView: EditorView | null,
  onChange: OnTransactionChange
) {
  const oldState = editingEditorView.state;
  const updatedTr = uniqueNodeId(
    tr,
    oldState,
    editingEditorView.state.apply(tr)
  );
  const newState = editingEditorView.state.apply(updatedTr);

  editingEditorView.updateState(newState);
  targetEditorView?.updateState(targetEditorView?.state.apply(updatedTr));
  onChange(tr, oldState, newState);
}

function handleCopyDOMEvent(view: EditorView, event: ClipboardEvent) {
  if (!event.clipboardData) {
    return false;
  }

  const { state } = view;
  const { selection } = state;
  const { $from, $to } = selection;
  const { doc } = state;

  const from = $from.pos;
  const to = $to.pos;

  const slice = doc.slice(from, to);

  // Serialize the slice to a string
  const serializer = DOMSerializer.fromSchema(schema);
  const div = document.createElement('div');
  serializer.serializeFragment(slice.content, { document }, div);
  const text = slice.content.textBetween(0, slice.content.size, '\n');

  processImagesAndCopy(div, text);

  event.preventDefault();
}
