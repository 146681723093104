import { Decoration, DecorationSet } from 'prosemirror-view';
import { EditorState, Transaction } from 'prosemirror-state';
import { ImagePluginAction, ImagePluginSettings } from '../../../../types';
import { imagePluginKey } from './utils';

export const defaultExtraAttributes = {
  width: null,
  height: null,
  maxWidth: null,
};

export const defaultCreateDecorations = (state: EditorState) =>
  imagePluginKey.getState(state) || DecorationSet.empty;

const defaultFindPlaceholder = (state: EditorState, id: object) => {
  const decos = imagePluginKey.getState(state);
  const found = decos?.find(undefined, undefined, (spec) => spec.id === id);
  return found?.length ? found[0].from : undefined;
};

const defaultCreateState = () => ({
  init() {
    return DecorationSet.empty;
  },
  apply(tr: Transaction, value: DecorationSet): DecorationSet {
    let set = value.map(tr.mapping, tr.doc);
    const action: ImagePluginAction = tr.getMeta(imagePluginKey);
    if (action?.type === 'add') {
      const widget = document.createElement('div');
      widget.classList.add('image-placeholder');
      const deco = Decoration.widget(action.pos, widget, {
        id: action.id,
      });
      set = set.add(tr.doc, [deco]);
    } else if (action?.type === 'remove') {
      set = set.remove(
        set.find(undefined, undefined, (spec) => spec.id === action.id)
      );
    }
    return set;
  },
});

export const defaultSettings: ImagePluginSettings = {
  extraAttributes: defaultExtraAttributes,
  minSize: 50,
  maxSize: 2000,
  scaleImage: true,
  createState: defaultCreateState,
  createDecorations: defaultCreateDecorations,
  findPlaceholder: defaultFindPlaceholder,
};

export const DEFAULT_MIN_SIZE = 20;
export const DEFAULT_MAX_SIZE = 10000;
export const DEFAULT_EMPTY_SRC =
  'data:image/gif;base64,' +
  'R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const DEFAULT_IMAGE_MARGIN = 2;
export const MM_TO_PX_CONVERSION_FACTOR = 3.7795275591;

export const DEFAULT_ORIGINAL_SIZE = {
  src: '',
  complete: false,
  height: 0,
  width: 0,
};
