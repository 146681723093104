import { css } from 'styled-components';
import { Colors } from '../../../theme';

export default css`
  .comment {
    background: ${Colors.blue50};
    border-bottom: 1px solid rgba(65, 128, 204, 1);
    cursor: pointer;
  }
`;
