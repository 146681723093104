import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import useTranslation from '../../translations';
import { NavigationItem } from '../Layout';
import { useSessionContext } from '../../contexts';
import { useGetContactByUserIdentity } from '../../queries';
import { useGroupUsers } from '../../hooks';
import { Contact, ContactGroupType } from '../../types';

dayjs.extend(utc);
dayjs.extend(tz);

const DATE_FORMAT = 'DD.MM.YYYY';
const TIME_FORMAT = 'HH:mm';
const MERCK_COMPANY_DOMAINS = [
  'merckgroup.com',
  'emdserono.com',
  'milliporesigma.com',
  'emdgroup.com',
];
const COMPANY_DOMAINS = [...MERCK_COMPANY_DOMAINS, 'bayer.com', 'philipa.tech'];

const shouldAddAdminsToCarbonCopy = (
  email: string | null | undefined
): boolean => {
  if (!email) {
    console.warn('Invalid email: Email is null or undefined');
    return false;
  }

  const emailDomain = email.split('@')[1]?.toLowerCase();
  return emailDomain ? COMPANY_DOMAINS.includes(emailDomain) : false;
};

const openBugReportEmail = (
  email: string | null | undefined,
  ccRecipients: string[]
) => {
  try {
    if (!email) {
      console.warn(
        'User email is not available, aborting bug report email generation.'
      );
      return;
    }

    const currentURL = window.location.href;
    const parsedUrl = new URL(currentURL);
    const day = dayjs().utc().tz('Europe/Berlin');
    const reportDate = day.format(DATE_FORMAT);
    const reportTime = day.format(TIME_FORMAT);
    const subject = encodeURIComponent(`Bug on ${parsedUrl.host}`);
    const bodyTemplate = `Bug Report\n\nDate: ${reportDate}\nBerlin Time: ${reportTime}\n\nUser: ${email}\n\nScreen on which bug appeared:\n${currentURL}\n\n\nShort description:\n\n\n\nSteps to reproduce:\n\n\n\nActual result:\n\n\n\nExpected result:\n\n\n\nScreenshot/video:\n\n\n\n`;
    const encodedBody = encodeURIComponent(bodyTemplate);
    const cc = ccRecipients.length ? `&cc=${ccRecipients.join(',')}` : '';

    window.location.href = `mailto:support@philipa.tech?subject=${subject}&body=${encodedBody}${cc}`;
  } catch (error) {
    console.error('Error generating bug report email:', error);
  }
};

const BugReport: React.FC = () => {
  const [openBugReportCounter, setOpenBugReportCounter] = useState<number>(0);
  const [adminUsersFetched, setAdminUsersFetched] = useState<boolean>(false);
  const t = useTranslation();
  const {
    session: { email: reporterEmail },
  } = useSessionContext();
  const { data: userContact } = useGetContactByUserIdentity();

  const { infiniteData, isLoading, fetchNextPage, hasNextPage } = useGroupUsers(
    {
      searchTerm: '',
      groupType: ContactGroupType.WithAppAccess,
      withAppAccessGroupIds: [AzureGroupsConfig.AppAccessAdmin],
    }
  );

  const shouldAddCarbonCopy = shouldAddAdminsToCarbonCopy(reporterEmail);

  const fetchAllAdminContacts = useCallback(async () => {
    let lastHasNextPage = true;
    while (lastHasNextPage) {
      const nextPage = await fetchNextPage();
      lastHasNextPage = nextPage.hasNextPage ?? false;
    }
  }, [fetchNextPage]);

  useEffect(() => {
    if (!adminUsersFetched && hasNextPage && !isLoading) {
      fetchAllAdminContacts().then(() => setAdminUsersFetched(true));
    }
  }, [adminUsersFetched, hasNextPage, isLoading, fetchAllAdminContacts]);

  useEffect(() => {
    if (openBugReportCounter > 0 && reporterEmail) {
      const addToRecipients = adminUsersFetched && shouldAddCarbonCopy;
      const reporterDomain = reporterEmail.split('@')[1]?.toLowerCase();
      const isMerckDomain = MERCK_COMPANY_DOMAINS.includes(reporterDomain);

      const ccRecipients = addToRecipients
        ? infiniteData
            .filter((contact): contact is Contact & { email: string } => {
              if (!contact.email || contact.email === userContact?.email) {
                return false;
              }

              const contactDomain = contact.email.split('@')[1]?.toLowerCase();

              if (isMerckDomain) {
                return MERCK_COMPANY_DOMAINS.includes(contactDomain);
              }

              return true;
            })
            .map((contact) => contact.email)
        : [];

      openBugReportEmail(reporterEmail, ccRecipients);
    }
  }, [
    openBugReportCounter,
    adminUsersFetched,
    shouldAddCarbonCopy,
    infiniteData,
    userContact,
    reporterEmail,
  ]);

  const handleClick = useCallback(() => {
    setOpenBugReportCounter((prev) => prev + 1);
  }, []);

  return (
    <NavigationItem
      iconClassName="icn-bug"
      tooltipText={t('NAVIGATION_TOOLTIPS.BUG_REPORT')}
      iconStyle={{ fontSize: 34 }}
      isSettingsItem
      onClick={handleClick}
    />
  );
};

export default BugReport;
