import { NodeSpec } from 'prosemirror-model';
import { getAttrs, parseAttrs } from './paragraph';

const numberedParagraph: NodeSpec = {
  attrs: {
    align: { default: null },
    color: { default: null },
    leftIndent: { default: null },
    lineSpacing: { default: null },
    nodeId: { default: null },
  },
  content: 'inline*',
  group: 'block',
  parseDOM: [{ tag: 'p', getAttrs }],
  toDOM(node) {
    const attrs = parseAttrs(node.attrs);
    attrs.class = 'numbered-paragraph';

    return ['p', attrs, 0];
  },
};

export default numberedParagraph;
