import { Command, TextSelection, Transaction } from 'prosemirror-state';
import { Fragment, Node, Slice } from 'prosemirror-model';
import { DocTransformer } from '../ClaimEditorProvider/transformer';
import { NodeJSONRaw } from '../ClaimEditorProvider/transformer/types';
import { CLAIM_PART } from '../schema/nodes/nodeNames';

export const claimPartIndent: Command = (state, dispatch) => {
  const { from, to, $from } = state.selection;
  let tr = state.tr;
  let modified = false;

  state.doc.nodesBetween(from, to, (node, pos) => {
    if (node.type.name === 'claimPart') {
      const claimPart = node;
      const currentIndentLevel = claimPart.attrs.indentLevel ?? 0;

      const resolvedPos = state.doc.resolve(pos);
      const claimPartAbove = resolvedPos.nodeBefore;

      if (!claimPartAbove || claimPartAbove.type.name !== 'claimPart') {
        return false;
      }

      const aboveIndentLevel = claimPartAbove.attrs.indentLevel ?? 0;

      if (currentIndentLevel === aboveIndentLevel) {
        const newIndentLevel = currentIndentLevel + 1;

        tr.setNodeMarkup(pos, null, {
          ...claimPart.attrs,
          indentLevel: newIndentLevel,
          startNewParagraph: true,
        });

        tr = walkThroughClaimPart(claimPart, pos, newIndentLevel, tr);

        modified = true;
      }
    }
  });

  if (modified) {
    const updatedDoc = tr.doc;

    const docJSON = updatedDoc.toJSON() as NodeJSONRaw;
    const transformer = DocTransformer.fromJSON(docJSON);
    const nestedDocument = transformer.build();

    const newDocumentNode = Node.fromJSON(state.schema, nestedDocument);

    const slice = new Slice(Fragment.from(newDocumentNode), 0, 0);
    tr.replace(0, updatedDoc.content.size, slice);

    const newDoc = tr.doc;
    const updatedPos = newDoc.resolve($from.pos - 1);
    tr.setSelection(TextSelection.near(updatedPos));

    if (dispatch) {
      dispatch(tr);
    }
  }
  return modified;
};

function walkThroughClaimPart(
  node: Node,
  parentNodePos: number,
  parentIndentLevel: number,
  tr: Transaction
): Transaction {
  node.descendants((childNode, childPos) => {
    if (childNode.type.name !== CLAIM_PART) {
      return false;
    }

    const absolutePos = childPos + tr.mapping.map(parentNodePos + 1);
    const newIndentLevel = parentIndentLevel + 1;

    tr = tr.setNodeMarkup(absolutePos, null, {
      ...childNode.attrs,
      indentLevel: newIndentLevel,
      startNewParagraph: true,
    });

    if (childNode.childCount > 0) {
      tr = walkThroughClaimPart(childNode, absolutePos, newIndentLevel, tr);
    }

    return false;
  });

  return tr;
}
