import React from 'react';
import styled from 'styled-components';
import { Tooltip, type MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { NavigationBarItemType } from '../../types/common';
import NavigationBarStep from './NavigationBarStep';

interface Props {
  type?: NavigationBarItemType;
  label: string;
  active: boolean;
  number?: number;
  disabled?: boolean;
  tooltip?: string;
  isLoading?: boolean;
  dropdownItems?: MenuProps['items'];
  onClick?: () => void;
}

const NavigationBarItem: React.FC<Props> = ({
  type = NavigationBarItemType.Default,
  number,
  label,
  active,
  disabled,
  tooltip,
  isLoading,
  dropdownItems,
  onClick,
}) => {
  let dropdownContent = null;

  if (dropdownItems) {
    dropdownContent = (
      <>
        <Dropdown
          overlayClassName="navigation-bar-dropdown-menu"
          menu={{ items: dropdownItems }}
          trigger={['click']}
        >
          <DropdownTrigger
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <i className="icn icn-chevron-down" />
          </DropdownTrigger>
        </Dropdown>
      </>
    );
  }

  const outerContent = (
    <Outer
      disabled={disabled}
      onClick={onClick}
      data-testid={`navigation-bar-item-${label
        .toLowerCase()
        .replaceAll(/\s/g, '-')}`}
    >
      <NavigationBarStep
        type={type}
        number={number}
        active={active}
        isLoading={isLoading}
      />
      <Label active={active}>{label}</Label>
      {dropdownContent}
    </Outer>
  );

  if (!tooltip || disabled) {
    return outerContent;
  }

  return (
    <Tooltip
      title={tooltip}
      placement="right"
      overlayStyle={{ maxWidth: '200px', whiteSpace: 'pre-line' }}
      overlayInnerStyle={{
        color: 'rgba(255, 255, 255, 0.8)',
        padding: '5px 8px',
        fontSize: '12px',
        borderRadius: '4px',
        background: '#5C616D',
      }}
    >
      {outerContent}
    </Tooltip>
  );
};

export default NavigationBarItem;

const Outer = styled.button<{ disabled?: boolean }>`
  display: inline-flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  transition: background 0.2s;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};

  &:hover {
    background: ${(props) =>
      props.disabled ? 'transparent' : props.theme.colors.primary200_20};
  }
`;

const Label = styled.div<{ active: boolean }>`
  font-size: 13px;
  font-weight: 400;
  color: ${(props) =>
    props.active ? props.theme.colors.white100 : props.theme.colors.white60};
`;

const DropdownTrigger = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};

  i {
    font-size: 20px;
  }
`;
