import React, {
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { useInfiniteQuery } from 'react-query';
import { Button } from 'antd';
import styled, { CSSObject } from 'styled-components';
import { useIntersectionObserver } from '../../hooks';
import ObservedTarget from '../ObservedTarget';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { queryClient } from '../../services/queryClient';
import useTranslation from '../../translations';
import {
  Column,
  FilterValue,
  QueryParams,
  DataSource,
  CachedListContext,
  BaseDto,
  InfiniteQueryData,
  SortOrder,
  Direction,
} from '../../types';
import Search from '../Search';
import { DolphinLoader, Table } from '..';
import useDragToScroll from '../../hooks/useDragToScroll';
import ListItem from './components/ListItem';
import TableHead from './components/TableHead';
import FadeOutEffect from './components/FadeOutEffect';
import { useListConfiguration } from './ListConfiguration';

interface SearchField {
  label: string;
  value: string;
  selected: boolean;
}

interface Props<TItem extends BaseDto, P extends QueryParams> {
  dataSource: DataSource<TItem, P>;
  columns: Column<TItem>[];
  title?: string | React.ReactNode;
  defaultQueryParamsOverride?: Partial<QueryParams>;
  highlightedIds?: string[];
  searchInputPlaceholder?: string;
  wrapperStyle?: React.CSSProperties;
  tableStyle?: React.CSSProperties;
  tableWrapperStyle?: React.CSSProperties;
  rowStyle?: CSSObject;
  hoverRowStyle?: CSSObject;
  cellClassName?: string;
  rowClassName?: string;
  headerChildren?: React.ReactNode;
  searchInSeparateRow?: boolean;
  linkFn?: (item: TItem) => string | undefined;
  clickFn?: (item: TItem) => void;
  customItemRenderer?: (item: TItem, index: number) => React.ReactNode;
  scrollX?: boolean;
  dragToScroll?: boolean;
  searchInOptions?: {
    options: { value: string; label: string; selected: boolean }[];
    onChange: (
      options: {
        value: string;
        label: string;
        selected: boolean;
      }[]
    ) => void;
  };
  setRows?: Dispatch<SetStateAction<TItem[]>>;
}

const CACHE_VALIDITY_IN_MIN = 5;

const List = <TItem extends BaseDto, P extends QueryParams>({
  dataSource,
  columns,
  title,
  highlightedIds,
  searchInputPlaceholder,
  wrapperStyle,
  tableStyle,
  tableWrapperStyle,
  rowStyle,
  hoverRowStyle,
  cellClassName,
  rowClassName,
  defaultQueryParamsOverride,
  headerChildren,
  searchInSeparateRow = false,
  scrollX = false,
  dragToScroll = false,
  linkFn,
  clickFn,
  customItemRenderer,
  searchInOptions,
  setRows,
}: PropsWithChildren<Props<TItem, P>>): ReactElement | null => {
  const t = useTranslation();
  const pagesToPreloadRef = useRef<number | null>(null);
  const scrollableElementRef = useRef<HTMLDivElement>(null);

  const bodyScrollTopRef = useRef<number>(0);
  const queryParamsRef = useRef<Partial<QueryParams> | null>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const [hasScrollbar, setHasScrollbar] = useState<boolean>(true); // most probable state on mount
  const [queryParams, setQueryParams] = useState<Partial<QueryParams> | null>(
    null
  );

  const [listColumns, setListColumns] = useState<Column<TItem>[]>([]);

  const [scrollPinnedToLeft, setScrollPinnedToLeft] = useState(true);
  const [scrollPinnedToRight, setScrollPinnedToRight] = useState(false);

  // It adds drag to scroll functionality to the list component
  const { isScrollingByDrag } = useDragToScroll({
    ref: scrollableElementRef,
    enabled: dragToScroll,
  });

  // List Configuration
  const listConfiguration = useListConfiguration();

  // load and apply list configuration
  useEffect(() => {
    (async () => {
      if (!listConfiguration) {
        return setListColumns(columns);
      }

      const storedColumns = await listConfiguration.load();

      if (!storedColumns.length) {
        listConfiguration.markAsInitialized();
        return setListColumns(columns);
      }

      const newColumns = storedColumns.map((c) => {
        const column = columns.find((col) => col.key === c.key);

        if (!column) {
          return null;
        }

        return {
          ...column,
          hide: c.hide,
          fixed: c.fixed || undefined,
        };
      });

      setListColumns(newColumns.filter(Boolean) as Column<TItem>[]);

      listConfiguration.markAsInitialized();
    })();
  }, [columns]);

  // store list configuration on change
  useEffect(() => {
    if (
      !listConfiguration ||
      !listConfiguration.initialized ||
      !listColumns.length
    ) {
      return;
    }

    listConfiguration.save(
      listColumns.map((c) => ({
        key: c.key,
        hide: c.hide || false,
        fixed: c.fixed || undefined,
      }))
    );
  }, [listColumns]);

  let defaultQueryParams: QueryParams = {
    skip: 0,
    searchTerm: '',
    searchFields: [],
    filteringMode: 'inclusion', // exclusion mode handling is not implemented
    filters: {},
    pageSize: dataSource.pageSize,
    sortBy: listColumns.find((c) => c.sorting != null)?.key,
    sortOrder: listColumns.find(
      (c) => c.sorting && [SortOrder.ASC, SortOrder.DESC].includes(c.sorting)
    )?.sorting,
    ...(defaultQueryParamsOverride ?? {}),
    ...(queryParams ?? {}),
  };

  if (dataSource.additionalQueryParams) {
    // make sure external (additional) do override internal queryParams
    defaultQueryParams = {
      ...defaultQueryParams,
      ...dataSource.additionalQueryParams(defaultQueryParams as P),
    };
  }

  const queryKey = Array.isArray(dataSource.queryKey)
    ? [...dataSource.queryKey, JSON.stringify(defaultQueryParams)]
    : [dataSource.queryKey, JSON.stringify(defaultQueryParams)];

  useEffect(() => {
    queryParamsRef.current = queryParams;
  }, [queryParams]);

  useEffect(() => {
    let initialParams = { ...defaultQueryParams };
    try {
      const key = dataSource.queryKey.toString();
      // apply persisted query
      const storedContext = sessionStorage.getItem(key);
      sessionStorage.removeItem(key);

      if (storedContext) {
        const parsedListContext: CachedListContext = JSON.parse(storedContext);
        // check if cached item has expired
        if (parsedListContext.expiresOn > new Date().getTime()) {
          if (parsedListContext.queryParams) {
            initialParams = {
              ...initialParams,
              ...parsedListContext.queryParams,
            };
          }
          pagesToPreloadRef.current = parsedListContext.pagesToFetch;
          bodyScrollTopRef.current = parsedListContext.scrollTop;
        }
      }
    } catch {}

    setQueryParams(initialParams);

    return () => {
      const contextToStore: CachedListContext = {
        pagesToFetch: query.data?.pages.length ?? 1,
        expiresOn: new Date().getTime() + CACHE_VALIDITY_IN_MIN * 60 * 1000,
        queryParams: queryParamsRef.current,
        scrollTop: bodyScrollTopRef.current,
      };
      sessionStorage.setItem(
        dataSource.queryKey.toString(),
        JSON.stringify(contextToStore)
      );
    };
  }, []);

  const query = useInfiniteQuery({
    queryKey,
    enabled: queryParams != null,
    queryFn: async ({ pageParam }): Promise<InfiniteQueryData<TItem>> => {
      const p: QueryParams = {
        ...defaultQueryParams,
        skip: (pageParam as number) ?? 0,
      };
      if (pagesToPreloadRef.current != null) {
        p.pageSize = pagesToPreloadRef.current * DEFAULT_PAGE_SIZE;
        pagesToPreloadRef.current = null; // clear value so this get handled only once
      }

      const pageSize = p.pageSize ?? DEFAULT_PAGE_SIZE;
      const skip = p.skip ?? 0;

      const apiResponse = await dataSource.apiFunction({
        ...p,
        pageSize: pageSize + 1,
      } as P);
      return {
        data: apiResponse.slice(0, pageSize),
        nextCursor: apiResponse.length > pageSize ? skip + pageSize : undefined,
      };
    },
    getNextPageParam: (lastPage: InfiniteQueryData<TItem>) =>
      lastPage.nextCursor,
  });

  useIntersectionObserver({
    target: targetRef?.current,
    onIntersect: query.fetchNextPage,
    root: rootRef?.current,
    threshold: 0.1,
    enabled: query.hasNextPage,
  });

  useEffect(() => {
    queryClient.invalidateQueries(queryKey);
  }, [dataSource]);

  useLayoutEffect(() => {
    if (!scrollableElementRef?.current) {
      return undefined;
    }
    const ref = scrollableElementRef.current;
    if (bodyScrollTopRef.current) {
      ref.scrollTo({
        top: bodyScrollTopRef.current,
        behavior: 'smooth',
      });
    }

    const handleWheel = (e: Event) => {
      bodyScrollTopRef.current = (e.target as HTMLDivElement).scrollTop;
    };

    ref.addEventListener('scroll', handleWheel, { passive: true });
    return () => {
      ref.removeEventListener('scroll', handleWheel);
    };
  }, [scrollableElementRef.current]);

  const handleSearchTermChange = (searchTerm: string) => {
    if (!queryParams) {
      return;
    }
    setQueryParams((prev) => ({
      ...prev,
      searchTerm,
    }));
  };

  const handleSearchFieldsChange = (searchFields: SearchField[]) => {
    if (!queryParams || !searchInOptions) {
      return;
    }

    setQueryParams((prev) => ({
      ...prev,
      searchFields: searchFields
        .filter((sf) => sf.selected)
        .map((sf) => sf.value),
    }));

    searchInOptions.onChange(searchFields);
  };

  let searchInOptionsProp = undefined;

  if (searchInOptions) {
    searchInOptionsProp = {
      options: searchInOptions.options,
      onChange: handleSearchFieldsChange,
    };
  }

  const handleFilterApply = (columnKey: string, values: FilterValue[]) => {
    setQueryParams((prev) => {
      const newValue = { ...prev };
      if (newValue?.filters) {
        newValue.filters[columnKey] = values;
      }
      return newValue;
    });
  };

  const handleClearFiltersClick = () =>
    setQueryParams((prev) => ({
      ...prev,
      searchTerm: '',
      filters: {},
    }));

  const handleClearTableFilters = () =>
    setQueryParams((prev) => ({
      ...prev,
      filters: {},
    }));

  const handleSortOrderChange = (
    columnKey: string,
    newSortOrder: SortOrder
  ) => {
    setQueryParams((prev) => ({
      ...prev,
      sortBy: columnKey,
      sortOrder: newSortOrder,
    }));
  };

  const handleColumnVisibilityChange = (
    column: Column<TItem>,
    visible: boolean
  ) => {
    setListColumns(
      listColumns.map((c) => {
        if (c.key === column.key) {
          return {
            ...c,
            hide: !visible,
          };
        }

        return c;
      })
    );
  };

  const handleColumnOrderChange = (
    column: Column<TItem>,
    oldIndex: number,
    newIndex: number
  ) => {
    setListColumns((prevState) => {
      const newColumns = [...prevState];
      newColumns.splice(oldIndex, 1);
      newColumns.splice(newIndex, 0, column);

      return newColumns.sort((a, b) => {
        if (
          (a.pinned === Direction.LEFT && b.pinned === Direction.LEFT) ||
          (a.pinned === Direction.RIGHT && b.pinned === Direction.RIGHT)
        ) {
          return 0;
        } else {
          if (a.pinned === Direction.LEFT || b.pinned === Direction.RIGHT) {
            return -1;
          }
          if (b.pinned === Direction.LEFT || a.pinned === Direction.RIGHT) {
            return 1;
          }
          return 0;
        }
      });
    });
  };

  const memoizedData = useMemo((): TItem[] | null => {
    if (!query.data) {
      return null;
    }
    return query.data.pages.reduce(
      (result: TItem[], page: InfiniteQueryData<TItem>) => [
        ...result,
        ...page.data,
      ],
      []
    );
  }, [query.data]);

  useEffect(() => {
    if (setRows && memoizedData) {
      setRows(memoizedData);
    }
  }, [memoizedData]);

  // detect if scroll is pinned to left/right
  useEffect(() => {
    if (!scrollX || !scrollableElementRef.current) {
      return;
    }

    const handleScroll = () => {
      if (!scrollableElementRef.current) {
        return;
      }

      setScrollPinnedToLeft(scrollableElementRef.current.scrollLeft === 0);

      setScrollPinnedToRight(
        scrollableElementRef.current.scrollLeft +
          scrollableElementRef.current.clientWidth >=
          scrollableElementRef.current.scrollWidth
      );
    };

    handleScroll();

    scrollableElementRef.current.addEventListener('scroll', handleScroll);

    return () => {
      scrollableElementRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [listColumns]);

  // calculate if table body has a scrollbar, so we can properly align list header
  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      // prevent observer from trigering layout changes while loading and when no data is found
      if (!entry || query.isLoading || !memoizedData?.length) {
        return;
      }
      setHasScrollbar(entry.target.scrollHeight > entry.target.clientHeight);
    });
    scrollableElementRef.current &&
      observer.observe(scrollableElementRef.current);
    return () => {
      observer.disconnect();
    };
  }, [query.isLoading, memoizedData]);

  const appliedFilters = useMemo(
    () =>
      Object.entries(queryParams?.filters ?? {}).filter(([, values]) =>
        Array.isArray(values) ? !!values.length : !!values
      ) as [string, FilterValue[]][],
    [queryParams]
  );

  const anyFiltering =
    (queryParams &&
      queryParams.searchTerm &&
      queryParams.searchTerm.length > 0) ||
    appliedFilters.length > 0;

  const tableBodyLoader = (
    <Table.Loader>
      <div data-testid="list-loading">
        <DolphinLoader />
      </div>
    </Table.Loader>
  );

  let tableBody = null;
  let centeredContent: null | JSX.Element = tableBodyLoader;
  switch (true) {
    case query.isError:
      tableBody = null;
      centeredContent = (
        <div data-testid="list-error">{t('SOMETHING_WENT_WRONG')}</div>
      );
      break;
    case memoizedData && memoizedData.length === 0:
      tableBody = null;
      centeredContent = (
        <Table.NoData data-testid="list-empty">
          {t('ITEM_LIST.NO_DATA')}
          {anyFiltering && (
            <Button type="link" onClick={handleClearFiltersClick}>
              {t('ITEM_LIST.NO_DATA_CLEAR_FILTERS')}
            </Button>
          )}
        </Table.NoData>
      );
      break;
    case listConfiguration?.loading:
      tableBody = null;
      centeredContent = tableBodyLoader;
      break;
    case memoizedData && memoizedData.length > 0:
      centeredContent = null;
      tableBody = (
        <>
          {memoizedData?.map((item, itemIdx) => (
            <FadeOutEffect
              key={item.id}
              scrollableElementRef={scrollableElementRef}
              data={memoizedData}
              last={!query.hasNextPage && itemIdx === memoizedData.length - 1}
            >
              <ListItem
                queryParams={queryParams || undefined}
                columns={listColumns}
                className={rowClassName}
                cellClassName={cellClassName}
                item={item}
                index={itemIdx}
                linkFn={linkFn}
                clickFn={clickFn}
                customItemRenderer={customItemRenderer}
                highlighted={highlightedIds?.some((id) => id === item.id)}
                styleOverride={rowStyle}
                hoverStyleOverride={hoverRowStyle}
                isScrollingByDrag={isScrollingByDrag}
              />
            </FadeOutEffect>
          ))}
        </>
      );
      break;
    default:
      tableBody = null;
      centeredContent = tableBodyLoader;
      break;
  }

  const titleContent = title && <Table.TableTitle>{title}</Table.TableTitle>;

  const searchContent = (
    <Search
      placeholder={searchInputPlaceholder}
      value={queryParams?.searchTerm}
      onChange={(v) => handleSearchTermChange(v)}
      style={{ flex: 7, margin: 0 }}
      searchInOptions={searchInOptionsProp}
      type="light"
    />
  );

  return (
    <Table.Outer
      ref={rootRef}
      data-testid="list-container"
      style={wrapperStyle}
    >
      {searchInSeparateRow ? (
        <Table.TitleSection>
          <div style={{ width: '100%' }}>
            <TitleSectionHeaderForSeparatedRow>
              {titleContent}
              {headerChildren}
            </TitleSectionHeaderForSeparatedRow>
            <Table.SearchSection>{searchContent}</Table.SearchSection>
          </div>
        </Table.TitleSection>
      ) : (
        <Table.TitleSection>
          {titleContent}
          {searchContent}
          {headerChildren}
        </Table.TitleSection>
      )}
      <Table.TableWrapper style={tableWrapperStyle}>
        <Table.Inner scrollX={scrollX} ref={scrollableElementRef}>
          <Table.Table
            className={classNames({
              'scroll-pinned-to-left': scrollPinnedToLeft,
              'scroll-pinned-to-right': scrollPinnedToRight,
            })}
            style={{
              ...tableStyle,
              height: memoizedData?.length ? 'auto' : '100%',
            }}
          >
            <TableHead
              scrollX={scrollX}
              columns={listColumns}
              appliedFilters={appliedFilters}
              onApplyFilter={handleFilterApply}
              sortBy={queryParams?.sortBy}
              sortOrder={queryParams?.sortOrder}
              scrollbarCompensation={hasScrollbar}
              onSortOrderChange={handleSortOrderChange}
              onColumnOrderChange={handleColumnOrderChange}
              onColumnVisibilityChange={handleColumnVisibilityChange}
              onClearTableFilters={handleClearTableFilters}
            />
            <Table.TableBody scrollX={scrollX}>
              {tableBody}
              <ObservedTarget
                targetRef={targetRef}
                loading={query.isFetchingNextPage}
              />
            </Table.TableBody>
          </Table.Table>
        </Table.Inner>
        {centeredContent && <Centered>{centeredContent}</Centered>}
      </Table.TableWrapper>
    </Table.Outer>
  );
};

export const TitleSectionHeaderForSeparatedRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Centered = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default List;
