import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EditorView } from 'prosemirror-view';
import { Tooltip, TooltipProps } from 'antd';
import { Node as PMNode } from 'prosemirror-model';
import useTranslation from '../../../../translations';
import { Colors, T } from '../../../../..';
import { claimEnter } from '../../config/claimEnter';
import { deleteClaim } from '../../config/customCommands';

interface Props {
  active: boolean;
  root: React.RefObject<HTMLDivElement>;
  node: PMNode;
  isTranslationEditor: boolean;
  editorView?: EditorView;
  patentOffice?: T.PatentOffice;
  undoClaim?: (claimId: string) => void;
  redoClaim?: (claimId: string) => void;
  retranslateClaim?: (claimId: string) => void;
}

export const TOOLTIP_STYLE = {
  maxWidth: '150px',
  color: Colors.white87,
  padding: '5px 8px',
  fontSize: '11px',
  background: Colors.primary300,
  borderRadius: 3,
  boxShadow: 'none',
  marginBottom: '-7px',
};

export const tooltipCommonProps: TooltipProps = {
  placement: 'top',
  overlayInnerStyle: { ...TOOLTIP_STYLE, textAlign: 'center' },
  color: Colors.primary300,
};

const ClaimActionsMenu: React.FC<Props> = ({
  editorView,
  active,
  root,
  node,
  isTranslationEditor,
  patentOffice,
  undoClaim,
  redoClaim,
  retranslateClaim,
}) => {
  const t = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const enabledActions = !!editorView?.editable && active && isFocused;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isInside =
        !!root.current && root.current.contains(event.target as Node);
      setIsFocused(isInside);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getAvailableActions = (
    isTranslationEditor: boolean,
    patentOffice?: T.PatentOffice,
    view?: EditorView
  ) => {
    if (!view) {
      return [];
    }

    const deleteAction = {
      icon: 'icn-delete',
      title: t('PM.ACTION.DELETE_CLAIM'),
      action: () => {
        deleteClaim(view.state, view.dispatch);
      },
    };

    switch (true) {
      case isTranslationEditor:
        return [
          {
            icon: 'icn-undo',
            title: t('PM.ACTION.UNDO_CLAIM', {
              claimNumber: node.attrs.claimNumber,
            }),
            action: () => undoClaim!(node.attrs.claimTraceId),
          },
          {
            icon: 'icn-redo',
            title: t('PM.ACTION.REDO_CLAIM', {
              claimNumber: node.attrs.claimNumber,
            }),
            action: () => redoClaim!(node.attrs.claimTraceId),
          },
          {
            icon: 'icn-retry',
            title: t('PM.ACTION.RETRANSLATE_CLAIM', {
              claimNumber: node.attrs.claimNumber,
            }),
            action: () => retranslateClaim!(node.attrs.claimTraceId),
          },
        ];

      case patentOffice === T.PatentOffice.USPTO:
        return [
          {
            icon: 'icn-insert-end',
            title: t('PM.ACTION.ADD_NEW_CLAIM_END'),
            action: () => {
              claimEnter()(view.state, view.dispatch);
            },
          },
          deleteAction,
        ];

      default:
        return [
          {
            icon: 'icn-insert-above',
            title: t('PM.ACTION.ADD_NEW_CLAIM_ABOVE'),
            action: () => {
              claimEnter('above')(view.state, view.dispatch);
            },
          },
          {
            icon: 'icn-insert-below',
            title: t('PM.ACTION.ADD_NEW_CLAIM_BELOW'),
            action: () => {
              claimEnter()(view.state, view.dispatch);
            },
          },
          deleteAction,
        ];
    }
  };

  const ACTIONS = getAvailableActions(
    isTranslationEditor,
    patentOffice,
    editorView
  );

  return (
    <Outer active={enabledActions}>
      {ACTIONS.map((item, index) => (
        <Tooltip key={index} {...tooltipCommonProps} title={item.title}>
          <StyledIcon onClick={item.action} className={item.icon} />
        </Tooltip>
      ))}
    </Outer>
  );
};

export default ClaimActionsMenu;

const Outer = styled.div<{ active: boolean }>`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  background-color: ${(props) => props.theme.colors.primary200};
  padding: 3px;
  border-radius: 5px;
  position: absolute;
  top: -35px;
  right: 0;
  z-index: 100;
`;

const StyledIcon = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 3px;
  transition: background 0.3s ease;
  color: ${(props) => props.theme.colors.white87} !important;
  text-decoration: none !important;
  width: 30px;
  height: 30px;

  &:hover {
    background: ${(props) => props.theme.colors.white20};
  }
`;
