import { ResolvedPos } from 'prosemirror-model';
import { Command } from 'prosemirror-state';
import { canSplit } from 'prosemirror-transform';

function findListItem($pos: ResolvedPos) {
  for (let i = $pos.depth; i > 0; i--) {
    const node = $pos.node(i);
    if (node.type.spec.group?.includes('flatList block')) {
      return { node, depth: i };
    }
  }
  return null;
}

export function listItemEnter(): Command {
  return (state, dispatch) => {
    const { $from } = state.selection;
    const listItem = findListItem($from);

    if (!listItem) {
      return false;
    }

    const { node: listItemNode, depth: listItemDepth } = listItem;

    if (dispatch) {
      let tr = state.tr;

      const typesAfter = [];

      for (let d = $from.depth; d > listItemDepth; d--) {
        const node = $from.node(d);
        typesAfter.push({ type: node.type, attrs: node.attrs });
      }

      typesAfter.push({ type: listItemNode.type, attrs: listItemNode.attrs });

      typesAfter.reverse();

      if (!canSplit(tr.doc, $from.pos, 2, typesAfter)) {
        return true;
      }

      tr = tr.split($from.pos, 2, typesAfter);
      const oldItemPos = $from.before(listItemDepth);
      const newItemPos = tr.mapping.map($from.after(listItemDepth));

      const oldItem = tr.doc.nodeAt(oldItemPos);
      const newItem = tr.doc.nodeAt(newItemPos);

      if (oldItem && newItem) {
        tr = tr.setNodeMarkup(newItemPos, undefined, oldItem.attrs);
      }

      dispatch(tr);
    }
    return true;
  };
}
