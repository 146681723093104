import { HookResult } from '../types';
import { isDefined } from '../utils/typescript';
import { Api, T } from '../..';
import { QueryKey } from '../queries';
import useInfiniteQuery from './useInfiniteQuery';

type HookShape = (props?: {
  searchTerm?: string;
  groupType: T.ContactGroupType;
  withAppAccessGroupIds?: string[];
}) => HookResult<T.Contact>;

const useGroupUsers: HookShape = (props) => {
  const {
    isLoading,
    isError,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    infiniteData,
  } = useInfiniteQuery<T.Contact, T.ContactsQueryParams>({
    queryParams: {
      searchTerm: props?.searchTerm,
      groupType: props?.groupType ?? T.ContactGroupType.CoworkerContacts,
      withAppAccessGroupIds: props?.withAppAccessGroupIds || [],
    },
    queryKey: QueryKey.GroupUsers,
    apiFunction: Api.contacts.getContacts,
  });
  return {
    isLoading,
    isError,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage: isDefined<boolean>(hasNextPage) ?? hasNextPage,
    infiniteData: isDefined<T.Contact[]>(infiniteData) ? infiniteData : [],
  };
};

export default useGroupUsers;
