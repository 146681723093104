import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '../..';
import { QueryKey } from '.';

const getContactByUserIdentity = (
  options?: UseQueryOptions<T.Contact, AxiosError>
): UseQueryResult<T.Contact, AxiosError> =>
  useQuery<T.Contact, AxiosError>(
    [QueryKey.GetContactByUserIdentity],
    Api.contacts.getContactByUserIdentity,
    options
  );

export default getContactByUserIdentity;
