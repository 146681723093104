import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import useTranslation from '../../translations';
import { T } from '../../..';
import { Icon } from '../Icon';
import { lineClamp } from '../../utils';
import TutorialsTooltip from './TutorialsTooltip';
import TutorialsTags from './TutorialsTags';

interface Props {
  data: T.Tutorial;
  onClick: (data: T.Tutorial) => void;
}

const TutorialsCard: React.FC<Props> = ({ data, onClick }) => {
  const t = useTranslation();
  const titleRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [isTitleTruncated, setTitleTruncated] = useState(false);
  const [isDescriptionTruncated, setDescriptionTruncated] = useState(false);

  const { title, description, durationSeconds, tags } = data;

  useEffect(() => {
    const element = titleRef.current;
    if (!element) {
      return;
    }
    const isTruncated = element.scrollHeight > element.clientHeight;
    setTitleTruncated(isTruncated);
  }, [title]);

  useEffect(() => {
    const element = descriptionRef.current;
    if (!element) {
      return;
    }
    const isTruncated = element.scrollHeight > element.clientHeight;
    setDescriptionTruncated(isTruncated);
  }, [description]);

  const tutorialDescription = description?.trim().length
    ? description
    : t('TUTORIALS.NO_DESCRIPTION');

  return (
    <Outer onClick={() => onClick(data)}>
      <TutorialsTooltip
        title={title}
        disabled={!isTitleTruncated}
        placement="top"
        style={{ minWidth: 300 }}
      >
        <Title ref={titleRef}>{title}</Title>
      </TutorialsTooltip>
      <TutorialsTooltip
        title={tutorialDescription}
        disabled={!isDescriptionTruncated}
        placement="bottom"
        style={{ minWidth: 300 }}
      >
        <Description ref={descriptionRef}>{tutorialDescription}</Description>
      </TutorialsTooltip>
      <TutorialsTags tags={tags} />
      <Footer>
        <Duration>
          {`${Math.floor(durationSeconds / 60)}`}
          <span>min</span>
        </Duration>
        <Indicator>
          <span>{t('TUTORIALS.CLICK_TO_PLAY')}</span>
          <Icon className="icn-external-link" color="inherit" size={16} />
        </Indicator>
      </Footer>
    </Outer>
  );
};

export default TutorialsCard;

const Indicator = styled.div`
  display: none;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  color: ${(props) => props.theme.colors.white100};
`;

const Outer = styled.a`
  width: 315px;
  height: 178px;
  padding: 20px;
  border-radius: 8px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: background 0.3s ease;
  background: ${(props) => props.theme.colors.primary200_20};
  &:hover {
    background: ${(props) => props.theme.colors.primary200_40};
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
    ${Indicator} {
      display: flex;
    }
  }
`;

const Title = styled.div`
  flex: 0 0 24px;
  font-size: 15px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white87};
  white-space: pre-wrap;
  ${lineClamp(1)}
`;

const Description = styled.div`
  flex: 0 0 40px;
  font-size: 13px;
  line-height: 1.54;
  color: ${(props) => props.theme.colors.white60};
  white-space: pre-wrap;
  ${lineClamp(2)}
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Duration = styled.span`
  display: flex;
  gap: 3px;
  font-size: 13px;
  color: ${(props) => props.theme.colors.white87};
  font-weight: 500;
  span {
    font-weight: 400;
    color: ${(props) => props.theme.colors.white70};
  }
`;
