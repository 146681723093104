import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import styled, { css } from 'styled-components';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { Document } from 'react-pdf';
import { Trans } from 'react-i18next';
import {
  Annotation,
  DocumentListItem,
  DocumentMetadata,
  DocumentSource,
  Language,
  TranslationStateErrorCode,
} from '../../types';
import { Icon } from '../Icon';
import { Colors } from '../../theme';
import { useDocumentAsBlob } from '../../queries';
import useTranslation from '../../translations';
import PageLoad from '../PageLoad';
import { useSessionContext } from '../../contexts';
import { EmptyAdd, Files } from '../../icons';
import { PDF_DOCUMENT_OPTIONS } from '../../constants';
import { Toolbar } from './Toolbar';
import ThumbnailPreview from './ThumbnailPreview';
import PageWrapper from './PageWrapper';
import useWindowObservers from './useWindowObservers';
import useSelectableOcrText from './useSelectableOcrText';
import { isNumberValid, mapLocations } from './util';
import {
  DocumentSearch,
  useSingleDocumentSearch,
  useJumpToSearchMatch,
} from './Search';
import usePdfPagesAspectRatio from './usePdfPagesAspectRatio';

interface Props {
  document: DocumentMetadata;
  annotations?: Annotation[];
  annotationFilter: string;
  setAnnotationFilter: (annotationSetKey: string) => void;
  activeAnnotations?: Annotation[];
  activeAnnotation?: Annotation | null;
  onClickClearActiveAnnotation?: () => void;
  selectedAnnotationSets?: Record<string, boolean>;
  setSelectedAnnotationSets?: (s: Record<string, boolean>) => void;
  showAllAnnotations?: boolean;
  initialPage?: number;
  showTranslationTool?: boolean;
  globalCache?: React.MutableRefObject<Record<string, Blob>>;
  documentsToSelect?: DocumentListItem[];
  onDocumentChange?: (d: DocumentMetadata) => void;
  onClickNavigationLink?: (documentId: string, pageIndex: number) => void;
  onLanguageChange?: (language: Language, pageNumbers?: string) => void;
  onAuthenticLanguageChange?: (language: Language) => void;
  isTranslating?: boolean;
  startTranslate?: (language: Language, pageNumbers?: string) => void;
  translationProgressPercentage?: number;
  translationErrorCode: TranslationStateErrorCode | null;
  viewerIndex?: number;
  toolbarStyle?: React.CSSProperties;
  hideAnnotations?: boolean;
  hideClaims?: boolean;
  initialZoom?: number;
  searchTerm?: string;
  enableKeysNavigation?: boolean;
  onPreviousScreen?: () => void;
  containerStyleOverride?: React.CSSProperties;
}

const SingleDocumentRenderer: React.FC<Props> = ({
  document,
  annotations,
  annotationFilter,
  setAnnotationFilter,
  globalCache,
  documentsToSelect,
  activeAnnotations,
  initialPage,
  showAllAnnotations,
  activeAnnotation,
  onClickClearActiveAnnotation,
  showTranslationTool = true,
  selectedAnnotationSets,
  setSelectedAnnotationSets,
  onDocumentChange,
  startTranslate = () => null,
  onClickNavigationLink,
  onLanguageChange = () => null,
  onAuthenticLanguageChange,
  isTranslating,
  viewerIndex,
  translationErrorCode,
  translationProgressPercentage,
  toolbarStyle,
  hideAnnotations,
  hideClaims,
  initialZoom = 100,
  searchTerm,
  enableKeysNavigation,
  onPreviousScreen,
  containerStyleOverride,
}) => {
  const previousDocument = useRef<DocumentMetadata | null>(null);
  const { translationService } = useSessionContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [pdf, setPdf] = useState<PDFDocumentProxy>();
  const localCache = useRef<Record<string, Blob>>({});
  const [zoom, setZoom] = useState(initialZoom);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [currentPageInputValue, setCurrentPageInputValue] =
    useState<string>('1');
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
  const [pdfWidth, setPdfWidth] = useState<number>(0);
  const t = useTranslation();
  const totalNumberOfPages = pdf?.numPages || 0;
  const documentWrapperRef = useRef<HTMLDivElement>(null);
  const previousInitialPage = useRef<number | null>(null);

  const documentUri = document.pdfUri || document.uri;
  const file =
    globalCache?.current[documentUri] || localCache.current[documentUri];

  const { isFetching: isLoadingFile, refetch } = useDocumentAsBlob(
    documentUri,
    {
      onSuccess: (data) => {
        if (globalCache !== undefined) {
          if (globalCache.current[documentUri] === undefined) {
            globalCache.current[documentUri] = data;
          }
        } else {
          localCache.current[documentUri] = data;
        }
      },
      enabled:
        globalCache?.current[documentUri] === undefined &&
        localCache.current[documentUri] === undefined,
    }
  );

  const visibleAnnotations = useMemo(() => {
    if (activeAnnotation) {
      return mapLocations([activeAnnotation]);
    }

    const anyAnnotationLayerSelected =
      selectedAnnotationSets &&
      Object.values(selectedAnnotationSets).some((v) => v);

    if (!anyAnnotationLayerSelected) {
      return [];
    }

    return annotations && showAllAnnotations
      ? mapLocations(annotations)
      : mapLocations(activeAnnotations || []);
  }, [
    annotations,
    activeAnnotation,
    selectedAnnotationSets,
    activeAnnotations,
    showAllAnnotations,
  ]);

  const { currentPageInView, visibilities, pageRefs, setPageRef } =
    useWindowObservers({
      wrapperRef,
      scale: zoom / 100,
      pdf,
      currentPage: currentPageNumber,
    });

  useEffect(() => {
    const wrapperObserver = new ResizeObserver((entries) => {
      const wrapper = entries[0]?.target as HTMLDivElement | null;
      if (!wrapper) {
        return;
      }
      if (wrapper.offsetWidth !== wrapper.clientWidth) {
        setPdfWidth(wrapper.clientWidth - 1);
      }
    });
    const containerObserver = new ResizeObserver((entries) => {
      const containerWidth = entries[0]?.contentRect.width;
      containerWidth && setPdfWidth(containerWidth - 12);
    });
    if (wrapperRef) {
      wrapperObserver.observe(wrapperRef);
    } else if (containerRef.current) {
      containerObserver.observe(containerRef.current);
    }
    return () => {
      wrapperObserver.disconnect();
      containerObserver.disconnect();
    };
  }, [wrapperRef]);

  // if user translates one more page in the same document, we need to update the cached file
  useEffect(() => {
    if (
      previousDocument.current &&
      previousDocument.current.contentLanguage === document.contentLanguage &&
      document.translatedPageIndices !==
        previousDocument.current.translatedPageIndices
    ) {
      refetch();
    }

    previousDocument.current = document;
  }, [document]);

  useEffect(() => {
    setPageNumber(1);
    setZoom(initialZoom);
    setPdf(undefined);
  }, [file]);

  // For initial scroll to page
  // React developers, screw you for making me do this with flags. This is awful.
  useEffect(() => {
    if (
      previousInitialPage.current !== initialPage &&
      initialPage !== undefined &&
      pageRefs.length > 0 &&
      totalNumberOfPages > 1 &&
      pdf &&
      document
    ) {
      scrollToPage(initialPage);
      previousInitialPage.current = initialPage || null;
    }
  }, [initialPage, totalNumberOfPages, pdf, document, pageRefs]);

  useEffect(() => {
    if (!activeAnnotation) {
      return;
    }

    const nextPage = activeAnnotation.locations[0].pageNumber + 1;
    setCurrentPageNumber(nextPage);
    scrollToPage(nextPage);
  }, [activeAnnotation]);

  useEffect(() => {
    if (currentPageInView === -1) {
      return;
    }
    if (currentPageInView !== currentPageNumber) {
      setPageNumber(currentPageInView);
    }
  }, [currentPageInView, currentPageNumber]);

  const {
    advancedOCRSelectionEnabled,
    advancedOCRSelectionActive,
    onAdvancedOCRSelection,
    selectionRectangleRef,
  } = useSelectableOcrText({
    containerRef: documentWrapperRef,
    currentDocument: document,
    visibilities,
    pageRefs,
  });

  const onDocumentLoadSuccess = (p: PDFDocumentProxy) => {
    setPdf(p);
  };

  const onThumbnailPageClicked = (pageNumber: number) => {
    setPageNumber(pageNumber);
    scrollToPage(pageNumber);
  };

  const scrollToPage = useCallback(
    (page: number) => {
      pageRefs?.[page - 1]?.scrollIntoView();
    },
    [pageRefs]
  );

  const onPreviousPage = useCallback(() => {
    const previousPage = Math.max(currentPageNumber - 1, 1);
    scrollToPage(previousPage);
  }, [currentPageNumber, scrollToPage]);

  const onNextPage = useCallback(() => {
    const nextPage = Math.min(currentPageNumber + 1, totalNumberOfPages);
    scrollToPage(nextPage);
  }, [currentPageNumber, totalNumberOfPages, scrollToPage]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!enableKeysNavigation) {
        return;
      }
      switch (e.key) {
        case 'PageUp':
          onPreviousPage();
          break;
        case 'PageDown':
          onNextPage();
          break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [enableKeysNavigation, onPreviousPage, onNextPage]);

  const setPageNumber = (page: number) => {
    setCurrentPageNumber(page);
    setCurrentPageInputValue(`${page}`);
  };

  const onPageNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value === undefined) {
      return;
    }
    setCurrentPageInputValue(e.target.value);
    const parsedValue = parseInt(e.target.value, 10);
    const pageValid = isNumberValid(parsedValue);
    if (pageValid) {
      const nextPage = Math.min(Math.max(1, parsedValue), totalNumberOfPages);
      setCurrentPageNumber(nextPage);
      scrollToPage(nextPage);
    }
  };

  // it updates the zoom value and
  // preserve the viewport position vertically
  const updateZoom = (zoomValue: number) => {
    if (zoomValue === zoom || !wrapperRef) {
      return;
    }

    wrapperRef.scrollTop = wrapperRef.scrollTop * (zoomValue / zoom);
    setZoom(zoomValue);
  };

  // we should make sure the viewport is centered horizontally
  // when the zoom value is changed
  useEffect(() => {
    if (!wrapperRef || wrapperRef.scrollWidth <= wrapperRef.clientWidth) {
      return;
    }

    wrapperRef.scrollLeft =
      (wrapperRef.scrollWidth - wrapperRef.clientWidth) / 2;
  }, [zoom]);

  const _onDocumentChange = useCallback(
    (d: DocumentMetadata) => {
      onDocumentChange?.(d);
    },
    [onDocumentChange]
  );

  const {
    searchText,
    isSearchActive,
    isSearching,
    searchMatches,
    activeMatchIndex,
    hasOcrLayer,
    toggleSearch,
    setSearchText,
    setActiveMatchIndex,
  } = useSingleDocumentSearch({
    document,
    searchTerm,
  });

  useJumpToSearchMatch({
    searchMatches,
    activeMatchIndex,
    currentPageNumber,
    pageRefs,
    wrapperRef,
    setCurrentPageNumber,
  });

  const documentAspectRatio =
    document.width && document.height
      ? document.height / document.width
      : undefined;

  const { pagesAspectRatio } = usePdfPagesAspectRatio(pdf);
  const showTranslatedPages = !!document.translatedPageIndices?.length;

  let documentContent;

  switch (true) {
    case Boolean(viewerIndex === 1 && hideClaims):
      documentContent = (
        <NoDataWrapper style={{ paddingTop: 0, gap: 16 }}>
          <EmptyAdd width={126} height={126} />
          <NoClaimsMessage>
            {t('DOC_VIEWER.CLAIM_SET_NOT_AVAILABLE')}
          </NoClaimsMessage>
        </NoDataWrapper>
      );
      break;
    case Boolean(translationErrorCode):
      let message = {
        key: 'DOC_VIEWER.TRANSLATION_ERROR_MESSAGE.PLEASE_TRY_AGAIN',
        values: {},
      };

      if (
        translationErrorCode ===
        TranslationStateErrorCode.ServiceSingleRequestLimitExceeded
      ) {
        message = {
          key: 'DOC_VIEWER.TRANSLATION_ERROR_MESSAGE.SERVICE_SINGLE_REQUEST_LIMIT_EXCEEDED',
          values: {
            characterLimit: translationService.settings.singleRequestLimit,
          },
        };
      }

      if (
        translationErrorCode ===
        TranslationStateErrorCode.ServiceMonthlyLimitExceeded
      ) {
        message = {
          key: 'DOC_VIEWER.TRANSLATION_ERROR_MESSAGE.SERVICE_MONTHLY_LIMIT_EXCEEDED',
          values: {
            characterLimit: translationService.settings.monthlyLimit,
          },
        };
      }

      if (
        translationErrorCode === TranslationStateErrorCode.ThirdPartyServiceDown
      ) {
        message = {
          key: 'DOC_VIEWER.TRANSLATION_ERROR_MESSAGE.THIRD_PARTY_SERVICE_DOWN',
          values: {},
        };
      }

      if (
        translationErrorCode === TranslationStateErrorCode.ServiceNotEnabled
      ) {
        message = {
          key: 'DOC_VIEWER.TRANSLATION_ERROR_MESSAGE.SERVICE_NOT_ENABLED',
          values: {},
        };
      }

      documentContent = (
        <TranslationErrorWrapper>
          <main>
            <Files width={145} height={145} />
            <h3>{t('DOC_VIEWER.TRANSLATION_ERROR_TITLE')}</h3>
            <div className="error-message">
              <Trans
                t={t}
                i18nKey={message.key}
                values={message.values}
                transKeepBasicHtmlNodesFor={['<br />']}
                components={{
                  contact: (
                    <a
                      href="mailto:support@philipa.tech"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </div>
          </main>
        </TranslationErrorWrapper>
      );
      break;
    case isTranslating:
      documentContent = (
        <DocumentLoading>
          <PageLoad
            message={
              translationProgressPercentage != null
                ? t('DOC_VIEWER.TRANSLATING_PROGRESS', {
                    progress: translationProgressPercentage,
                  })
                : undefined
            }
          />
        </DocumentLoading>
      );
      break;
    case isLoadingFile:
      documentContent = (
        <DocumentLoading>
          <PageLoad message={t('DOC_VIEWER.LOADING_PDF')} />
        </DocumentLoading>
      );
      break;
    default:
      documentContent = (
        <Document
          file={file}
          inputRef={documentWrapperRef}
          options={PDF_DOCUMENT_OPTIONS}
          noData={
            <NoDataWrapper>
              <Icon className="icn-warning" size={40} color={Colors.warning} />
              <NoDataMessage>{t('NO_DATA_MESSAGE')}</NoDataMessage>
              {document.source === DocumentSource.None && (
                <a target="_blank" rel="noreferrer" href={document.uri}>
                  {t('DOCUMENT_URL')}
                </a>
              )}
            </NoDataWrapper>
          }
          loading={
            <DocumentLoading>
              <PageLoad message={t('DOC_VIEWER.LOADING_PDF')} />
            </DocumentLoading>
          }
          onLoadSuccess={onDocumentLoadSuccess}
          className="document-wrapper"
        >
          {advancedOCRSelectionEnabled && (
            <SelectionRectangle ref={selectionRectangleRef}>
              <SelectableTextTooltip />
            </SelectionRectangle>
          )}
          <ThumbnailPreview
            setCurrentPage={onThumbnailPageClicked}
            currentPage={currentPageNumber}
            totalNumberOfPages={pdf?.numPages || 0}
          />
          <DocumentScroll
            ref={setWrapperRef}
            className="document-scroll-container"
          >
            {visibilities.map((visible, index) => (
              <PageWrapper
                aspectRatio={
                  documentAspectRatio ?? pagesAspectRatio?.[index + 1]
                }
                searchMatches={searchMatches}
                activeMatchIndex={activeMatchIndex}
                annotations={visibleAnnotations}
                pageRef={setPageRef}
                key={`page_${index}`}
                index={index}
                advancedOCRSelectionActive={advancedOCRSelectionActive}
                page={pageRefs[index]}
                scale={zoom / 100}
                className="document-view-page"
                visible={visible}
                pdfWidth={pdfWidth}
                onClickNavigationLink={onClickNavigationLink}
                hasBackdrop={
                  showTranslatedPages &&
                  !document.translatedPageIndices?.includes(index)
                }
              />
            ))}
          </DocumentScroll>
        </Document>
      );
  }

  return (
    <Container ref={containerRef}>
      <Outer
        style={containerStyleOverride}
        selectionToolActive={advancedOCRSelectionActive}
      >
        <Toolbar
          activeAnnotation={activeAnnotation}
          isSearchActive={isSearchActive}
          onSearchToggle={toggleSearch}
          onClickClearActiveAnnotation={onClickClearActiveAnnotation}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          document={document}
          annotationFilter={annotationFilter}
          setAnnotationFilter={setAnnotationFilter}
          selectedAnnotationSets={selectedAnnotationSets}
          setSelectedAnnotationSets={setSelectedAnnotationSets}
          documentsToSelect={documentsToSelect}
          advancedOCRSelectionEnabled={advancedOCRSelectionEnabled}
          advancedOCRSelectionActive={advancedOCRSelectionActive}
          currentZoom={zoom}
          onDocumentChange={_onDocumentChange}
          onAdvancedOCRSelection={onAdvancedOCRSelection}
          onPageNumberChange={onPageNumberChange}
          setZoom={updateZoom}
          totalNumberOfPages={totalNumberOfPages}
          currentPageNumber={currentPageInputValue}
          translationToolEnabled={showTranslationTool}
          onLanguageChange={onLanguageChange}
          onAuthenticLanguageChange={onAuthenticLanguageChange}
          startTranslate={startTranslate}
          style={toolbarStyle}
          hideAnnotations={hideAnnotations}
          onPreviousScreen={onPreviousScreen}
          viewerIndex={viewerIndex}
        />
        {isSearchActive && (
          <DocumentSearch
            document={document}
            isSearching={isSearching}
            matchCount={searchMatches.length}
            activeMatchIndex={activeMatchIndex}
            searchText={searchText}
            hasOcrLayer={hasOcrLayer}
            viewerIndex={viewerIndex}
            onSearchChange={setSearchText}
            onActiveMatchChange={setActiveMatchIndex}
            onClose={toggleSearch}
          />
        )}
        <DocumentOuter>{documentContent}</DocumentOuter>
      </Outer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
`;

const Outer = styled.div<{ selectionToolActive: boolean }>`
  ${(props) =>
    props.selectionToolActive &&
    css`
      cursor: crosshair;
    `}
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
  min-height: 0;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primary200_60};
  border-radius: 12px 12px 0 0;
  overflow: hidden;
`;

const DocumentOuter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  min-height: 0;
  position: relative;
`;

const DocumentScroll = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  height: 100%;
  min-height: 0;
`;

const SelectionRectangle = styled.div`
  position: absolute;
  border: 3px solid #4286f4;
  display: none;
  pointer-events: none;
  z-index: 10;
`;

const SelectableTextTooltip = styled.div`
  background-image: linear-gradient(
    118deg,
    ${(props) => props.theme.colors.gradient.dropdown.start},
    ${(props) => props.theme.colors.gradient.dropdown.end}
  );
  min-width: 300px;
  max-width: 500px;
  z-index: 10;
  pointer-events: none;
  border-radius: 6px;
  position: fixed;
  padding: 8px;
`;

const NoDataMessage = styled.span`
  font-size: 18px;
  text-align: center;
  color: ${(props) => props.theme.colors.warning};
`;

const NoClaimsMessage = styled.span`
  font-size: 15px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.white70};
`;

const NoDataWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  padding-top: 200px;
`;

const DocumentLoading = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TranslationErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 40px;

  h3 {
    margin-top: 30px;
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.white100};
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -100px;
    gap: 2px;
  }

  .error-message {
    font-size: 14px;
    color: ${(props) => props.theme.colors.white87};
    text-align: center;

    a {
      color: ${(props) => props.theme.colors.white87};
      text-decoration: underline;
    }
  }
`;

export default SingleDocumentRenderer;
