import { useRef, useEffect } from 'react';
import { DocumentLocation } from '../../types';
import { checkIsCanvasProjectionInView } from '../../util';

interface Props {
  searchMatches: DocumentLocation[][];
  activeMatchIndex: number | null;
  currentPageNumber: number;
  pageRefs: HTMLDivElement[];
  wrapperRef: HTMLDivElement | null;
  setCurrentPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

const useJumpToSearchMatch = ({
  searchMatches,
  activeMatchIndex,
  currentPageNumber,
  pageRefs,
  wrapperRef,
  setCurrentPageNumber,
}: Props) => {
  const lastMatchJumpIndex = useRef<number | null>(null);

  useEffect(() => {
    // No active match or jump to match already executed
    if (
      activeMatchIndex == null ||
      activeMatchIndex === lastMatchJumpIndex.current
    ) {
      return;
    }
    // Take the first part of match (first word of phrase, or first part of split word)
    const match = searchMatches[activeMatchIndex]?.[0];
    if (!match) {
      return;
    }

    // Save index so effect runs only when active match index changes
    lastMatchJumpIndex.current = activeMatchIndex;
    const { pageNumber, pageLocation } = match;
    const pageElement = pageRefs[pageNumber - 1];

    if (!wrapperRef || !pageElement) {
      return;
    }

    // Check if match is visible on screen
    const isMatchInView = checkIsCanvasProjectionInView(
      wrapperRef,
      pageElement,
      pageLocation
    );

    // If visible, no need to jump, so return
    if (isMatchInView) {
      return;
    }

    // Match is not visible, so we need to make adjustments
    if (currentPageNumber !== pageNumber) {
      setCurrentPageNumber(pageNumber);
    }

    // We need to account for location of match on the page and jump to approximate page area
    let top = pageElement.offsetTop;
    if (pageLocation.y > 0.6) {
      top = top + pageElement.clientHeight * 0.5;
    }
    wrapperRef.scrollTo({
      top,
    });
  }, [currentPageNumber, searchMatches, activeMatchIndex, pageRefs]);
};

export default useJumpToSearchMatch;
