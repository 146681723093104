import { ResponseLetterSection } from '../types';

export const OBJECTIONS_LEGAL_BASIS = {
  [ResponseLetterSection.UsptoAnticipation]: '35 U.S.C. § 102',
  [ResponseLetterSection.UsptoClaimAmendments]: '37 C.F.R. § 1.121',
  [ResponseLetterSection.UsptoClaimInterpretation]: '35 U.S.C. § 112(f)',
  [ResponseLetterSection.UsptoFormalitiesGeneral]: 'Various',
  [ResponseLetterSection.UsptoFormalitiesInventorship]: 'Various',
  [ResponseLetterSection.UsptoFormalitiesTitle]: 'Various',
  [ResponseLetterSection.UsptoFormalitiesAbstract]: 'Various',
  [ResponseLetterSection.UsptoFormalitiesClaims]: 'Various',
  [ResponseLetterSection.UsptoFormalitiesImproperMarkush]: 'Various',
  [ResponseLetterSection.UsptoFormalitiesSpecification]: 'Various',
  [ResponseLetterSection.UsptoFormalitiesDrawings]: 'Various',
  [ResponseLetterSection.UsptoFormalitiesSequences]: 'Various',
  [ResponseLetterSection.UsptoFormalitiesInformationDisclosureStatement]:
    'Various',
  [ResponseLetterSection.UsptoIndefiniteness]: '35 U.S.C. § 112(b) ',
  [ResponseLetterSection.UsptoNewMatter]: '35 U.S.C. § 132',
  [ResponseLetterSection.UsptoObviousness]: '35 U.S.C. § 103',
  [ResponseLetterSection.UsptoPriority]: '35 U.S.C. §§ 102(d) / 119 / 120',
  [ResponseLetterSection.UsptoDoublePatentingObviousness]: '35 U.S.C. § 101',
  [ResponseLetterSection.UsptoDoublePatentingStatutory]: '35 U.S.C. § 101',
  [ResponseLetterSection.UsptoRestrictionElection]:
    '35 U.S.C. § 121 / 37 C.F.R. § 1.142',
  [ResponseLetterSection.UsptoSubjectMatterEligibility]: '35 U.S.C. § 101',
  [ResponseLetterSection.UsptoUtility]: '35 U.S.C. § 101',
  [ResponseLetterSection.UsptoWrittenDescriptionEnablementBestMode]:
    '35 U.S.C. § 112(a)',
  [ResponseLetterSection.UsptoReissueRecapture]: '35 U.S.C. § 251',
  [ResponseLetterSection.UsptoPlant]: '37 CFR § 1.166',
};
