import SHARED_ROUTES, { generateUrl } from '../utils/routing';
import { ApplicationIdentifier, DocViewerParams } from '../types';

export function prepareQueryParams(
  params: DocViewerParams
): Record<string, string> {
  const queryParams: Record<string, string> = {
    applicationIdentifier: params.applicationIdentifier,
    defaultVisibleDocCount: `${params.defaultVisibleDocCount}` || '2',
    firstDocumentId: params.firstDocumentId,
    secondDocumentId: params.secondDocumentId,
  };
  if (params.rootId) {
    queryParams.rootId = params.rootId;
  }

  if (params.title) {
    queryParams.title = params.title;
  }

  if (params.subtitle) {
    queryParams.subtitle = params.subtitle;
  }

  if (params.thirdDocumentId) {
    queryParams.thirdDocumentId = params.thirdDocumentId;
  }

  if (params.startingPages) {
    queryParams.startingPages = params.startingPages;
  }

  if (params.hideAnnotations) {
    queryParams.hideAnnotations = params.hideAnnotations ? 'true' : 'false';
  }

  if (params.hideHeader) {
    queryParams.hideHeader = params.hideHeader ? 'true' : 'false';
  }

  if (params.hideClaims) {
    queryParams.hideClaims = params.hideClaims.toString();
  }

  return queryParams;
}

declare global {
  interface Window {
    getScreenDetails(): Promise<ScreenDetails>;
  }
  interface Screen {
    availLeft: number;
    availTop: number;
    isExtended: boolean;
  }
}

interface ScreenDetails {
  screens: Screen[];
  currentScreen: Screen;
}

export const openDocViewer = (
  docViewerParams: DocViewerParams
): Window | null => {
  const urlToOpen = generateUrl(
    SHARED_ROUTES.DOC_VIEWER,
    undefined,
    prepareQueryParams(docViewerParams)
  );

  const openOnOtherScreen = async () => {
    if (window?.screen?.isExtended) {
      const screenDetails: ScreenDetails = await window.getScreenDetails();
      const otherScreen: Screen | undefined = screenDetails.screens.find(
        (screen) => screen !== screenDetails.currentScreen
      );

      if (!!otherScreen) {
        window.open(
          urlToOpen,
          '_blank',
          `left=${otherScreen.availLeft},` +
            `top=${otherScreen.availTop},` +
            `width=${otherScreen.availWidth},` +
            `height=${otherScreen.availHeight},` +
            'fullscreen'
        );
      } else {
        console.error('No other screens found.');
      }
    } else {
      console.error('Screen extension not supported.');
    }
  };

  if (docViewerParams.windowToUse) {
    return docViewerParams.windowToUse.open(urlToOpen, '_self');
  }
  if (
    docViewerParams.applicationIdentifier === ApplicationIdentifier.PATENTOR &&
    window?.screen?.isExtended
  ) {
    openOnOtherScreen();
  } else {
    return window.open(urlToOpen, '_blank');
  }

  return null;
};
