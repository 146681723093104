import { NodeSpec, Node, TagParseRule } from 'prosemirror-model';
import { flatListGroup, listToDOM, parseInteger } from 'prosemirror-flat-list';
import {
  EDITOR_LIST_KIND_MAP,
  EditorListKind,
  ListKindToEditorListKind,
} from '../../../../types';
const nav = typeof navigator != 'undefined' ? navigator : null;
const agent = (nav && nav.userAgent) || '';
const ie_edge = /Edge\/(\d+)/.exec(agent);
const ie_upto10 = /MSIE \d/.exec(agent);
const ie_11up = /Trident\/(?:[7-9]|\d{2,})\..*rv:(\d+)/.exec(agent);
const ie = !!(ie_upto10 || ie_11up || ie_edge);
const safari = !ie && !!nav && /Apple Computer/.test(nav.vendor);

function defaultAttributesGetter(node: Node) {
  let _a;
  const attrs = node.attrs;
  const markerHidden =
    ((_a = node.firstChild) == null ? void 0 : _a.type) === node.type;

  let kind = attrs.kind;

  if (typeof kind === 'string') {
    kind =
      ListKindToEditorListKind[kind as keyof typeof ListKindToEditorListKind];
  }

  const mappedKind = EDITOR_LIST_KIND_MAP[kind as EditorListKind];
  const markerType = markerHidden ? void 0 : mappedKind || 'bullet';
  const domAttrs = {
    class: 'prosemirror-flat-list',
    'data-list-kind': markerType,
    'data-list-order': attrs.order != null ? String(attrs.order) : void 0,
    'data-list-checked': attrs.checked ? '' : void 0,
    'data-list-collapsed': attrs.collapsed ? '' : void 0,
    'data-list-collapsable': node.childCount >= 2 ? '' : void 0,
    style:
      attrs.order != null
        ? // Safari (at least version <= 16.5) doesn't support `counter-set`
          safari
          ? `counter-reset: prosemirror-flat-list-counter; counter-increment: prosemirror-flat-list-counter ${attrs.order};`
          : `counter-set: prosemirror-flat-list-counter ${attrs.order};`
        : void 0,
  };
  return domAttrs;
}

function createParseDomRules(): readonly TagParseRule[] {
  const listKindGetter = (element: Element): EditorListKind => {
    const listKind =
      element.getAttribute('data-list-kind') ||
      EDITOR_LIST_KIND_MAP[EditorListKind.Bullet];
    const entry = Object.entries(EditorListKind).find(
      ([, value]) =>
        value.toString().toLowerCase() ===
        listKind.replace(/-/g, '').toLowerCase()
    );
    return entry
      ? (parseInt(entry[0], 10) as EditorListKind)
      : EditorListKind.Bullet;
  };
  return [
    {
      tag: 'div[data-list-kind]',
      getAttrs: (element) => {
        if (typeof element === 'string') {
          return {};
        }
        return {
          nodeId: element.getAttribute('nodeId'),
          kind: listKindGetter(element),
          order: parseInteger(element.getAttribute('data-list-order')),
          checked: element.hasAttribute('data-list-checked'),
          collapsed: element.hasAttribute('data-list-collapsed'),
        };
      },
    },
    {
      tag: 'div[data-list]',
      getAttrs: (element) => {
        if (typeof element === 'string') {
          return {};
        }
        return {
          nodeId: element.getAttribute('nodeId'),
          kind: listKindGetter(element),
          order: parseInteger(element.getAttribute('data-list-order')),
          checked: element.hasAttribute('data-list-checked'),
          collapsed: element.hasAttribute('data-list-collapsed'),
        };
      },
    },
    {
      tag: 'ul > li',
      getAttrs: (element) => {
        if (typeof element !== 'string') {
          let checkbox: HTMLElement | null =
            element.firstChild as HTMLElement | null;
          for (let i = 0; i < 3 && checkbox; i++) {
            if (['INPUT', 'UL', 'OL', 'LI'].includes(checkbox.nodeName)) {
              break;
            }
            checkbox = checkbox.firstChild as HTMLElement | null;
          }
          if (
            checkbox &&
            checkbox.nodeName === 'INPUT' &&
            checkbox.getAttribute('type') === 'checkbox'
          ) {
            return {
              kind: 'task',
              checked: checkbox.hasAttribute('checked'),
            };
          }
          if (
            element.hasAttribute('data-task-list-item') ||
            element.getAttribute('data-list-kind') === 'task'
          ) {
            return {
              kind: 'task',
              checked:
                element.hasAttribute('data-list-checked') ||
                element.hasAttribute('data-checked'),
            };
          }
          if (
            element.hasAttribute('data-toggle-list-item') ||
            element.getAttribute('data-list-kind') === 'toggle'
          ) {
            return {
              kind: 'toggle',
              collapsed: element.hasAttribute('data-list-collapsed'),
            };
          }
        }
        return {
          kind: EditorListKind.Bullet,
        };
      },
    },
    {
      tag: 'ol > li',
      getAttrs: (element) => {
        if (typeof element === 'string') {
          return {
            kind: EditorListKind.Decimal,
          };
        }
        return {
          kind: EditorListKind.Decimal,
          order: parseInteger(element.getAttribute('data-list-order')),
        };
      },
    },
  ];
}

const list: NodeSpec = {
  content: 'block+',
  group: `${flatListGroup} block`,
  defining: true,
  attrs: {
    nodeId: {
      default: null,
    },
    kind: {
      default: EditorListKind.Bullet,
    },
    order: {
      default: null,
    },
    checked: {
      default: false,
    },
    collapsed: {
      default: false,
    },
    counterId: {
      default: null,
    },
    numberingTemplate: {
      default: '{0}',
    },
  },
  toDOM: (node) =>
    listToDOM({
      node,
      getAttributes: (node) => {
        // parse list kind
        const defaultAttrs = defaultAttributesGetter(node);
        return {
          ...defaultAttrs,
          nodeId: node.attrs.nodeId,
        };
      },
    }),
  parseDOM: createParseDomRules(),
};
export default list;
