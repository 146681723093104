import React from 'react';
import styled from 'styled-components';
import { Language } from '../../../types';
import { LanguagePicker, LanguagePickerItem } from '../../LanguageControls';
import {
  TranslationPickerTooltip,
  AuthenticTag,
  TranslatedIcon,
} from '../common';
import useTranslation from '../../../translations';

interface Props {
  languages: Language[];
  activeLanguage?: Language;
  authenticLanguage?: Language;
  availableTranslations?: Language[];
  partiallyTranslatedLanguages?: Language[];
  onChange: (language: Language) => void;
  onAuthenticLanguageChange?: (language: Language) => void;
  onRetranslate?: (language: Language) => void;
  triggerRender?: (isActive: boolean, onClick: () => void) => void;
}

const TranslationPicker: React.FC<Props> = ({
  languages,
  activeLanguage,
  authenticLanguage,
  availableTranslations,
  partiallyTranslatedLanguages,
  onChange,
  onAuthenticLanguageChange,
  onRetranslate,
  triggerRender,
}) => {
  const t = useTranslation();

  const languageItems = languages.map((l) => {
    const isAuthenticLanguage = l === authenticLanguage;
    const hasTranslationAvailable = availableTranslations?.includes(l);
    const isPartiallyTranslated = partiallyTranslatedLanguages?.includes(l);
    return {
      language: l,
      content: (
        <Content>
          {!isAuthenticLanguage && hasTranslationAvailable && (
            <>
              {isPartiallyTranslated ? (
                <TranslationPickerTooltip
                  title={t('TRANSLATION_PICKER.PARTIALLY_TRANSLATED')}
                >
                  <PartiallyTranslatedIcon className="icn-checkmark">
                    <div className="translation-item-arc translation-item-arc__start"></div>
                    <div className="translation-item-arc translation-item-arc__end"></div>
                  </PartiallyTranslatedIcon>
                </TranslationPickerTooltip>
              ) : (
                <TranslatedIcon style={{ marginLeft: 5 }} />
              )}
              {onRetranslate && (
                <TranslationPickerTooltip
                  title={t('TRANSLATION_PICKER.RETRANSLATE')}
                >
                  <RetranslateIcon
                    className="icn-refresh language-picker-item--show-on-hover"
                    onClick={(e) => {
                      e.stopPropagation();
                      onRetranslate(l);
                    }}
                  />
                </TranslationPickerTooltip>
              )}
            </>
          )}
        </Content>
      ),
    };
  });

  let headerContent = null;

  if (authenticLanguage) {
    headerContent = authenticLanguage && (
      <LanguagePickerItem
        language={authenticLanguage}
        highlighted
        content={
          <TranslationPickerHeader>
            <AuthenticTag style={{ marginLeft: 8 }} />
            {onAuthenticLanguageChange && (
              <LanguagePicker
                languages={languages}
                onChange={onAuthenticLanguageChange}
                positions={['bottom']}
                header={
                  <ChangeAuthenticLanguageHeader>
                    {t('TRANSLATION_PICKER.CHANGE_AUTHENTIC_LANGUAGE')}
                  </ChangeAuthenticLanguageHeader>
                }
                triggerRender={(_, togglePopover) => (
                  <div>
                    <TranslationPickerTooltip
                      title={t('TRANSLATION_PICKER.CHANGE_AUTHENTIC_LANGUAGE')}
                    >
                      <RetranslateIcon
                        className="icn-refresh language-picker-item--show-on-hover"
                        onClick={(e) => {
                          e.stopPropagation();
                          togglePopover();
                        }}
                      />
                    </TranslationPickerTooltip>
                  </div>
                )}
              />
            )}
          </TranslationPickerHeader>
        }
        onClick={() => onChange(authenticLanguage)}
      />
    );
  }

  return (
    <LanguagePicker
      languages={languageItems}
      activeLanguage={activeLanguage}
      highlightedLanguages={availableTranslations}
      header={headerContent}
      onChange={onChange}
      triggerRender={triggerRender}
    />
  );
};

export default TranslationPicker;

const RetranslateIcon = styled.i`
  visibility: hidden;
  border-radius: 50%;
  font-size: 18px;
  margin-left: 5px;
  transition: color 0.3s ease;
  color: ${(props) => props.theme.colors.white70};
  &:hover {
    color: ${(props) => props.theme.colors.white87};
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
`;

const PartiallyTranslatedIcon = styled.i`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 12px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #5a5b66;
  color: #7b7c84;

  .translation-item-arc {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    border: 3px solid;
  }
  .translation-item-arc__start {
    border-color: transparent transparent transparent
      ${(props) => props.theme.colors.secondary500};
    transform: rotate(135deg);
  }
  .translation-item-arc__end {
    border-color: transparent transparent transparent
      ${(props) => props.theme.colors.secondary500};
    transform: rotate(180deg);
  }
`;

const ChangeAuthenticLanguageHeader = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white87};
`;

const TranslationPickerHeader = styled.div`
  display: flex;
  items: center;
`;
