import { Dropdown, Switch, Tooltip } from 'antd';
import styled from 'styled-components';
import React, { Fragment, useCallback } from 'react';
import useTranslation from '../../../translations';
import { FlatButton } from '../../Button';
import { useMediaQuery } from '../../../hooks';
import { DEVICE } from '../../../constants';
import AnnotationColorBox from './AnnotationColorBox';
import {
  getAnnotationIdByKey,
  getAnnotationLegalBasis,
  getAnnotationStructure,
  produceSelectedAnnotationSetsOnToggleAnnotationSet,
} from './annotationsUtil';

interface Props {
  selectedAnnotationSets?: Record<string, boolean>;
  annotationSets?: Record<string, string>;
  setSelectedAnnotationSets?: (s: Record<string, boolean>) => void;
}

const AnnotationsLayerPicker: React.FC<Props> = ({
  selectedAnnotationSets,
  annotationSets,
  setSelectedAnnotationSets,
}) => {
  const desktopHD = useMediaQuery(DEVICE.desktopHD);

  const t = useTranslation();

  const hasAnnotationsSets =
    annotationSets && Object.keys(annotationSets)?.length !== 0;

  const isUSPTO = Boolean(
    annotationSets &&
      Object.values(annotationSets).some((as) => as.startsWith('USPTO_'))
  );

  const rootAnnotations = getAnnotationStructure(isUSPTO).filter(
    (a) => a.parentKey === undefined
  );

  const onChangeAnnotationToggle = useCallback(
    (annotationId: string, value: boolean) => {
      const newSelectedAnnotationSets =
        produceSelectedAnnotationSetsOnToggleAnnotationSet(
          annotationId,
          value,
          annotationSets,
          selectedAnnotationSets
        );

      setSelectedAnnotationSets?.(newSelectedAnnotationSets);
    },
    [selectedAnnotationSets]
  );

  const renderAnnotationSet: (key: string, parentIndex: number) => JSX.Element =
    useCallback(
      (key: string, parentIndex = 0) => {
        const annotationId =
          getAnnotationIdByKey(key, annotationSets) || `placeholder-${key}`;

        const childAnnotations = getAnnotationStructure(isUSPTO).filter(
          (a) => a.parentKey === key
        );

        const legalBasis = getAnnotationLegalBasis(key);

        const checked = selectedAnnotationSets?.[annotationId];

        return (
          <Fragment key={key}>
            <MenuItem>
              <Flex
                style={{
                  paddingLeft: `calc(20px + ${parentIndex} * 30px)`,
                  paddingRight: 20,
                }}
              >
                <Annotation
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onChangeAnnotationToggle(annotationId, !checked);
                  }}
                >
                  <AnnotationColorBox annotationKey={key} />
                  {t(`ANNOTATIONS_LAYER_PICKER.ANNOTATIONS.${key}`, {
                    defaultValue: key,
                  })}
                  {legalBasis && <LegalBasis>{legalBasis}</LegalBasis>}
                </Annotation>
                <Switch
                  size={desktopHD ? 'default' : 'small'}
                  style={{ marginLeft: 10 }}
                  checked={checked}
                  onChange={(value, e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChangeAnnotationToggle(annotationId, value);
                  }}
                />
              </Flex>
            </MenuItem>
            {childAnnotations.length > 0 &&
              childAnnotations.map((a) =>
                renderAnnotationSet(a.key, parentIndex + 1)
              )}
          </Fragment>
        );
      },
      [
        onChangeAnnotationToggle,
        setSelectedAnnotationSets,
        selectedAnnotationSets,
        t,
      ]
    );

  return (
    <Dropdown
      trigger={['click']}
      disabled={!hasAnnotationsSets}
      overlayClassName="annotations-layer-picker"
      menu={
        annotationSets && {
          items: rootAnnotations.map((a) => ({
            label: renderAnnotationSet(a.key, 0),
            key: a.key,
          })),
          style: { padding: '10px 0', borderRadius: 12 },
        }
      }
    >
      <Tooltip title={t('SETTINGS')}>
        <SettingsButton>
          <SettingsIcon className="icn-settings" />
        </SettingsButton>
      </Tooltip>
    </Dropdown>
  );
};

export default AnnotationsLayerPicker;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Annotation = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  cursor: pointer;
  font-size: 15px;
`;

const MenuItem = styled.div`
  background: transparent !important;
  padding: 10px !important;

  &:hover {
    background: rgba(103, 110, 127, 0.2) !important;
  }

  .ant-switch-checked {
    background-color: ${({ theme }) => theme.colors.blue200} !important;
  }
`;

const SettingsButton = styled(FlatButton)`
  width: 30px;
  height: 30px;
  margin: 0;
  color: black;
  border-radius: 3px;

  // bigger than 1920px
  @media (min-width: 1921px) {
    width: 34px;
    height: 34px;
  }
`;

const SettingsIcon = styled.i`
  font-size: 20px;
  // bigger than 1920px
  @media (min-width: 1921px) {
    font-size: 24px;
  }
`;

const LegalBasis = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white60};
`;
