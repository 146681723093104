import type { NodeJSON } from 'prosekit/core';
import type { NodeJSONRaw } from '../types';
import type { DocTransformer } from '../index';
import { buildNestedClaimByIndent } from './indent';

export const normalizeInputJSON = (
  input: unknown,
  instance: DocTransformer
): NodeJSON => {
  let node = input as NodeJSONRaw;
  // apply more transformations here
  node = buildNestedClaimByIndent(node, instance);
  return node as NodeJSON;
};
