import React, { useState } from 'react';
import { Checkbox, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { B2 } from '../Atoms/Atoms';
import useTranslation from '../../translations';

interface Option {
  label: string;
  value: string;
  selected: boolean;
}

interface Props {
  searchQuery?: string;
  options: Option[];
  onChange: (options: Option[]) => void;
}

const SearchInOptions: React.FC<Props> = ({
  searchQuery,
  options,
  onChange,
}) => {
  const t = useTranslation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const selectedLabels = options.filter((o) => o.selected).map((o) => o.label);
  const triggerContent = (
    <div>
      {selectedLabels.length === 0 && 'All'}
      {selectedLabels.length === 1 && selectedLabels[0]}
      {selectedLabels.length > 1 && `${selectedLabels.length} selected`}
    </div>
  );

  const handleClearAllClick = () => {
    const newOptions = options.map((o) => ({
      ...o,
      selected: false,
    }));

    onChange(newOptions);
  };

  const handleSelectAllClick = () => {
    const newOptions = options.map((o) => ({
      ...o,
      selected: true,
    }));

    onChange(newOptions);
  };

  const dropdownContent = (
    <DropdownContentOuter>
      <FilterInner>
        <FilterControl onClick={handleSelectAllClick}>
          {t('ITEM_LIST.FILTERING.SELECT_ALL')}
        </FilterControl>
        <FilterControl onClick={handleClearAllClick}>
          {t('ITEM_LIST.FILTERING.CLEAR')}
        </FilterControl>
      </FilterInner>
      <Divider />
      <FilterItems>
        {options.map((option) => (
          <DropdownContentRow key={option.value}>
            <div>{option.label}</div>
            <Checkbox
              checked={option.selected}
              onChange={(e) => {
                const newOptions = options.map((o) => {
                  if (o.value === option.value) {
                    return {
                      ...o,
                      selected: e.target.checked,
                    };
                  }

                  return o;
                });

                onChange(newOptions);
              }}
            />
          </DropdownContentRow>
        ))}
      </FilterItems>
    </DropdownContentOuter>
  );

  return (
    <Outer spaceFromRight={Boolean(searchQuery)}>
      <Inner>
        <Dropdown
          open={menuOpen}
          onOpenChange={(v) => setMenuOpen(v)}
          trigger={['click']}
          overlay={dropdownContent}
          placement="bottomCenter"
        >
          <Trigger onClick={() => setMenuOpen(true)}>
            {triggerContent}
            <DownOutlined />
          </Trigger>
        </Dropdown>
      </Inner>
    </Outer>
  );
};

export default SearchInOptions;

const Outer = styled.div<{ spaceFromRight: boolean }>`
  position: absolute;
  right: ${(props) => (props.spaceFromRight ? '40px' : '20px')};
  transition: right 0.3s ease;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
`;

const Inner = styled.div``;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => props.theme.colors.white87};
  background: ${(props) => props.theme.colors.primary200_20};
  border-radius: 6px;
  padding: 4px 13px 4px 13px;
  &:hover {
    color: white;
  }
  i {
    transition: color 0.3s ease;
  }
`;

const DropdownContentOuter = styled.div`
  min-width: 250px;
  background: linear-gradient(135deg, #3b3f4f 0%, #31323f 100%);
  border-radius: 12px;
`;

const DropdownContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  &:hover {
    background: ${(props) => props.theme.colors.primary200_20};
  }
`;

const FilterInner = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white20};
`;

const FilterControl = styled(B2)<{ $disabled?: boolean }>`
  color: ${(props) => props.theme.colors.blue300};
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  padding: 14px 20px;
  ${(props) =>
    props.$disabled && 'pointer-events: none; cursor: auto; opacity: 60%;'}
`;

const FilterItems = styled.div`
  padding: 10px 0;
`;
