import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAnnotationSets } from '../../../queries';
import useTranslation from '../../../translations';
import { Annotation, DocumentMetadata } from '../../../types';
import { FlatButton } from '../../Button';
import {
  getAnnotationIdByKey,
  getAnnotationStructure,
  getAnnotationStyle,
  produceSelectedAnnotationSetsOnToggleAnnotationSet,
} from './annotationsUtil';
import AnnotationsLayerPicker from './AnnotationsLayerPicker';

interface Props {
  onClose: () => void;
  activeAnnotation?: Annotation | null;
  onClickClearActiveAnnotation?: () => void;
  document: DocumentMetadata;
  annotationFilter: string;
  setAnnotationFilter: (annotationSetKey: string) => void;
  selectedAnnotationSets?: Record<string, boolean>;
  setSelectedAnnotationSets?: (s: Record<string, boolean>) => void;
}

const AnnotationsToolbar: React.FC<Props> = ({
  onClose,
  document,
  activeAnnotation,
  onClickClearActiveAnnotation,
  annotationFilter,
  setAnnotationFilter,
  selectedAnnotationSets,
  setSelectedAnnotationSets,
}) => {
  const annotationFiltersInnerRef = useRef<HTMLDivElement>(null);
  const [showFullAnnotationName, setShowFullAnnotationName] = useState(true);

  const t = useTranslation();

  const { data: annotationSets } = useAnnotationSets(document.id, {
    enabled: document.id !== undefined,
  });

  const isUSPTO = Boolean(
    annotationSets &&
      Object.values(annotationSets).some((as) => as.startsWith('USPTO_'))
  );

  const rootAnnotations = getAnnotationStructure(isUSPTO).filter(
    (a) => a.parentKey === undefined
  );

  const filterableAnnotationSets = useMemo(
    () =>
      rootAnnotations.filter((a) => {
        const annotationId =
          getAnnotationIdByKey(a.key, annotationSets) || `placeholder-${a.key}`;
        return selectedAnnotationSets?.[annotationId] === true;
      }),
    [rootAnnotations, selectedAnnotationSets]
  );

  useEffect(() => {
    const newSelectedAnnotationSets = rootAnnotations.reduce<
      Record<string, boolean>
    >((acc, annotation) => {
      if (annotation.key === 'CLAIM_FEATURES') {
        return acc;
      }

      const annotationId =
        getAnnotationIdByKey(annotation.key, annotationSets) ||
        `placeholder-${annotation.key}`;

      acc = {
        ...produceSelectedAnnotationSetsOnToggleAnnotationSet(
          annotationId,
          true,
          annotationSets,
          acc
        ),
      };

      return acc;
    }, {});

    setSelectedAnnotationSets?.(newSelectedAnnotationSets);

    // Reset selected annotation sets when annotation toolbar is unmounted
    return () => setSelectedAnnotationSets?.({});
  }, [annotationSets]);

  useEffect(() => {
    const callback = () => {
      if (!annotationFiltersInnerRef.current) {
        return;
      }

      // Create a temporary clone to measure full-width content
      const clone = annotationFiltersInnerRef.current.cloneNode(
        true
      ) as HTMLElement;
      clone.style.position = 'absolute';
      clone.style.visibility = 'hidden';
      clone.style.width = annotationFiltersInnerRef.current.clientWidth + 'px';

      // Force all text in clone to be full length
      clone.querySelectorAll('button').forEach((button) => {
        button.style.width = 'auto';
        button.textContent = button.getAttribute('title') || button.textContent;
      });

      window.document.body.appendChild(clone);

      // Check if the full-text version would overflow
      const wouldOverflow = clone.scrollWidth > clone.clientWidth;
      setShowFullAnnotationName(!wouldOverflow);

      // Clean up
      window.document.body.removeChild(clone);
    };

    callback();

    const resizeObserver = new ResizeObserver(callback);

    if (annotationFiltersInnerRef.current) {
      resizeObserver.observe(annotationFiltersInnerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <>
      {!activeAnnotation && (
        <Outer>
          <AnnotationFilters>
            <AnnotationFiltersInner ref={annotationFiltersInnerRef}>
              <AnnotationFilterButton
                active={!annotationFilter}
                onClick={(e) => {
                  e.preventDefault();
                  setAnnotationFilter('');
                }}
              >
                All
              </AnnotationFilterButton>
              {filterableAnnotationSets.map((annotation) => {
                const style = getAnnotationStyle(annotation.key) || {
                  backgroundColor: 'black',
                };

                return (
                  <AnnotationFilterButton
                    title={t(
                      `ANNOTATIONS_LAYER_PICKER.ANNOTATIONS.${annotation.key}`
                    )}
                    key={annotation.key}
                    active={
                      !annotationFilter || annotation.key === annotationFilter
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setAnnotationFilter(annotation.key);
                    }}
                    style={{
                      background: style.legendColor || style.backgroundColor,
                      color: 'black',
                    }}
                  >
                    {t(`ANNOTATIONS_LAYER_PICKER.LEGENDS.${annotation.key}`, {
                      defaultValue: t(
                        `ANNOTATIONS_LAYER_PICKER.ANNOTATIONS.${annotation.key}`
                      ),
                    }).slice(0, showFullAnnotationName ? undefined : 6)}
                    {showFullAnnotationName ? '' : '...'}
                  </AnnotationFilterButton>
                );
              })}
            </AnnotationFiltersInner>
          </AnnotationFilters>
          <Actions>
            <AnnotationsLayerPicker
              annotationSets={annotationSets}
              selectedAnnotationSets={selectedAnnotationSets}
              setSelectedAnnotationSets={setSelectedAnnotationSets}
            />

            <CloseButton
              style={{ color: 'black', fontSize: 14 }}
              onClick={onClose}
            >
              <CloseIcon className="icn-clear" />
            </CloseButton>
          </Actions>
        </Outer>
      )}

      {activeAnnotation && (
        <ActiveAnnotationWarning>
          <div>
            <i className="icn-info-outlined"></i>
            <span>
              {t('ANNOTATIONS_TOOLBAR.ONLY_THE_MATCHING_ANNOTATION_IS_SHOWN')}
            </span>
          </div>

          {onClickClearActiveAnnotation && (
            <ShowAllAnnotationsButton onClick={onClickClearActiveAnnotation}>
              {t('ANNOTATIONS_TOOLBAR.CLEAR')}
            </ShowAllAnnotationsButton>
          )}
        </ActiveAnnotationWarning>
      )}
    </>
  );
};

export default AnnotationsToolbar;

const ActiveAnnotationWarning = styled.div`
  width: 100%;
  padding: 3px 6px;
  background: #eaeaea;
  color: black;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  font-size: 10px;

  div {
    display: flex;
    align-items: center;
    gap: 4px;

    i {
      font-size: 16px;
    }
  }
`;

const ShowAllAnnotationsButton = styled.button`
  background: black;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 10px;
  cursor: pointer;
  flex-shrink: 0;
  font-size: 10px;
  font-weight: 500;
`;

const Outer = styled.div`
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 3px;
  background: white;
  border-bottom: 1px solid #e0e0e0;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 36px;

  // bigger than 1920px
  @media (min-width: 1921px) {
    height: 40px;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const AnnotationFilters = styled.div`
  flex: 1;
  position: relative;
`;

const AnnotationFiltersInner = styled.div`
  width: 100%;
  display: flex;
  overflow-x: hidden;
  gap: 6px;
`;

const AnnotationFilterButton = styled.button<{ active: boolean }>`
  background: black;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 10px;
  cursor: pointer;
  flex-shrink: 0;
  font-size: 10px;
  height: 24px;
  font-weight: 500;
  opacity: ${(p) => (p.active ? 1 : 0.5)};

  // bigger than 1920px
  @media (min-width: 1921px) {
    font-size: 12px;
    line-height: 20px;
    height: auto;
  }
`;

const CloseButton = styled(FlatButton)`
  width: 30px;
  height: 30px;
  margin: 0;
  border-radius: 3px;

  // bigger than 1920px
  @media (min-width: 1921px) {
    width: 34px;
    height: 34px;
  }
`;

const CloseIcon = styled.i`
  font-size: 20px;
  // bigger than 1920px
  @media (min-width: 1921px) {
    font-size: 24px;
  }
`;
