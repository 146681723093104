import React, { FC } from 'react';
import { Button } from 'antd';
import styled, { CSSObject } from 'styled-components';
import { Icon } from '../..';
import { Colors } from '../../../theme';
import useTranslation from '../../../translations';

interface Props {
  filtersApplied: boolean;
  onClearTableFilters: () => void;
  styleOverride?: CSSObject;
}

const TableFilterNotification: FC<Props> = ({
  filtersApplied,
  onClearTableFilters,
  styleOverride,
}) => {
  const t = useTranslation();

  return filtersApplied ? (
    <NotificationWrapper style={styleOverride}>
      <Icon className="icn-info-outlined" color={Colors.primary100} />
      <Notification>
        {t('ITEM_LIST.FILTERING.FILTERS_ACTIVATED')}.{' '}
        <Button type="link" onClick={onClearTableFilters}>
          {t('ITEM_LIST.FILTERING.CLEAR_FILTERS')}.
        </Button>
      </Notification>
    </NotificationWrapper>
  ) : null;
};

const NotificationWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin: 0 0 -6px 15px;
  align-items: center;
  position: fixed;
  z-index: 100000;
`;

const Notification = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.primary100};
  button {
    padding: 0;
    font-size: 13px;
    color: ${(props) => props.theme.colors.blue600};
    &:hover {
      span {
        text-decoration: underline;
      }
      color: ${(props) => props.theme.colors.blue600};
    }
  }
`;

export default TableFilterNotification;
