import { Command, EditorState, Transaction } from 'prosemirror-state';

export const insertHardBreak: Command = (
  state: EditorState,
  dispatch?: (tr: Transaction) => void
): boolean => {
  const { $from, $to } = state.selection;
  if (!$from.sameParent($to)) {
    return false;
  }

  const breakNode = state.schema.nodes.hard_break.create();

  if (dispatch) {
    const tr = state.tr.replaceSelectionWith(breakNode);
    dispatch(tr.scrollIntoView());
  }

  return true;
};
