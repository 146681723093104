import React, { MouseEventHandler, useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { Popover as AntPopover, Tooltip } from 'antd';
import styled, { useTheme } from 'styled-components';
import { T } from '../../../..';
import { hover } from '../../../utils';
import { FlatButton } from '../../Button';
import useTranslation from '../../../translations';
import {
  ModalState,
  PatentApplicationStakeholderRole,
  SCREEN,
} from '../../../types';
import { useSessionContext } from '../../../contexts';
import ActionStakeholderPopover from './ActionStakeholderPopover';
import ContactEntry from './ContactEntry';
import ContactCard from './ContactCard';

import AddStakeholderPopover from './AddStakeholderPopover';

interface Props {
  item: T.StakeholderListItem;
  availableRoles: T.PatentApplicationStakeholderRole[];
  onAddStakeholder: (
    item: T.StakeholderListItem,
    newRole: T.PatentApplicationStakeholderRole
  ) => void;
  customItemRenderer?: (item: T.StakeholderListItem) => React.ReactNode;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
  editScreen: SCREEN;
}

const ContactListItem: React.FC<Props> = ({
  item,
  onAddStakeholder,
  availableRoles,
  setModalState,
  editScreen,
}) => {
  const t = useTranslation();
  const theme = useTheme();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isAddPopoverOpen, setIsAddPopoverOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const listItemRef = useRef<HTMLAnchorElement | HTMLDivElement>(null);
  const [iconClassName, setIconClassName] =
    useState<string>('icn-done-outlined');
  const [btnText, setBtnText] = useState<string>('ACTION.ADDED');

  const { session } = useSessionContext();
  const isEditable =
    session.userId === item?.contact?.userId ||
    session.userId === item?.userId ||
    item?.userId === null;

  // if availableRoles does not contain any of the following roles, then the item is only for attorneys
  const isOnlyForAttorneys = !availableRoles.some((r) =>
    [
      PatentApplicationStakeholderRole.Applicant,
      PatentApplicationStakeholderRole.Examiner,
      PatentApplicationStakeholderRole.Inventor,
      PatentApplicationStakeholderRole.LawFirm,
    ].includes(r)
  );

  const hideEditButton = isOnlyForAttorneys && !isEditable;

  const handleMouseOver = () => {
    setIconClassName('icn-remove-outlined');
    setBtnText('ACTION.REMOVE');
  };

  const handleMouseOut = () => {
    setIconClassName('icn-done-outlined');
    setBtnText('ACTION.ADDED');
  };

  const onItemClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setIsAddPopoverOpen(true);
  };

  return (
    <StakeholderItem
      ref={listItemRef as React.RefObject<HTMLDivElement>}
      key={item.id}
      style={{ minHeight: '50px' }}
      data-testid="list-item"
    >
      <PopoverWrapper>
        <AntPopover
          key={item.id}
          trigger="click"
          placement="bottomLeft"
          destroyTooltipOnHide
          open={isVisible}
          content={<ContactCard contact={item.contact} />}
        >
          <ContactWrapper onClick={() => setIsVisible(!isVisible)}>
            <ContactEntry title={item.displayName} subtitle={item.company} />
          </ContactWrapper>
        </AntPopover>
      </PopoverWrapper>

      <ActionsWrapper>
        {item.role !== undefined ? (
          <>
            <Button
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              leftIcon={<i className={iconClassName} />}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setModalState((prev) => ({
                  ...prev,
                  currentScreen: SCREEN.REMOVE_STAKEHOLDER,
                  activeStakeholder:
                    ({
                      ...item,
                      id: item.stakeholderId,
                    } as T.PatentApplicationStakeholder) || null,
                }));
              }}
            >
              <span>{t(btnText)}</span>
            </Button>
          </>
        ) : (
          <Popover
            isOpen={isAddPopoverOpen}
            positions={['bottom']}
            align={'center'}
            containerStyle={{ zIndex: '10000' }}
            onClickOutside={() => setIsAddPopoverOpen(false)}
            content={
              <AddStakeholderPopover
                item={item}
                availableRoles={availableRoles}
                onAddStakeholder={onAddStakeholder}
                setIsAddPopoverOpen={setIsAddPopoverOpen}
              />
            }
          >
            <AddButton
              leftIcon={<i className="icn-add" />}
              onClick={onItemClick}
            >
              {t('ACTION.ADD')}
            </AddButton>
          </Popover>
        )}
        {!hideEditButton && (
          <Popover
            isOpen={isPopoverOpen}
            positions={['bottom']}
            align={'start'}
            containerStyle={{ zIndex: '10000', background: 'unset' }}
            onClickOutside={() => setIsPopoverOpen(false)}
            clickOutsideCapture
            content={
              <ActionStakeholderPopover
                item={item}
                setIsPopoverOpen={setIsPopoverOpen}
                setModalState={setModalState}
                editScreen={editScreen}
              />
            }
          >
            <Trigger
              onClick={() => setIsPopoverOpen((prevState) => !prevState)}
              disabled={!isEditable}
            >
              <Tooltip
                placement="top"
                title={t('MORE')}
                overlayStyle={{ whiteSpace: 'pre-line' }}
                overlayInnerStyle={{
                  color: theme.colors.white87,
                  padding: '8px 10px',
                  fontSize: '11px',
                  borderRadius: '4px',
                  backgroundColor: '#5C616D',
                }}
              >
                <TriggerIcon className="icn-more-horizontal" />
              </Tooltip>
            </Trigger>
          </Popover>
        )}
      </ActionsWrapper>
    </StakeholderItem>
  );
};

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 146px;
`;
const PopoverWrapper = styled.div`
  padding-top: 0;
  margin-top: -3px;
  .ant-popover-content {
    margin-left: 30px;
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 20px;
    border-radius: 14px;
    background: linear-gradient(135deg, #3b3f4f 0%, #31323f 100%);
  }
  .ant-popover-inner {
    background-color: unset;
  }
  .ant-modal-title {
    font-weight: 700;
    font-size: 20px;
  }
`;
const StakeholderItem = styled.div`
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 28px 8px 40px;
  ${hover('rgba(0, 0, 0, 0.1)')};
`;

const AddButton = styled(FlatButton)`
  padding: 5px 9px;
  margin: 0;
  border-radius: 5px;
  &:hover {
    background: ${(props) => props.theme.colors.primary200_20};
  }
`;
const Button = styled(FlatButton)`
  padding: 5px 9px;
  margin: 0;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.success};
  &:hover {
    background: ${(props) => props.theme.colors.primary200_20};
    color: ${(props) => props.theme.colors.white70};
  }
  transition: all 0.3s ease-in-out;
`;
const Trigger = styled.button`
  padding: 7px;
  cursor: pointer;
  &:hover {
    ${(props) => props.theme.colors.primary200_20}
    border-radius: 5px;
  }
`;

const TriggerIcon = styled.i`
  font-size: 18px;
  color: ${(props) => props.theme.colors.white40};
  display: flex;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
`;
const ContactWrapper = styled.div``;
export default ContactListItem;
