import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { Api, T } from '../..';
import { QueryKey } from '.';

type FetchParams = {
  companyGroupId: string;
  azureGroupIds: string[];
  isSysAdmin: boolean;
};

const useCompanyUsersByGroups = (
  fetchParams: FetchParams,
  options?: UseQueryOptions<T.AzureUser[], AxiosError>
): UseQueryResult<T.AzureUser[], AxiosError> =>
  useQuery<T.AzureUser[], AxiosError>(
    [QueryKey.CompanyUsersByGroups, JSON.stringify(fetchParams)],
    () => Api.admin.getCompanyUsersByGroups(fetchParams),
    options
  );

export default useCompanyUsersByGroups;
