import { InferType, object, string, array } from 'yup';

const UpdateContactSchema = object({
  displayName: string(),
  phone: string(),
  email: string(),
  company: string(),
  department: string(),
  title: string(),
  firstName: string(),
  middleName: string(),
  lastName: string(),
  birthday: string().nullable(),
  gender: string().nullable(),
  nationality: string().nullable(),
  address: string(),
  city: string(),
  state: string(),
  registrationNumber: string(),
  country: string().nullable(),
  postcode: string(),
  mobile: string(),
  languages: array().of(string()),
  socialMedia: array().of(string()),
  representation: string(),
});

export type UpdateContact = InferType<typeof UpdateContactSchema>;
export type CreateContact = UpdateContact;

export { UpdateContactSchema as CreateContactSchema };
export default UpdateContactSchema;
