/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { canSplit, liftTarget } from 'prosemirror-transform';
import { TextSelection, Command } from 'prosemirror-state';
import { findParentNodeOfType } from '../util';

export const liftEmptyBlock: Command = (state, dispatch) => {
  const { $cursor } = state.selection as TextSelection;
  if (!$cursor || $cursor.parent.content.size) {
    return false;
  }
  if ($cursor.depth > 1 && $cursor.after() != $cursor.end(-1)) {
    const before = $cursor.before();
    if (canSplit(state.doc, before)) {
      if (dispatch) {
        dispatch(state.tr.split(before));
      }
      return true;
    }
  }
  const range = $cursor.blockRange(),
    target = range && liftTarget(range);
  if (target == null) {
    return false;
  }
  if (dispatch) {
    dispatch(state.tr.lift(range!, target));
  }
  return true;
};

export const deleteClaim: Command = (state, dispatch) => {
  const { schema, tr } = state;
  const claim = findParentNodeOfType(schema.nodes.claim)(state.selection);

  if (!claim) {
    return false;
  }

  if (dispatch) {
    tr.delete(claim.pos, claim.pos + claim.node.nodeSize);
    dispatch(tr.scrollIntoView());
  }
  return true;
};
