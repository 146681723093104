import { css } from 'styled-components';

export default css`
  [data-claim-part='PreambleDelimiter'] {
    font-weight: bold;
  }

  [data-claim-part='PreambleDelimiter'] [data-claim-part] {
    font-weight: normal;
  }

  [data-claim-part='Dependencies'] {
    border-bottom: 1px solid #444;
    display: inline-block;
  }

  .claim-part {
    display: flex;
    gap: 5px;

    & > div:first-child {
      grid-column: 1;
    }

    & > div.content {
      grid-column: 2;
    }

    &.preamble-delimiter > .content > p {
      font-weight: bold;
    }
    & > .content > div.list-numbering {
      margin-right: 8px;
      white-space: nowrap;
    }
    & > .content > div.list-numbering,
    .content > span + * {
      grid-column: span 1;
    }

    &[data-hide-prefix='true'] {
      & > div.content {
        grid-column: span 2;
        display: grid;
      }

      // We add a minimum padding to the left to make it look better
      & > .content > .claim-part {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-auto-columns: min-content;
        margin-left: 33px;
      }
    }
    &.claim-part[data-numbering-type='custom'] {
      & > .content > p ~ * {
        margin-left: 0;
      }
    }
  }
  .claim-part[data-is-deleted='true'] {
    & > .content {
      > p {
        display: none;
      }
    }
  }
`;
