import { EditorState, Transaction } from 'prosemirror-state';
import { Node } from 'prosemirror-model';
import UICommand from '../../toolbar/commands/UICommand';
import { ListType } from '../../styles/bulletListStyles';
import { findNodesAtMaxDepth } from '../../util';
import { LIST_NUMBERING } from '../../schema/nodes/nodeNames';

class ListToggleCommand extends UICommand {
  _type: ListType;

  constructor(type: ListType) {
    super();
    this._type = type;
  }

  execute = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean => {
    const { schema, tr, selection } = state;
    const { from, to } = selection;
    let modified = false;

    const deepestClaimParts = findNodesAtMaxDepth(
      state,
      from,
      to,
      schema.nodes.claimPart.name
    );

    deepestClaimParts.forEach(({ node, pos }) => {
      const mappedPos = tr.mapping.map(pos);

      // Remove list-numbering node if current numberingType is 'custom'
      if (node.attrs.numberingType === 'custom') {
        const contentWithoutListNumbering = [] as Node[];
        node.content.forEach((child) => {
          if (child.type !== schema.nodes[LIST_NUMBERING]) {
            contentWithoutListNumbering.push(child);
          }
        });

        const newContent = schema.nodes.claimPart.create(
          { ...node.attrs },
          contentWithoutListNumbering
        );
        tr.replaceWith(mappedPos, mappedPos + node.nodeSize, newContent);
      }

      tr.setNodeMarkup(mappedPos, null, {
        ...node.attrs,
        indentLevel: node.attrs.indentLevel ?? 0,
        numberingType: this._type,
        numberingPrefix: null,
        numberingTemplate: null,
        startNewParagraph: true,
      });

      modified = true;
    });

    if (modified && dispatch) {
      dispatch(tr);
    }

    return modified;
  };
}

export default ListToggleCommand;
