import { Command, TextSelection } from 'prosemirror-state';
import { Fragment, Node, Slice } from 'prosemirror-model';
import { NodeJSONRaw } from '../ClaimEditorProvider/transformer/types';
import { DocTransformer } from '../ClaimEditorProvider/transformer';

export const claimPartDedent: Command = (state, dispatch) => {
  const { from, to, $from } = state.selection;
  const claimPartType = state.schema.nodes.claimPart;
  const tr = state.tr;

  let modified = false;

  state.doc.nodesBetween(from, to, (node, pos) => {
    if (node.type === claimPartType) {
      const currentIndentLevel = node.attrs.indentLevel ?? 0;
      const newIndentLevel = Math.max(currentIndentLevel - 1, 0);
      tr.setNodeMarkup(pos, null, {
        ...node.attrs,
        indentLevel: newIndentLevel,
      });

      modified = true;
    }
  });

  if (modified && dispatch) {
    const updatedDoc = tr.doc;

    const docJSON = updatedDoc.toJSON() as NodeJSONRaw;
    const flattenContent = DocTransformer.fromJSON(docJSON).toJSON(docJSON);

    // Rebuild the document with the updated indent levels
    const transformer = DocTransformer.fromJSON(flattenContent);
    const result = transformer.build();

    const newDocumentNode = Node.fromJSON(state.schema, result);

    const slice = new Slice(Fragment.from(newDocumentNode), 0, 0);
    tr.replace(0, updatedDoc.content.size, slice);

    // Adjust the selection to the previous position
    const newDoc = tr.doc;
    const updatedPos = newDoc.resolve($from.pos - 1);
    tr.setSelection(TextSelection.near(updatedPos));
    dispatch(tr.scrollIntoView());
  }

  return modified;
};
