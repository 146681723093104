import { NodeSpec } from 'prosemirror-model';
import { iposApi } from '../../../../api/base';
import { MM_TO_PX_CONVERSION_FACTOR } from '../../images/imageView/defaults';

const CSS_ROTATE_PATTERN = /rotate\(([0-9\.]+)rad\)/i;
const EMPTY_CSS_VALUE = new Set(['0%', '0pt', '0px']);

function getAttrs(dom: HTMLElement | string) {
  if (typeof dom === 'string') {
    return {};
  }
  const { cssFloat, display, marginTop, marginLeft } = dom.style;
  let { width, height } = dom.style;
  let align = dom.getAttribute('data-align') || dom.getAttribute('align');
  if (align) {
    align = /(left|right|center)/.test(align) ? align : null;
  } else if (cssFloat === 'left' && !display) {
    align = 'left';
  } else if (cssFloat === 'right' && !display) {
    align = 'right';
  } else if (!cssFloat && display === 'block') {
    align = 'block';
  }

  width = width || dom.getAttribute('width') || `0px`;
  height = height || dom.getAttribute('height') || `0px`;

  let crop = null;
  let rotate = null;
  const { parentElement } = dom;
  if (parentElement instanceof HTMLElement) {
    // Special case for Google doc's image.
    const ps = parentElement.style;
    if (
      ps.display === 'inline-block' &&
      ps.overflow === 'hidden' &&
      ps.width &&
      ps.height &&
      marginLeft &&
      !EMPTY_CSS_VALUE.has(marginLeft) &&
      marginTop &&
      !EMPTY_CSS_VALUE.has(marginTop)
    ) {
      crop = {
        width: parseInt(ps.width, 10) || 0,
        height: parseInt(ps.height, 10) || 0,
        left: parseInt(marginLeft, 10) || 0,
        top: parseInt(marginTop, 10) || 0,
      };
    }
    if (ps.transform) {
      // example: `rotate(1.57rad) translateZ(0px)`;
      const mm = ps.transform.match(CSS_ROTATE_PATTERN);
      if (mm && mm[1]) {
        rotate = parseFloat(mm[1]) || null;
      }
    }
  }

  return {
    align,
    alt: dom.getAttribute('alt') || null,
    crop,
    height: parseInt(height, 10) || null,
    rotate,
    src: dom.getAttribute('src') || null,
    title: dom.getAttribute('title') || null,
    width: parseInt(width, 10) || null,
    objectSrc: dom.getAttribute('objectsrc') || null,
    aspectRatio: dom.getAttribute('aspectratio') || null,
    imgSrc: dom.getAttribute('imgsrc') || null,
  };
}

const image: NodeSpec = {
  inline: true,
  attrs: {
    align: { default: null },
    alt: { default: '' },
    crop: { default: null },
    height: { default: null },
    rotate: { default: null },
    src: { default: null },
    title: { default: '' },
    width: { default: null },
    relWidth: { default: null },
    aspectRatio: { default: null },
    isDeleted: { default: false },
    isInserted: { default: false },
    objectSrc: { default: null },
    imgSrc: { default: null },
  },
  group: 'inline',
  draggable: true,
  parseDOM: [{ tag: 'img[src]', getAttrs }],
  toDOM(node) {
    const { src, height: heightMm, width: widthMm, aspectRatio } = node.attrs;

    let amendedSrc = '';
    let imgSrc = '';
    if (src) {
      if (src.startsWith('data:')) {
        amendedSrc = src;
      } else {
        amendedSrc = `${iposApi}/v1/${src}`;
        imgSrc = src;
      }
    }

    let height = heightMm * MM_TO_PX_CONVERSION_FACTOR;
    let width = widthMm * MM_TO_PX_CONVERSION_FACTOR;

    if (width && !height) {
      height = width / aspectRatio;
    } else if (height && !width) {
      width = height * aspectRatio;
    }
    return [
      'img',
      {
        ...node.attrs,
        src: amendedSrc,
        height,
        width,
        isDeleted: node.attrs.isDeleted || false,
        isInserted: node.attrs.isInserted || false,
        imgSrc,
      },
    ];
  },
};

export default image;
