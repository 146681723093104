import type { NodeJSONRaw } from '../types';
import type { DocTransformer } from '../index';
import { flattenClaimPart } from './flatten';

export const normalizeOutputJSON = (
  input: unknown,
  instance: DocTransformer
): NodeJSONRaw => {
  let node = input as NodeJSONRaw;
  // apply more transformations here
  node = flattenClaimPart(node, instance);
  return node;
};
