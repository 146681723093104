import React, { useState, useMemo } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { AutoComplete, Popover } from 'antd';
import { ToolbarIcon } from '../Utils';
import useTranslation from '../../../translations';
import { textBlocksList } from '../../../constants';
import Button from './Button';

const { Option } = AutoComplete;

const sortedTextBlocksList = textBlocksList.toSorted((a, b) =>
  a.localeCompare(b)
);
const options = sortedTextBlocksList.map((tb: string) => ({
  value: tb,
}));

interface OptionType {
  children?: React.ReactNode;
}

const TextBlocksPicker: React.FC<{
  editorState: EditorState;
  onChange: (newState: EditorState) => void;
}> = ({ editorState, onChange }) => {
  const t = useTranslation();
  const [inputValue, setInputValue] = useState('');

  const onSelect = (textBlock: string) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const blockKey = selectionState.getStartKey();
    const blockText = contentState.getBlockForKey(blockKey).getText();

    const startOffset = selectionState.getStartOffset();
    const hasSpaceBefore =
      startOffset > 0 && blockText[startOffset - 1] === ' ';

    const endOffset = selectionState.getEndOffset();

    const hasSpaceAfter =
      endOffset < blockText.length && blockText[endOffset] === ' ';

    let modifiedContentState = contentState;
    let newSelection = selectionState;

    if (!hasSpaceBefore) {
      modifiedContentState = Modifier.insertText(
        modifiedContentState,
        selectionState,
        ' '
      );

      newSelection = modifiedContentState.getSelectionAfter();
    }

    const contentStateWithEntity = modifiedContentState.createEntity(
      'textBlock',
      'IMMUTABLE',
      { textBlock }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    modifiedContentState = Modifier.insertText(
      contentStateWithEntity,
      newSelection,
      `[${textBlock}]`,
      undefined,
      entityKey
    );

    if (!hasSpaceAfter) {
      modifiedContentState = Modifier.insertText(
        modifiedContentState,
        modifiedContentState.getSelectionAfter(),
        ' '
      );
    }

    const newEditorState = EditorState.push(
      editorState,
      modifiedContentState,
      'insert-fragment'
    );

    onChange(
      EditorState.forceSelection(
        newEditorState,
        modifiedContentState.getSelectionAfter()
      )
    );
    setInputValue('');
  };

  const textBlocks = useMemo(
    () =>
      options.map(({ value }, index) => (
        <Option
          key={value}
          value={value}
          data-testid={`dropdown-text-block-entry-${index}`}
        >
          {value}
        </Option>
      )),
    [options]
  );

  return (
    <Popover
      trigger="click"
      placement="topLeft"
      title={
        <AutoComplete
          value={inputValue}
          onChange={setInputValue}
          onFocus={() => setInputValue('')}
          dropdownRender={(dropdown) => (
            <div data-testid="add-text-block-dropdown">{dropdown}</div>
          )}
          style={{ width: 300 }}
          onSelect={onSelect}
          filterOption={(inputValue: string, option?: OptionType) => {
            const children = option?.children;
            if (typeof children === 'string') {
              return children.toLowerCase().includes(inputValue.toLowerCase());
            }
            return false;
          }}
          placeholder={t('RICH_TEXT.CONTROLS.SEARCH_FOR_TEXT_TEMPLATES')}
        >
          {textBlocks}
        </AutoComplete>
      }
    >
      <Button
        data-testid="add-text-block"
        label="RICH_TEXT.CONTROLS.TEXT_TEMPLATES"
        icon={<ToolbarIcon className="icn-placeholder" />}
      />
    </Popover>
  );
};

export default TextBlocksPicker;
