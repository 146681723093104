import { NodeJSONRaw, Store } from './types';
import { normalizeInputJSON } from './input';
import { normalizeOutputJSON } from './output';

export class DocTransformer {
  store: Store = new Map();

  constructor(public raw: NodeJSONRaw) {}

  static fromJSON(raw: unknown) {
    return new DocTransformer(raw as NodeJSONRaw);
  }

  build() {
    return normalizeInputJSON(this.raw, this);
  }

  toJSON(doc: unknown) {
    return normalizeOutputJSON(doc, this);
  }
}
