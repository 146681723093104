import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../translations';

interface Props {
  title: string;
  src: string;
  onClose: () => void;
}

const VideoPlayer: React.FC<Props> = ({ title, src, onClose }) => {
  const t = useTranslation();

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <StyledIcon className="icn-clear" onClick={onClose} />
      </Header>
      <Video controls autoPlay controlsList="nodownload">
        <source src={src} type="video/mp4" />
        {t('TUTORIALS.NOT_SUPPORTED')}
      </Video>
    </Container>
  );
};

export default VideoPlayer;

const Container = styled.div`
  background: ${(props) => props.theme.colors.white87};
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  height: 90%;
  max-height: 1200px;
  display: flex;
  flex-direction: column;
`;

const Video = styled.video`
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: calc(100% - 55px);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const Title = styled.div`
  font-size: 20px;
  color: ${(props) => props.theme.colors.black87};
`;

const StyledIcon = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  width: 38px;
  height: 38px;
  border-radius: 6px;
  transition: background 0.3s ease;
  color: ${(props) => props.theme.colors.black87};
  &:hover {
    background: ${(props) => props.theme.colors.black10};
  }
`;
