import { useCallback } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import useTranslation from '../../translations';

dayjs.extend(utc);
dayjs.extend(tz);

const DATE_FORMAT = 'DD.MM.YYYY';
const TIME_FORMAT = 'HH:mm';
const SUPPORT_EMAIL = 'support@philipa.tech';

export const useCreateBugReportEmail = () => {
  const t = useTranslation();
  const createBugReportEmail = useCallback(
    (email: string | null | undefined, ccRecipients: string[]) => {
      try {
        if (!email) {
          console.warn(
            'User email is not available, aborting bug report email generation.'
          );
          return;
        }

        const currentURL = window.location.href;
        const parsedUrl = new URL(currentURL);
        const day = dayjs().utc().tz('Europe/Berlin');
        const reportDate = day.format(DATE_FORMAT);
        const reportTime = day.format(TIME_FORMAT);
        const subject = encodeURIComponent(
          t('BUG_REPORT.SUBJECT', { host: parsedUrl.host })
        );
        const cc = ccRecipients.length ? `&cc=${ccRecipients.join(';')}` : '';

        const bodyTemplate = `
               ${t('BUG_REPORT.BODY_TITLE')}

                ${t('BUG_REPORT.BODY_DATE')}: ${reportDate}
                ${t('BUG_REPORT.BODY_TIME')}: ${reportTime}

                ${t('BUG_REPORT.BODY_USER')}: ${email}

                ${t('BUG_REPORT.BODY_SCREEN')}: ${currentURL}


                ${t('BUG_REPORT.BODY_DESCRIPTION')}:


                ${t('BUG_REPORT.BODY_STEPS')}:


                ${t('BUG_REPORT.BODY_ACTUAL_RESULT')}:


                ${t('BUG_REPORT.BODY_EXPECTED_RESULT')}:


                ${t('BUG_REPORT.BODY_SCREENSHOT_VIDEO')}:


        `;

        const encodedBody = encodeURIComponent(bodyTemplate);

        window.location.href = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${encodedBody}${cc}`;
      } catch (error) {
        console.error('Error generating bug report email:', error);
      }
    },
    []
  );

  return createBugReportEmail;
};
