import React, { useEffect } from 'react';
import { message } from 'antd';
import styled from 'styled-components';
import { T } from '../../..';
import { useTutorialVideoUrl } from '../../queries';
import useTranslation from '../../translations';
import { Spinner } from '../Spinner';
import VideoPlayer from './VideoPlayer';

const REFRESH_URI_TOKEN_INTERVAL = 3600000;

interface Props {
  tutorial: T.Tutorial;
  onClose: () => void;
}

const VideoPlayerContainer: React.FC<Props> = ({ tutorial, onClose }) => {
  const t = useTranslation();

  const query = useTutorialVideoUrl(tutorial.id, {
    onError() {
      message.error(t('TUTORIALS.LOAD_VIDEO_ERRROR'));
      onClose();
    },
    cacheTime: 0,
  });

  const { data: src, refetch } = query;

  // Refresh the token URI on interval
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (src) {
      timeout = setTimeout(() => {
        refetch();
      }, REFRESH_URI_TOKEN_INTERVAL);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [src, refetch]);

  let content;

  switch (true) {
    case query.isLoading:
      content = <Spinner size="large" tip={t('TUTORIALS.LOADING_VIDEO')} />;
      break;
    case query.data != null:
      content = (
        <VideoPlayer
          key={src}
          title={tutorial.title}
          src={query.data}
          onClose={onClose}
        />
      );
  }

  return <Backdrop>{content}</Backdrop>;
};

export default VideoPlayerContainer;

const Backdrop = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10000;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: ${(props) => props.theme.colors.black60};
`;
