import styled, { css } from 'styled-components';
import { EditorContentType, PatentOffice } from '../../../types';
import { Colors } from '../../../theme';
import bulletListStyles from './bulletListStyles';
import numberedParagraphStyles from './numberedParagraphStyles';
import listStyles from './listStyles';
import claimPartStyles from './claimPartStyles';
import claimStyles from './claimStyles';
import commentStyles from './commentStyles';
import claimAdvantageStyles from './claimAdvantageStyles';
import claimReferenceStyles from './claimReferenceStyles';

export const StyledContentContainer = styled('div')`
  padding: 4rem 0;
  max-width: 73rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 2em;
  grid-template-areas: 'editor editor-diff';
  flex: 1;
`;

export const StyledEditorWrapperCommon = styled('div')`
  max-height: 65vh;
  display: flex;
  min-height: 0;
  border: 1px solid silver;
`;

export const StyledEditorWrapper = styled(StyledEditorWrapperCommon)`
  grid-area: editor;
  display: flex;
  min-height: 0;
  background-color: ${Colors.primary800};
  color: ${Colors.white60};
`;

export const StyledEditorDiffWrapper = styled(StyledEditorWrapperCommon)`
  grid-area: editor-diff;
  background-color: ${Colors.primary800};
  color: ${Colors.white60};
`;

export const StyledEditorContainer = styled.div<{
  type: 'diff' | 'clean';
  contentType: EditorContentType;
  patentOffice?: PatentOffice;
}>`
  display: flex;
  min-height: 0;
  min-width: 0;
  height: 100%;

  > div {
    display: flex;
    min-height: 0;
    min-width: 0;
    width: 100%;
    z-index: 2;
  }

  & {
    .ProseMirror {
      counter-reset: numbered-paragraph;
      ${bulletListStyles};
      ${numberedParagraphStyles};
      ${listStyles};
      ${claimPartStyles};
      ${claimStyles};
      ${commentStyles};
      ${claimAdvantageStyles};
      ${claimReferenceStyles};
      overflow-y: auto;
      min-width: 0;
      z-index: 0;
      padding: 30px;
      outline: none;
      width: 100%;
      min-height: 200px;
      background: ${Colors.white100};
      color: ${Colors.black};
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color:${Colors.black};
        margin-bottom: 1em;
        line-height: 1em;
        font-size: 14px;
      }
     
      h1 {
        text-align: center; // by default exported docx file has h1 aligned center
      }
      p {
        margin-bottom: 0.5em;
      }

      .old-claim-number {
        display: none;
      }

      .deleted {
        display: none;
      }

      [isdeleted='true'] {
        display: none;
      }
    }

    ${(props) =>
      props.contentType == EditorContentType.Claims
        ? css`
            .ProseMirror {
              p {
                margin-bottom: 0;
              }
            }
          `
        : css``}

    ${(props) =>
      props.patentOffice === PatentOffice.USPTO
        ? css`
            .ProseMirror {
              .claim.deleted {
                display: flex;
              }
            }
          `
        : css``}
    }

    ${(props) =>
      props.type === 'diff'
        ? css`
            .ProseMirror {
              .deleted {
                color: ${Colors.red500};
                text-decoration: line-through;
              }

              .claim.deleted {
                color: inherit;
                text-decoration: none;
              }

              img[isDeleted='true'] {
                border: 1px solid ${Colors.red500};
              }

              .inserted.claim-number,
              span.inserted,
              .inserted > * > *:not(.claim-part) {
                color: ${Colors.secondary300};
                text-decoration: underline;
              }

              ${props.patentOffice === PatentOffice.USPTO &&
              css`
                .claim.inserted *:not(i) {
                  color: initial !important;
                  text-decoration: none !important;
                }
              `}

              .deleted {
                .claim-number,
                .content {
                  color: ${Colors.red500};
                  text-decoration: line-through;
                }

                display: initial;
              }

              table.deleted {
                display: inline-table !important;
              }

              [data-is-deleted='true'] {
                display: flex;
              }

              .claim-part[data-is-deleted='true']
                > .content
                > *:not(.claim-part) {
                display: initial;
              }

              [isdeleted='true'] {
                display: initial;
              }

              .old-claim-number {
                display: block;
              }

              .image-view-body.pasted {
                border: 2px solid ${Colors.secondary300};
              }

              .image-view.track-changes-insert {
                .image-view-body.pasted {
                  border: none;
                }
              }

              .track-changes-delete {
                position: relative;
                color: ${Colors.red500} !important;
                text-decoration: line-through !important;

                &:has(> img) {
                  display: flex;
                }

                &.image-view,
                img {
                  border-radius: 8px;
                  position: relative;
                  pointer-events: none;
                  border: 2px solid ${Colors.red500};
                  overflow: hidden;
                }

                // Leave this overlay here for future, for now we will just hide images
                picture::after {
                  content: '';
                  position: absolute;
                  display: block;
                  top: 0;
                  opacity: 0.7;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: ${Colors.red500};
                }
              }

              .track-changes-delete + .ProseMirror-separator {
                display: none !important;
              }

              .track-changes-delete
                + .ProseMirror-separator
                + .ProseMirror-trailingBreak {
                display: none !important;
              }

              .track-changes-insert {
                color: ${Colors.secondary300};
                &.image-view {
                  border-radius: 8px;
                  border: 2px solid ${Colors.secondary300};
                }
              }
            }
          `
        : css`
            .ProseMirror {
              .track-changes-delete {
                display: none;
              }

              [data-node-type='claim']::after {
                display: none;
              }
              .claim-part.deleted {
                display: none !important;
              }
            }
          `}
    .ProseMirror .tableWrapper {
      overflow-x: auto;
      margin: 1em 0;
    }

    .ProseMirror table {
      border-collapse: collapse;
      table-layout: auto;
      width: 100%;
      overflow: hidden;
      margin: 0;
    }

    .ProseMirror td,
    .ProseMirror th {
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
      min-width: 1em;
      border: 1px solid #ddd;
      padding: 3px 5px;
      overflow-wrap: break-word;
      max-width: 300px;
    }

    .ProseMirror th {
      font-weight: bold;
      text-align: left;
    }

    /* Give selected cells a blue overlay */

    .ProseMirror .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .ProseMirror .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: 0;
      width: 4px;
      z-index: 20;
      background-color: #adf;
      pointer-events: none;
    }

    .ProseMirror.resize-cursor {
      cursor: col-resize;
    }

    .ProseMirror-gapcursor {
      display: none;
      pointer-events: none;
      position: absolute;
    }

    .ProseMirror-gapcursor:after {
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      width: 20px;
      border-top: 1px solid ${Colors.black};
      animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
    }

    @keyframes ProseMirror-cursor-blink {
      to {
        visibility: hidden;
      }
    }

    .ProseMirror-focused .ProseMirror-gapcursor {
      display: block;
    }
    .ProseMirror-search-match {
      background-color: #ffff0054;
    }
    .ProseMirror-active-search-match {
      background-color: #ff6a0054;
    }
    p::selection,
    p > *::selection {
      background: rgba(203, 220, 255, 1);
      color: #434854;
    }
  }
`;
