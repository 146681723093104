import { EditorState, Transaction } from 'prosemirror-state';
import UICommand from '../../toolbar/commands/UICommand';
import { CLAIM_PART, LIST_NUMBERING } from '../../schema/nodes/nodeNames';
import { findNodesAtMaxDepth } from '../../util';

class CustomListToggleCommand extends UICommand {
  constructor() {
    super();
  }

  execute = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean => {
    const { schema, tr, selection } = state;
    const { from, to } = selection;
    let modified = false;

    const deepestClaimParts = findNodesAtMaxDepth(state, from, to, CLAIM_PART);

    deepestClaimParts.forEach(({ node, pos }) => {
      const mappedPos = tr.mapping.map(pos);

      tr.setNodeMarkup(mappedPos, null, {
        ...node.attrs,
        numberingType: 'custom',
        numberingPrefix: 'R<sup>1</sup>',
        numberingTemplate: 'R<sup>1</sup>',
        startNewParagraph: true,
      });

      const mainTextNode = schema.text('R');
      const superscriptMark = schema.marks.superscript.create();
      const superscriptTextNode = schema.text('1', [superscriptMark]);

      const listNumberingNode = schema.nodes[LIST_NUMBERING].create(null, [
        mainTextNode,
        superscriptTextNode,
      ]);

      tr.insert(mappedPos + 1, listNumberingNode);

      const nextClaimPartPos = tr.mapping.map(
        mappedPos + listNumberingNode.nodeSize
      );
      const nextClaimPart = tr.doc.nodeAt(nextClaimPartPos);

      if (nextClaimPart && nextClaimPart.type === schema.nodes.claimPart) {
        tr.setNodeMarkup(nextClaimPartPos, null, {
          ...nextClaimPart.attrs,
          startNewParagraph: true,
        });
      }

      modified = true;
    });

    if (!modified) {
      return false;
    }

    if (dispatch) {
      dispatch(tr);
    }

    return true;
  };
}

export default CustomListToggleCommand;
