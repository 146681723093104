import { T } from '../..';
import { DEFAULT_PAGE_SIZE } from '../constants';
import { randomDate, randomEnum, randomString } from '../utils';
import {
  generateClaim,
  generateOaDocumentMetadata,
  generateOfficeActionListItem,
  generatePatentRevision,
  generateParsedOfficeAction,
  generateUserProcess,
  generateOfficeAction,
  generatePatentRevisions,
  generateStakeholder,
  generateUserProcessStage,
  generatePatentHistoryItem,
  generateTextBlock,
  range,
  generateResponseLetter,
  generateOfficeActionType,
  generatePatentApplication,
  generateTranslation,
  generateAnnotationSets,
  generateDocumentBlob,
  generateOaListFilters,
  generateCitedPriorArt,
  generatePatentIdentifier,
} from './mockDataGenerators';

export const mockStakeholders: T.PatentApplicationStakeholder[] = [
  ...Array.from(Array(20)).map(() =>
    generateStakeholder(randomEnum(T.PatentApplicationStakeholderRole))
  ),
];

const treeRoot = generatePatentHistoryItem({
  previousRevisionId: undefined,
  isFiled: true, // make sure that we have at lease one filed/non-editable revision item
});
const firstChild = generatePatentHistoryItem({
  previousRevisionId: treeRoot.id,
  isFiled: false, // make sure that we have at lease one editable revision item
});
const secondChild = generatePatentHistoryItem({
  previousRevisionId: treeRoot.id,
});
const thirdChild = generatePatentHistoryItem({
  previousRevisionId: treeRoot.id,
});
const firstGrandchild = generatePatentHistoryItem({
  previousRevisionId: firstChild.id,
});
const secondGrandchild = generatePatentHistoryItem({
  previousRevisionId: firstChild.id,
});

export const mockPatentHistoryTree: T.PatentRevisionHistoryItem[] = [
  treeRoot,
  firstChild,
  secondChild,
  thirdChild,
  firstGrandchild,
  secondGrandchild,
];

export const mockUserProcess: T.OfficeActionImportProcess =
  generateUserProcess();

export const mockDocumentBlob = generateDocumentBlob();
export const mockBase64Response = randomString(100);
export const mockOfficeActionListFilters = generateOaListFilters();
export const mockPatentApplication: T.PatentApplication =
  generatePatentApplication();
export const mockSuccessfulUserProcess: T.OfficeActionImportProcess =
  generateUserProcess({
    stages: [
      generateUserProcessStage({
        state: T.ProcessStageState.CompletedSuccessfully,
        executionOrder: 0,
      }),
      generateUserProcessStage({
        state: T.ProcessStageState.CompletedSuccessfully,
        executionOrder: 1,
      }),
      generateUserProcessStage({
        state: T.ProcessStageState.CompletedSuccessfully,
        executionOrder: 2,
      }),
    ],
  });

export const mockParsedOfficeAction: T.OfficeActionParserOutput =
  generateParsedOfficeAction();

export const mockOfficeActionTypes: T.OfficeActionType[] = Array.from(
  Array(20)
).map(() => generateOfficeActionType());

export const mockOfficeActionReviewResults: T.OfficeActionUploadReview = {
  officeActionParserOutput: mockParsedOfficeAction,
  officeActionDocument: generateOaDocumentMetadata(),
  earlierOfficeActionDocuments: [],
  officeActionSummary: null,
  patentRevisions: [],
  patentApplicationSummary: {
    applicationTitle: randomString(),
    applicationDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    publicationDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    priorityDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    filingType: randomEnum(T.FilingType),
    patentFamilyId: randomString(),
    mostImportantAspect: randomString(),
    productReference: randomString(),
    projectReference: randomString(),
    engineeringDomain: randomEnum(T.EngineeringDomain),
    expiryDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    applicationIdentifier: generatePatentIdentifier(),
    publicationIdentifier: generatePatentIdentifier(),
    lawFirmReference: randomString(),
  },
  patentRevisionImportProcessInProgressId: null,
};

export const mockOfficeActionCitedPriorArtInfo: T.OfficeActionCitedPriorArtInfo =
  {
    citedPriorArt: Array.from({ length: 10 }, () => generateCitedPriorArt()),
    claimCitationMatrix: [],
    claimFeatureMatrix: [],
  };

export const mockOaDocumentMetadata: T.DocumentMetadata =
  generateOaDocumentMetadata();

export const mockClaims = [
  ...Array.from(Array(20)).map((_, index) =>
    generateClaim({ claimNumber: index + 1 })
  ),
];

export const firstPageOfficeActionList: T.OfficeActionListItem[] = [
  ...Array.from(Array(DEFAULT_PAGE_SIZE)).map(() =>
    generateOfficeActionListItem()
  ),
];

export const secondPageOfficeActionList: T.OfficeActionListItem[] = [
  ...Array.from(Array(DEFAULT_PAGE_SIZE)).map(() =>
    generateOfficeActionListItem()
  ),
];
export const mockAnnotationSets = generateAnnotationSets();
export const mockPatentRevision = generatePatentRevision();

export const mockOfficeAction = generateOfficeAction();
export const mockTranslations = generateTranslation();
export const mockResponseLetter = generateResponseLetter();

export const mockPatentRevisions = generatePatentRevisions();

export const mockTextBlock = generateTextBlock();

export const mockTextBlocks = range(100).map(() => generateTextBlock());
