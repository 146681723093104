import React, { useState } from 'react';
import styled from 'styled-components';
import Notification from '../Notification';
import { useTutorials } from '../../queries';
import useTranslation from '../../translations';
import { T } from '../../..';
import TutorialsCard from './TutorialsCard';
import VideoPlayerContainer from './VideoPlayerContainer';

const Tutorials: React.FC = () => {
  const t = useTranslation();
  const tutorialsQuery = useTutorials();

  const [tutorial, setTutorial] = useState<T.Tutorial | null>(null);

  if (tutorialsQuery.isLoading || tutorialsQuery.isIdle) {
    return <StyledNotification spin spinSize="large" />;
  }

  if (tutorialsQuery.isError) {
    return <StyledNotification text={t('TUTORIALS.LOAD_ERROR')} />;
  }

  if (tutorialsQuery.data.length === 0) {
    return <StyledNotification text={t('TUTORIALS.NO_TUTORIALS')} />;
  }

  return (
    <>
      <Outer>
        <Title>{t('TUTORIALS.INTRO')}</Title>
        <Inner>
          {tutorialsQuery.data.map((tutorial, index) => (
            <TutorialsCard key={index} data={tutorial} onClick={setTutorial} />
          ))}
        </Inner>
      </Outer>
      {tutorial && (
        <VideoPlayerContainer
          tutorial={tutorial}
          onClose={() => setTutorial(null)}
        />
      )}
    </>
  );
};

export default Tutorials;

const Outer = styled.div`
  min-height: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.colors.white70};
`;

const Inner = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  gap: 8px;
  align-content: flex-start;
  padding-bottom: 20px;
`;

const StyledNotification = styled(Notification)`
  height: 600px;
`;
