import qs from 'querystring';
import { T } from '../..';
import {
  DocumentSearchResult,
  SearchDocuments,
} from '../components/MultiDocViewer/types';
import Api, { handleBlobResponse } from './base';

const API_BASE = '/v1/documents';

export const getTranslations = (
  documentId: string
): Promise<T.DocTranslations> =>
  Api.get<T.DocTranslations>(`${API_BASE}/${documentId}/translations`).then(
    (response) => response.data
  );

export const getAsBlobById = (documentId: string): Promise<[Blob, string]> =>
  Api.get<Blob>(`${API_BASE}/${documentId}?stream=true`, {
    responseType: 'blob',
  }).then(handleBlobResponse);

export const startTranslation = (
  documentId: string,
  targetLanguage: T.Language
): Promise<T.DocumentMetadata> =>
  Api.post<T.DocumentMetadata>(
    `${API_BASE}/${documentId}/translations/${targetLanguage}`
  ).then((response) => response.data);

export const startTranslationByPage = (
  documentId: string,
  targetLanguage: T.Language,
  pageNumbers?: string
): Promise<T.TranslationProcess> => {
  const queryParams = pageNumbers ? `?page-numbers=${pageNumbers}` : '';
  return Api.post<T.TranslationProcess>(
    `${API_BASE}/${documentId}/translations/${targetLanguage}/start${queryParams}`
  ).then((response) => response.data);
};

export const getTranslationProcess = (
  documentId: string,
  targetLanguage: T.Language
): Promise<T.TranslationProcess> =>
  Api.get<T.TranslationProcess>(
    `${API_BASE}/${documentId}/translations/${targetLanguage}/process`
  ).then((response) => response.data);

export const getMetadata = (
  documentId: string,
  annotationSets?: Record<string, boolean>
): Promise<T.DocumentMetadata> => {
  if (annotationSets) {
    const queryParams = new URLSearchParams();
    Object.entries(annotationSets).forEach(([key, value]) => {
      if (value && !key.startsWith('placeholder-')) {
        queryParams.append('annotation-set', key);
      }
    });
    return Api.get<T.DocumentMetadata>(
      `${API_BASE}/${documentId}/metadata?${queryParams.toString()}`
    ).then((response) => response.data);
  }
  return Api.get<T.DocumentMetadata>(`${API_BASE}/${documentId}/metadata`).then(
    (response) => response.data
  );
};

export const updateAuthenticLanguage = (
  documentId: string,
  documentName: string,
  contentLanguage: T.Language
): Promise<T.DocumentMetadata> =>
  Api.put<T.DocumentMetadata>(`${API_BASE}/${documentId}/metadata`, {
    name: documentName,
    contentLanguage,
  }).then((response) => response.data);

export const getAsBlobByUri = (uri: string): Promise<Blob> => {
  const parsedUrl = new URL(`http://localhost/v1${uri}`);
  parsedUrl.searchParams.append('stream', 'true');
  return Api.get<Blob>(
    `${parsedUrl.pathname}?${parsedUrl.searchParams.toString()}`,
    {
      responseType: 'blob',
    }
  ).then((response) => response.data);
};

export const getAnnotationSets = (
  documentId: string
): Promise<Record<string, string>> =>
  Api.get<Record<string, string>>(
    `${API_BASE}/${documentId}/metadata/annotation-sets`
  ).then((response) => response.data);

export const getDocumentsViaProxy = (
  rootId: string,
  appIdentifier: T.ApplicationIdentifier
): Promise<T.DocumentListItem[]> =>
  Api.get<T.DocumentListItem[]>(
    `${API_BASE}/proxy?${new URLSearchParams({
      'root-id': rootId,
      'app-identifier': appIdentifier,
    }).toString()}`
  ).then((response) => response.data);

export const getDocumentsByIds = (
  documentIds: string[]
): Promise<T.DocumentMetadata[]> => {
  const params = new URLSearchParams();
  documentIds.forEach((id) => params.append('document-ids', id));
  return Api.get<T.DocumentMetadata[]>(
    `${API_BASE}/documents?${params.toString()}`
  ).then((response) => response.data);
};

export const getAsBase64ByUri = (uri: string): Promise<string> => {
  const parsedUrl = new URL(`http://localhost/v1${uri}`);
  parsedUrl.searchParams.append('stream', 'false');
  return Api.get<string>(
    `${parsedUrl.pathname}?${parsedUrl.searchParams.toString()}`,
    {
      responseType: 'text',
    }
  ).then((response) => response.data);
};

export const getSelectableTexts = (
  documentId: string,
  pages: number[]
): Promise<Record<number, T.SelectableText[]>> =>
  Api.get<Record<number, T.SelectableText[]>>(
    `${API_BASE}/${documentId}/ocr?${qs.stringify({
      'page-numbers': pages,
    })}`
  ).then((response) => response.data);

export const searchDocuments = (
  payload: SearchDocuments
): Promise<DocumentSearchResult[]> =>
  Api.post<DocumentSearchResult[]>(`${API_BASE}/search`, payload).then(
    (response) => response.data
  );

export const startPdfOcrProcess = (
  documentId: string
): Promise<T.DocumentOcrProcess> =>
  Api.post<T.DocumentOcrProcess>(`${API_BASE}/${documentId}/ocr/start`).then(
    (response) => response.data
  );

export const getPdfOcrProcess = (
  documentId: string
): Promise<T.DocumentOcrProcess> =>
  Api.get<T.DocumentOcrProcess>(`${API_BASE}/${documentId}/ocr/status`).then(
    (response) => response.data
  );

export const getDocumentsAsZip = (
  documentIds: string[]
): Promise<[Blob, string]> =>
  Api.post<Blob>(`${API_BASE}/zip`, documentIds, { responseType: 'blob' }).then(
    handleBlobResponse
  );
