import {
  Command,
  EditorState,
  TextSelection,
  Transaction,
} from 'prosemirror-state';
import { Fragment, Node } from 'prosemirror-model';
import {
  findNextNodeOfType,
  findParentNodeOfType,
  findPreviousNodeOfType,
} from '../util';
import { CLAIM_PART } from '../schema/nodes/nodeNames';

export const claimPartBackspace: Command = (state, dispatch) => {
  const { selection, schema, tr } = state;
  const { $from, empty } = selection;
  const { claimPart, claim } = schema.nodes;

  const claimPartOnCursor = findParentNodeOfType(claimPart)(selection);
  const claimOnCursor = findParentNodeOfType(claim)(selection);

  if (!claimPartOnCursor) {
    return false;
  }

  const isAtStartOfClaimPart = $from.parentOffset === 0 && empty;
  if (!isAtStartOfClaimPart) {
    return false;
  }

  if (claimPartOnCursor.node.attrs.numberingType !== 'none') {
    handleNumberingChangeToNone(state, tr, claimPartOnCursor);
  } else {
    const previousClaimPart = findPreviousNodeOfType(
      state.doc,
      claimPartOnCursor.pos,
      CLAIM_PART
    );

    if (previousClaimPart) {
      mergeClaimParts(
        state,
        tr,
        claimPartOnCursor,
        previousClaimPart,
        claimOnCursor
      );
    }
  }

  if (dispatch) {
    dispatch(tr.scrollIntoView());
  }

  return false;
};

export function handleNumberingChangeToNone(
  state: EditorState,
  tr: Transaction,
  claimPartOnCursor: { node: Node; pos: number }
) {
  tr.setNodeMarkup(claimPartOnCursor.pos, null, {
    ...claimPartOnCursor.node.attrs,
    numberingType: 'none',
  });

  const validNodes = filterContent(state, claimPartOnCursor);
  if (validNodes.length > 0) {
    tr.replaceWith(
      claimPartOnCursor.pos + 1,
      claimPartOnCursor.pos + claimPartOnCursor.node.nodeSize - 1,
      Fragment.from(validNodes)
    );
  }

  const resolvedPos = tr.doc.resolve(claimPartOnCursor.pos + 1);
  tr.setSelection(TextSelection.create(tr.doc, resolvedPos.pos));
}

// numbering type none can only have paragraphs or nested claim parts
function filterContent(
  state: EditorState,
  claimPartOnCursor: { node: Node; pos: number }
) {
  const validNodes: Node[] = [];
  claimPartOnCursor.node.forEach((childNode) => {
    if (
      childNode.type === state.schema.nodes.paragraph ||
      childNode.type === state.schema.nodes.claimPart
    ) {
      validNodes.push(childNode);
    }
  });
  return validNodes;
}

// merge the current claim part with the previous claim part
export function mergeClaimParts(
  state: EditorState,
  tr: Transaction,
  claimPartAfterCursor: { node: Node; pos: number },
  claimPartBeforeCursor: { node: Node; pos: number },
  claim?: { node: Node; pos: number } | null
) {
  const prevPos = claimPartBeforeCursor.pos;
  const prevNode = claimPartBeforeCursor.node;

  const prevParagraph = prevNode.lastChild;
  const currentParagraph = claimPartAfterCursor.node.firstChild;

  if (prevParagraph && currentParagraph) {
    const preservedChildren = preserveChildrenWithMergedContent(
      state,
      prevNode,
      currentParagraph
    );

    const updatedClaimPartContent = Fragment.fromArray(preservedChildren);

    tr.replaceWith(
      prevPos,
      prevPos + prevNode.nodeSize,
      state.schema.nodes.claimPart.create(
        prevNode.attrs,
        updatedClaimPartContent
      )
    );

    // Adjust `delete transaction` to work with updated doc
    const newDoc = tr.doc;
    const updatedPos = newDoc.resolve(prevPos);
    const nextNode = findNextNodeOfType(newDoc, updatedPos.pos + 1, CLAIM_PART);

    if (nextNode) {
      tr.delete(nextNode.pos, nextNode.pos + nextNode.node.nodeSize);
    }

    insertNestedClaimParts(
      tr,
      claimPartAfterCursor.node,
      claimPartAfterCursor.pos
    );

    setSelectionAtEndOfPreviousPart(tr, prevPos, prevNode);

    if (claim?.node.childCount === 1) {
      tr.delete(claim.pos, claim.pos + claimPartAfterCursor.node.nodeSize);
    }
  }
}

// preserve non-paragraph nodes and merge paragraph content
function preserveChildrenWithMergedContent(
  state: EditorState,
  prevNode: Node,
  currentParagraph: Node
): Node[] {
  const children: Node[] = [];

  prevNode.forEach((childNode) => {
    if (childNode.type === state.schema.nodes.paragraph) {
      const combinedContent = childNode.content.append(
        currentParagraph.content
      );
      const updatedParagraph = state.schema.nodes.paragraph.create(
        childNode.attrs,
        combinedContent
      );
      children.push(updatedParagraph);
    } else {
      children.push(childNode);
    }
  });

  return children;
}

function insertNestedClaimParts(
  tr: Transaction,
  node: Node,
  pos: number
): Transaction {
  const nestedClaimParts: Node[] = [];
  node.forEach((childNode, offset, index) => {
    if (childNode.type.name === 'claimPart' && index !== 0) {
      nestedClaimParts.push(childNode);
    }
  });

  if (nestedClaimParts.length > 0) {
    const insertPos = pos + node.firstChild!.nodeSize - 2;

    tr.insert(insertPos, Fragment.fromArray(nestedClaimParts));
  }

  return tr;
}

function setSelectionAtEndOfPreviousPart(
  tr: Transaction,
  pos: number,
  node: Node
) {
  const selectionPos = tr.doc.resolve(pos + node.nodeSize - 2);
  tr.setSelection(TextSelection.near(selectionPos));
}
