import React, { useCallback } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import Notification from '../Notification';
import useTranslation from '../../translations';
import { NavigationItem } from '../Layout';
import { useSessionContext } from '../../contexts';
import { useIsUserSysAdmin } from '../../hooks';
import useCompanyUsersByGroups from '../../queries/useCompanyUsersByGroups';
import { queryClient } from '../../services/queryClient';
import { QueryKey } from '../../queries';
import { useCreateBugReportEmail } from './useCreateBugReportEmail';

const BugReport: React.FC = () => {
  const t = useTranslation();
  const { session } = useSessionContext();
  const isSysAdmin = useIsUserSysAdmin();

  const companyGroupId = session.ownerGroupId;
  const reporterEmail = session.email;

  const openBugReportEmail = useCreateBugReportEmail();

  const { refetch, isLoading } = useCompanyUsersByGroups(
    {
      companyGroupId,
      azureGroupIds: [AzureGroupsConfig.PermissionCompanyReceiveBugReport],
      isSysAdmin,
    },
    {
      staleTime: 0,
      cacheTime: 0,
      enabled: false,
      onError: () => {
        message.error(t('BUG_REPORT.ERROR'));
      },
    }
  );

  const handleClick = useCallback(async () => {
    try {
      await queryClient.cancelQueries(QueryKey.CompanyUsersByGroups);
      queryClient.removeQueries(QueryKey.CompanyUsersByGroups);

      const { data: users } = await refetch();
      const ccRecipients = users
        ? users
            .filter((user) => user.mail && user.mail !== reporterEmail)
            .map((user) => user.mail)
        : [];

      openBugReportEmail(reporterEmail, ccRecipients);
    } catch (error) {
      console.error('Error fetching users for bug report:', error);
    }
  }, [refetch, reporterEmail]);

  return (
    <>
      {isLoading && (
        <NotificationWrapper>
          <Notification
            text={t('BUG_REPORT.NOTIFICATION')}
            spin
            spinSize="large"
            center
          />
        </NotificationWrapper>
      )}
      <NavigationItem
        iconClassName="icn-bug"
        tooltipText={t('NAVIGATION_TOOLTIPS.BUG_REPORT')}
        iconStyle={{ fontSize: 34 }}
        isSettingsItem
        onClick={handleClick}
      />
    </>
  );
};

export default BugReport;

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 200px;
  background-color: ${(props) => props.theme.colors.black20};
  padding-bottom: 50px;
  backdrop-filter: blur(5px);
  z-index: 1000;
`;
