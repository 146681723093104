import { QueryObserverResult } from 'react-query';
import { EditorContentType, EditorDocument } from '../../types';
import { useEditorContent as useEditorDescriptionContent } from '../../queries';

interface Props {
  editorDocumentId: string | undefined;
  contentType?: EditorContentType;
  inlined?: boolean;
  trackChanges?: boolean;
  enabled?: boolean;
  instanceId?: string;
}

type Hook = (props: Props) => {
  data: EditorDocument | null | undefined;
  isLoading: boolean;
  isError: boolean;
  refetch: () => Promise<QueryObserverResult<EditorDocument | null>>;
  isFetching: boolean;
};

const useEditorContent: Hook = ({
  editorDocumentId,
  contentType = EditorContentType.Description,
  enabled = true,
  instanceId,
}) => {
  const {
    data: descriptionData,
    isLoading: isDescriptionLoading,
    isError: isDescriptionError,
    refetch: refetchDescription,
    isFetching,
  } = useEditorDescriptionContent(
    editorDocumentId,
    {
      enabled:
        enabled &&
        contentType === EditorContentType.Description &&
        Boolean(editorDocumentId),
    },
    instanceId
  );

  return {
    data: descriptionData,
    isLoading: isDescriptionLoading,
    isError: isDescriptionError,
    refetch: refetchDescription,
    isFetching,
  };
};

export default useEditorContent;
