import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Checkbox, Tooltip } from 'antd';
import { DocumentPickerItem } from '../types';
import useTranslation from '../../../translations';
import { Colors } from '../../../theme';
import { truncate } from '../../../utils';

interface Props {
  item: DocumentPickerItem;
  level?: number;
  onCheck: (itemId: string, checked: boolean) => void;
  onOpenChange: (itemId: string, open: boolean) => void;
  disableIfNoTextLayer: boolean;
}

const DocumentPickerDropdownItem: React.FC<Props> = ({
  item,
  level = 0,
  onCheck,
  onOpenChange,
  disableIfNoTextLayer,
}) => {
  const t = useTranslation();
  const { id, name, checked, open, subItems, hasOcrLayer } = item;

  const isCheckable = checked != null;
  const isDisabled = !hasOcrLayer && disableIfNoTextLayer;

  const toggleItemClasses = classNames('dropdown-item__icon', {
    'icn-chevron-up': open,
    'icn-chevron-down': !open,
  });

  const handleCheck = () => {
    if (isCheckable && !isDisabled) {
      onCheck(id, !checked);
    }
  };

  return (
    <>
      <Outer
        $level={level}
        $pointer={isCheckable && !isDisabled}
        className="dropdown-item__wrapper"
        onClick={handleCheck}
      >
        {isCheckable && (
          <Checkbox
            checked={checked}
            onChange={handleCheck}
            disabled={isDisabled}
            style={{ marginLeft: 8 }}
          />
        )}
        {!isCheckable && !!subItems?.length && (
          <ToggleIcon
            className={toggleItemClasses}
            onClick={() => onOpenChange(id, !open)}
          />
        )}
        <Name title={name}>{name}</Name>
        {isCheckable && isDisabled && (
          <Tooltip
            title={t('MULTI_DOC_SEARCH.NO_TEXT_LAYER')}
            placement="top"
            color={Colors.primary300}
            arrowPointAtCenter
            overlayInnerStyle={{
              width: 150,
              color: Colors.white87,
            }}
            destroyTooltipOnHide
          >
            <InfoIcon className="icn-error-solid" />
          </Tooltip>
        )}
      </Outer>
      {open &&
        subItems?.map((subItem) => (
          <DocumentPickerDropdownItem
            key={subItem.id}
            item={subItem}
            level={level + 1}
            onCheck={onCheck}
            onOpenChange={onOpenChange}
            disableIfNoTextLayer={disableIfNoTextLayer}
          />
        ))}
    </>
  );
};

export default DocumentPickerDropdownItem;

const Outer = styled.div<{ $level: number; $pointer?: boolean }>`
  display: flex;
  align-items: center;
  flex: 0 0 38px;
  gap: 10px;
  transition: background 0.3s ease;
  padding: 0 20px 0 15px;
  ${(props) => props.$level && `padding-left: ${props.$level * 22 + 20}px;`}
  ${(props) => props.$pointer && `cursor: pointer;`}

  .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.white40};
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border: none;
    }
  }
`;

const Name = styled.div`
  font-size: 15px;
  font-weight: 400;
  flex: 1;
  user-select: none;
  color: ${(props) => props.theme.colors.white87};
  ${truncate()}
`;

const InfoIcon = styled.i`
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white70};
`;

const ToggleIcon = styled(InfoIcon)`
  border-radius: 3px;
  transition: color 0.3s ease;
  transition: background 0.3s ease;
  margin-right: -5px;
  font-size: 19px;
  color: ${(props) => props.theme.colors.white70};
  &:hover {
    background: ${(props) => props.theme.colors.primary200_30};
  }
`;
