import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Node } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import useTranslation from '../../../translations';
import { DocumentSyncState, EditorContentType } from '../../../types';
import useEditorContent from '../useEditorContent';
import useSaveEditorDocument from '../useSaveEditorDocument';
import { parseDoc } from '../util';

import {
  useReactNodeViewPortals,
  ReactNodeViewPortalsProvider,
} from '../images';
import { Props, VeryRichContextProviderProps } from './types';
import { VeryRichContext, VeryRichEditorStateContext } from './context';
import { createEditorState } from './editorState';
import { createEditorView, OnTransactionChange } from './editorView';

const VeryRichProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  editorDocumentId,
  readonly = false,
  trackingChanges = false,
  contentType = EditorContentType.Description,
  instanceId,
}) => {
  const t = useTranslation();
  const [readonlyState, setReadonlyState] = useState<boolean>(readonly);
  useState<DocumentSyncState>('synced');
  const cleanEditorElementRef = useRef(null);
  const diffEditorElementRef = useRef(null);
  const docReference = useRef<Node | null>(null);
  const editorStateRef = useRef<EditorState | null>(null);
  const cleanEditorRef = useRef<EditorView | null>(null);
  const diffEditorRef = useRef<EditorView | null>(null);
  const saveTimeout = useRef<number | null>(null);
  const [cleanEditorState, setCleanEditorState] = useState<EditorState | null>(
    null
  );
  const { createPortal } = useReactNodeViewPortals();
  const handleCreatePortal = useCallback(createPortal, []);

  const {
    data: initialValue,
    isLoading,
    isError,
    isFetching,
  } = useEditorContent({
    editorDocumentId: editorDocumentId as string,
    contentType,
    enabled: !!editorDocumentId,
    instanceId,
  });

  const {
    data: baseInitialValue,
    isLoading: isLoadingBase,
    isError: isErrorBase,
    isFetching: isFetchingBase,
  } = useEditorContent({
    editorDocumentId: initialValue?.changeSet?.baseDocId as string,
    contentType,
    enabled: initialValue?.changeSet?.baseDocId != undefined,
    instanceId,
  });

  const isFetchingData =
    isLoading || isLoadingBase || isFetching || isFetchingBase;

  const { queueDocumentForSaving, documentState, setDocumentState, popQueue } =
    useSaveEditorDocument({
      editorDocumentId,
      docReference,
      readonlyState,
    });

  const onTransactionChange: OnTransactionChange = useCallback(
    (tr, oldState, newState) => {
      setCleanEditorState(newState);
      if (!docReference.current) {
        docReference.current = oldState.doc;
      }
      if (tr.docChanged || tr.getMeta('imageSizeUpdate') === true) {
        setDocumentState('unsynced');
        if (saveTimeout.current !== null) {
          window.clearTimeout(saveTimeout.current);
        }
        // saveTimeout.current = window.setTimeout(
        //   () => queueDocumentForSaving(newState),
        //   5000
        // );
      }
      if (tr.getMeta('resourceInsert') === true) {
        queueDocumentForSaving(newState);
      }
    },
    [editorDocumentId]
  );

  useEffect(() => {
    if (!editorDocumentId || isFetchingData) {
      return;
    }
    const { parsedDoc, status } = parseDoc(initialValue, editorDocumentId, t);
    if (status === 'error') {
      setDocumentState('error');
    }
    editorStateRef.current = createEditorState({
      initialValue,
      baseInitialValue,
      editorDocumentId,
      handleCreatePortal,
      readonlyState,
      parsedDoc,
    });

    const cleanEditorView = createEditorView({
      root: cleanEditorElementRef.current,
      onTransactionChange,
      readonlyState,
      getTargetEditorView: () => diffEditorRef.current,
      editorState: editorStateRef.current,
    });
    cleanEditorRef.current = cleanEditorView;
    setCleanEditorState(cleanEditorView.state);
    if (trackingChanges) {
      const diffEditorState = createEditorState({
        initialValue,
        baseInitialValue,
        editorDocumentId,
        handleCreatePortal,
        readonlyState,
        parsedDoc,
      });
      const diffEditorView = createEditorView({
        attributes: {
          trackChanges: 'true',
        },
        onTransactionChange,
        root: diffEditorElementRef.current,
        readonlyState,
        getTargetEditorView: () => cleanEditorRef.current,
        editorState: diffEditorState,
      });
      diffEditorRef.current = diffEditorView;
    }
    return () => {
      diffEditorRef.current?.destroy();
      cleanEditorRef.current?.destroy();
    };
  }, [
    initialValue,
    trackingChanges,
    readonlyState,
    editorDocumentId,
    onTransactionChange,
    baseInitialValue,
    isFetchingData,
  ]);

  const onUnFocusClean = useCallback(() => {
    // if (saveTimeout.current !== null) {
    //   window.clearTimeout(saveTimeout.current);
    // }
    // cleanEditorRef.current &&
    //   queueDocumentForSaving(cleanEditorRef.current.state);
  }, [editorDocumentId]);

  const onUnFocusDiff = useCallback(() => {
    // if (saveTimeout.current !== null) {
    //   window.clearTimeout(saveTimeout.current);
    // }
    // diffEditorRef.current &&
    //   queueDocumentForSaving(diffEditorRef.current.state);
  }, [editorDocumentId]);

  const flushQueue = useCallback(() => {
    if (saveTimeout.current !== null) {
      window.clearTimeout(saveTimeout.current);
    }
    cleanEditorRef.current &&
      queueDocumentForSaving(cleanEditorRef.current.state);
    popQueue();
  }, [editorDocumentId]);

  const veryRichContextValue: VeryRichContextProviderProps = useMemo(
    () => ({
      documentState,
      setReadonly: setReadonlyState,
      cleanEditorElementRef,
      cleanEditorRef,
      onUnFocusClean,
      onUnFocusDiff,
      diffEditorElementRef,
      flushQueue,
      hasLoadingFailed: isError || isErrorBase,
      loading: isFetchingData,
      contentType: contentType || EditorContentType.Description,
      diffEditorView: diffEditorRef.current || undefined,
      readonlyState,
    }),
    [
      documentState,
      cleanEditorElementRef,
      diffEditorElementRef,
      flushQueue,
      isFetchingData,
      contentType,
      diffEditorRef.current,
      cleanEditorRef.current,
      readonlyState,
    ]
  );

  return (
    <VeryRichContext.Provider value={veryRichContextValue}>
      <VeryRichEditorStateContext.Provider
        value={{
          editorState: cleanEditorState,
        }}
      >
        {children}
      </VeryRichEditorStateContext.Provider>
    </VeryRichContext.Provider>
  );
};

const NodeViewWrapper: React.FC<React.PropsWithChildren<Props>> = (props) => (
  <ReactNodeViewPortalsProvider>
    <VeryRichProvider {...props} />
  </ReactNodeViewPortalsProvider>
);
export { NodeViewWrapper as VeryRichProvider };
