import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tooltip, TooltipProps } from 'antd';
import { Popover } from 'react-tiny-popover';
import useTranslation from '../../../../translations';
import FileSelector from '../../../FileSelector';
import isElementFullyVisible from '../../toolbar/util/isElementFullyVisible';
import bindScrollHandler from '../../toolbar/util/bindScrollHandler';
import { DOCX_FORMAT } from '../../../../constants';
import { Colors } from '../../../../theme';

const TOOLTIP_STYLE = {
  maxWidth: '150px',
  color: Colors.white87,
  padding: '5px 8px',
  fontSize: '11px',
  background: Colors.primary300,
  borderRadius: 3,
  boxShadow: 'none',
  marginBottom: '-10px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center' as React.CSSProperties['textAlign'],
};

const tooltipCommonProps: TooltipProps = {
  placement: 'top',
  overlayInnerStyle: TOOLTIP_STYLE,
  color: Colors.primary300,
};

const ImageAlignValues = {
  NONE: {
    value: null,
    title: 'PM.ACTION.INLINE',
    icon: 'icn-image-inline',
  },
  CENTER: {
    value: 'center',
    title: 'PM.ACTION.TOP_BOTTOM',
    icon: 'icn-image-between-lines',
  },
} as const;

type ImageAlign = keyof typeof ImageAlignValues;
type ImageAlignValue = (typeof ImageAlignValues)[ImageAlign]['value'];

export type ImageInlineEditorValue = {
  readonly align?: ImageAlignValue;
};

type Props = {
  active: boolean;
  resetKey: number;
  loading: boolean;
  enableActions: boolean;
  anchor: HTMLSpanElement | null;
  children: React.ReactNode;
  isEmbeddedObject: boolean;
  onSelect: (val: { align: ImageAlignValue }) => void;
  onFileChange: (selectedFiles: File[]) => void;
  onCopy: () => void;
  onDownload: () => void;
};

const ImageMenu: React.FC<Props> = ({
  active,
  resetKey,
  loading,
  enableActions,
  anchor,
  children,
  isEmbeddedObject,
  onSelect,
  onFileChange,
  onCopy,
  onDownload,
}) => {
  const [open, setOpen] = useState(false);
  const t = useTranslation();

  const handleClick = (clickedAlign: ImageAlignValue) => {
    onSelect({ align: clickedAlign });
  };

  const buttonConfig = {
    align: Object.keys(ImageAlignValues).map((key) => {
      const { value, title, icon } = ImageAlignValues[key as ImageAlign];
      return {
        key,
        icon,
        title,
        onClick: () => handleClick(value),
        disabled: loading,
      };
    }),
    action: [
      {
        key: 'copy',
        icon: 'icn-copy',
        title: 'ACTION.COPY',
        onClick: onCopy,
        disabled: loading,
      },
      ...(isEmbeddedObject
        ? [
            {
              key: 'upload',
              customContent: (
                <FileSelector
                  disabled={!enableActions}
                  resetKey={resetKey}
                  accept={DOCX_FORMAT}
                  onChange={(acceptedFiles) => onFileChange(acceptedFiles)}
                  style={{ height: 30, border: 'none' }}
                  overrideContent={
                    <Tooltip
                      {...tooltipCommonProps}
                      title={t(
                        enableActions
                          ? 'ACTION.REPLACE'
                          : 'PM.SAVE_NOTIFICATION'
                      )}
                    >
                      <StyledIcon className="icn-retry" />
                    </Tooltip>
                  }
                />
              ),
            },
            {
              key: 'download',
              icon: 'icn-download',
              title: enableActions ? 'ACTION.DOWNLOAD' : 'PM.SAVE_NOTIFICATION',
              onClick: onDownload,
              disabled: !enableActions,
            },
          ]
        : []),
    ],
  };

  const renderMenuButton = ({
    key,
    icon,
    title,
    disabled,
    customContent,
    onClick,
  }: {
    key: string;
    icon?: string;
    title?: string;
    disabled?: boolean;
    customContent?: React.ReactNode;
    onClick?: () => void;
  }) => {
    if (customContent) {
      return <div key={key}>{customContent}</div>;
    }
    return (
      <Tooltip key={key} {...tooltipCommonProps} title={t(title || '')}>
        <StyledIcon
          onClick={disabled ? undefined : onClick}
          className={icon}
          disabled={disabled}
        />
      </Tooltip>
    );
  };

  useEffect(() => {
    if (!anchor) {
      return;
    }
    const handleVisibility = () => {
      const isVisible = isElementFullyVisible(anchor) && active;
      setOpen(isVisible);
    };
    handleVisibility();
    const scrollHandle = bindScrollHandler(anchor, handleVisibility);
    return () => scrollHandle.dispose();
  }, [anchor, active]);

  return (
    <Popover
      isOpen={open}
      positions={['top']}
      content={
        <Menu>
          {buttonConfig.align.map(renderMenuButton)}
          <Divider />
          {buttonConfig.action.map(renderMenuButton)}
        </Menu>
      }
      align="center"
      onClickOutside={() => setOpen(false)}
      parentElement={anchor ?? undefined}
      containerStyle={{ zIndex: '10000000', top: '-7px' }}
    >
      <div>{children}</div>
    </Popover>
  );
};

const Menu = styled.div`
  background: ${(props) => props.theme.colors.primary200};
  display: flex;
  padding: 5px;
  border-radius: 8px;
  align-items: center;
`;

const StyledIcon = styled.i<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  transition: background 0.3s ease-in-out;
  color: ${(props) => props.theme.colors.white70} !important;
  &:hover {
    background: ${(props) => props.theme.colors.white20};
  }
  cursor: pointer;
  pointer-events: auto;

  ${(props) => props.disabled && 'opacity: 60%;'}
`;

const Divider = styled.div`
  height: 20px;
  margin: 4px;
  width: 1px;
  background-color: ${(props) => props.theme.colors.white20};
`;

export default memo(ImageMenu);
