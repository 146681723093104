import { Tutorial } from '../types';
import Api from './base';

const API_BASE = '/v1/tutorials';

export const getTutorials = (): Promise<Tutorial[]> =>
  Api.get<Tutorial[]>(`${API_BASE}`).then((response) => response.data);

export const getTutorialVideoUrl = (tutorialId: string): Promise<string> =>
  Api.get<string>(`${API_BASE}/${tutorialId}/url`).then(
    (response) => response.data
  );
