/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useMemo } from 'react';
import styled, { CSSObject, useTheme } from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import useTranslation from '../../../translations';
import { Spinner } from '../../Spinner';
import { NewContact, PatentApplicationStakeholder } from '../../../types';
import UpdateContactSchema, {
  UpdateContact,
} from '../../../validations/updateContactSchema';
import { FormField, Input } from '../../Form';
import { PrimaryButton, VoidButton } from '../../Button';
import IposTheme from '../../../theme';
import useGetContact from '../../../queries/useGetContact';
import { CountryPicker, GenderPicker, LanguagePicker } from '../Pickers';
import CustomDatePicker from '../Pickers/CustomDatePicker';
import CollapsablePanel from '../../CollapsablePanel';
import { Actions } from './RemoveStakeholder';

interface Props {
  stakeholder?: PatentApplicationStakeholder | null;
  onCancel: () => void;
  onUpdate: (stakeholder: PatentApplicationStakeholder) => void;
  onCreate: (contact: NewContact) => void;
}

interface FieldDefinition {
  name: string;
  translationKey: string;
  component: React.FC<any>;
  placeholder?: string;
  icon?: ReactNode;
}

const LabeledInput: React.FC<FieldDefinition> = ({
  name,
  component: Component,
  placeholder,
  translationKey,
}) => {
  const { colors } = useTheme();
  const onEnterPressed = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
    }
  };
  return (
    <Flex>
      <FormField
        errorStyle={{ ...ErrorStyle, position: 'relative', top: 0 }}
        onKeyDown={onEnterPressed}
        labelStyle={LabelStyle}
        style={{
          flex: 1,
          height: 'fit-content',
          backgroundColor: colors.primary700,
          borderRadius: '6px',
          marginBottom: '8px',
        }}
        componentStyle={{
          border: 'none',
          fontSize: '15px',
        }}
        label={translationKey}
        name={name}
        component={Component}
        placeholder={placeholder}
      />
    </Flex>
  );
};

const ContactForm: React.FC<Props> = ({
  stakeholder,
  onCancel,
  onUpdate,
  onCreate,
}) => {
  const t = useTranslation();
  const { colors } = useTheme();

  const isEditing = !!stakeholder;

  const { data: latestContact, isLoading } = useGetContact(
    stakeholder?.contact?.id || '',
    {
      enabled: isEditing,
    }
  );

  const defaultValues = useMemo(() => {
    const contact = latestContact || stakeholder?.contact;
    return {
      displayName: contact?.displayName ?? '',
      phone: contact?.phone ?? '',
      email: contact?.email ?? '',
      company: contact?.company ?? '',
      department: contact?.department ?? '',
      title: contact?.title ?? '',
      firstName: contact?.firstName ?? '',
      middleName: contact?.middleName ?? '',
      lastName: contact?.lastName ?? '',
      birthday: contact?.birthday ?? null,
      gender: contact?.gender ?? null,
      nationality: contact?.nationality ?? null,
      address: contact?.address ?? '',
      city: contact?.city ?? '',
      country: contact?.country ?? null,
      state: contact?.state ?? '',
      registrationNumber: contact?.registrationNumber ?? '',
      postcode: contact?.postcode ?? '',
      mobile: contact?.mobile ?? '',
      languages: contact?.languages ?? [],
      socialMedia: contact?.socialMedia ?? [],
      representation: contact?.representation ?? '',
    };
  }, [latestContact, stakeholder]);

  const PANELS: { title: string; fields: FieldDefinition[] }[] = [
    {
      title: t('STAKEHOLDERS.PROPERTIES.PERSONAL_INFO'),
      fields: [
        {
          name: 'title',
          translationKey: 'STAKEHOLDERS.PROPERTIES.TITLE',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.TITLE'),
        },
        {
          name: 'displayName',
          translationKey: 'STAKEHOLDERS.PROPERTIES.DISPLAY_NAME',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.DISPLAY_NAME'),
        },
        {
          name: 'firstName',
          translationKey: 'STAKEHOLDERS.PROPERTIES.FIRST_NAME',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.FIRST_NAME'),
        },
        {
          name: 'middleName',
          translationKey: 'STAKEHOLDERS.PROPERTIES.MIDDLE_NAME',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.MIDDLE_NAME'),
        },
        {
          name: 'lastName',
          translationKey: 'STAKEHOLDERS.PROPERTIES.LAST_NAME',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.LAST_NAME'),
        },
      ],
    },
    {
      title: t('STAKEHOLDERS.PROPERTIES.PERSONAL_DETAILS'),
      fields: [
        {
          icon: <FieldIcon className="icn-calendar" />,
          name: 'birthday',
          translationKey: 'STAKEHOLDERS.PROPERTIES.BIRTHDAY',
          component: CustomDatePicker,
        },
        {
          name: 'gender',
          translationKey: 'STAKEHOLDERS.PROPERTIES.GENDER.TITLE',
          component: GenderPicker,
        },
        {
          name: 'nationality',
          translationKey: 'STAKEHOLDERS.PROPERTIES.NATIONALITY',
          component: CountryPicker,
        },
        {
          name: 'languages',
          translationKey: 'STAKEHOLDERS.PROPERTIES.LANGUAGES',
          component: LanguagePicker,
        },
      ],
    },
    {
      title: t('STAKEHOLDERS.PROPERTIES.CONTACT_INFO'),
      fields: [
        {
          name: 'company',
          translationKey: 'STAKEHOLDERS.PROPERTIES.COMPANY',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.COMPANY'),
        },
        {
          name: 'department',
          translationKey: 'STAKEHOLDERS.PROPERTIES.DEPARTMENT',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.DEPARTMENT'),
        },
        {
          name: 'address',
          translationKey: 'STAKEHOLDERS.PROPERTIES.ADDRESS',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.ADDRESS'),
        },
        {
          name: 'city',
          translationKey: 'STAKEHOLDERS.PROPERTIES.CITY',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.CITY'),
        },
        {
          name: 'country',
          translationKey: 'STAKEHOLDERS.PROPERTIES.COUNTRY',
          component: CountryPicker,
          placeholder: '',
        },
        {
          name: 'postcode',
          translationKey: 'STAKEHOLDERS.PROPERTIES.POSTCODE',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.POSTCODE'),
        },
        {
          name: 'phone',
          translationKey: 'STAKEHOLDERS.PROPERTIES.PHONE',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.PHONE'),
        },
        {
          name: 'mobile',
          translationKey: 'STAKEHOLDERS.PROPERTIES.MOBILE',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.MOBILE'),
        },
        {
          name: 'email',
          translationKey: 'STAKEHOLDERS.PROPERTIES.EMAIL',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.EMAIL'),
        },
        {
          name: 'state',
          translationKey: 'STAKEHOLDERS.PROPERTIES.STATE',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.STATE'),
        },
        {
          name: 'registrationNumber',
          translationKey: 'STAKEHOLDERS.PROPERTIES.REGISTRATION_NUMBER',
          component: Input.Void,
          placeholder: t('STAKEHOLDERS.PLACEHOLDER.REGISTRATION_NUMBER'),
        },
      ],
    },
    {
      title: t('STAKEHOLDERS.PROPERTIES.SOCIAL_INFO'),
      fields: [],
    },
  ];

  const methods = useForm<UpdateContact>({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(UpdateContactSchema),
  });

  const handleSubmit = (formValues: UpdateContact) => {
    const contactData = {
      ...formValues,
      birthday: formValues.birthday
        ? dayjs(formValues.birthday).format()
        : null,
    } as NewContact;

    if (stakeholder) {
      onUpdate({
        ...stakeholder,
        contact: {
          ...stakeholder.contact,
          ...contactData,
        },
      });
      return;
    }

    onCreate(contactData);
  };

  let innerContent = (
    <>
      {PANELS.map((panel) => (
        <CollapsablePanel
          key={panel.title}
          title={panel.title}
          panelHeaderStyle={{
            padding: '6px 7px 6px 15px',
            ':hover': { background: 'unset' },
          }}
          fieldGroupTitleStyle={{ fontSize: '13px' }}
          arrowIconStyle={{ fontSize: '24px', color: colors.white70 }}
          fieldGroupChildrenStyle={{
            padding: '6px 13px 0px 15px',
            border: 'none',
            ':focus-within': {
              borderColor: 'unset',
            },
            ':hover': {
              backgroundColor: colors.primary700,
            },
          }}
        >
          {panel.fields.map((df) => (
            <LabeledInput
              key={df.name}
              translationKey={t(df.translationKey)}
              name={df.name}
              component={df.component}
              placeholder={df.placeholder}
            />
          ))}
        </CollapsablePanel>
      ))}
    </>
  );

  if (isLoading) {
    innerContent = (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <Outer onSubmit={methods.handleSubmit(handleSubmit)}>
        <Inner>{innerContent}</Inner>
        <Actions>
          <VoidButton onClick={onCancel}>{t('ACTION.CANCEL')}</VoidButton>
          <PrimaryButton type="submit">{t('ACTION.SAVE')}</PrimaryButton>
        </Actions>
      </Outer>
    </FormProvider>
  );
};

const Outer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 40px 40px;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 20px 0 0;
`;
const Flex = styled.div`
  display: flex;
  height: auto;
`;
const LabelStyle: CSSObject = {
  fontSize: '12px',
  color: IposTheme.colors.white70,
};

const ErrorStyle: CSSObject = {
  fontSize: '12px',
  color: IposTheme.colors.error,
};

export const FieldIcon = styled.i`
  font-size: 20px;
  margin-bottom: 5px;
  color: ${(props) => props.theme.colors.primary100};
  align-self: center;
`;
export default ContactForm;
