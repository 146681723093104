import React, { useState, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Thumbnail } from 'react-pdf';
import { transparentize } from 'polished';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Normal } from '../Atoms/Atoms';
import { useTranslation } from '../../translations';
import { Icon } from '../Icon';

interface Props {
  totalNumberOfPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const ThumbRender = ({ index, style, data }: ListChildComponentProps) => (
  <PageWrapper style={style} onClick={() => data.setCurrentPage(index + 1)}>
    <Thumbnail
      loading=""
      height={100}
      pageNumber={index + 1}
      className={classNames('thumbnail-page-preview', {
        'thumbnail-page-preview--current': data.currentPage === index + 1,
      })}
    />
    <Normal>{index + 1}</Normal>
  </PageWrapper>
);

const ThumbnailPreview: React.FC<Props> = ({
  totalNumberOfPages,
  currentPage,
  setCurrentPage,
}) => {
  const t = useTranslation();
  const listRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [listHeight, setListHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!entries.length) {
          return;
        }
        const entry = entries[0];
        if (!entry) {
          return;
        }
        setListHeight(entry.target.clientHeight);
      });
    });
    listRef.current && observer.observe(listRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Outer expanded={expanded}>
      <ThumbnailsListWrapper expanded={expanded}>
        <CloseIcon
          className="icn-clear"
          onClick={() => setExpanded(false)}
          transition
        />
        <ThumbnailsText>{t('PAGE_THUMBNAILS')}</ThumbnailsText>
        <ThumbnailsList ref={listRef}>
          <FixedSizeList
            height={listHeight}
            itemCount={totalNumberOfPages}
            itemSize={100}
            width={100}
            itemData={{ setCurrentPage, currentPage }}
          >
            {ThumbRender}
          </FixedSizeList>
        </ThumbnailsList>
      </ThumbnailsListWrapper>
      {!expanded && (
        <IconWrapper onClick={() => setExpanded(true)}>
          <OpenIcon className="icn-files-outlined" transition />
        </IconWrapper>
      )}
    </Outer>
  );
};

const CloseIcon = styled(Icon)`
  font-size: 24px;
  transition: color 0.3s ease;
  color: ${(props) => props.theme.colors.primary700};
  &:hover {
    color: ${(props) => props.theme.colors.white40};
  }
`;

const OpenIcon = styled(Icon)`
  font-size: 24px;
  transition: color 0.3s ease;
  color: ${(props) => props.theme.colors.primary100};
`;

const IconWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  &:hover {
    ${OpenIcon} {
      color: ${(props) => props.theme.colors.primary400};
    }
  }
`;

const ThumbnailsText = styled(Normal)`
  font-weight: 500;
  margin-bottom: 22px;
  margin-top: 12px;
  color: ${(props) => props.theme.colors.primary700};
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ThumbnailsList = styled.div`
  padding: 0 5px;
  flex: 1;
`;

const ThumbnailsListWrapper = styled.div<{ expanded: boolean }>`
  display: ${(props) => (props.expanded ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  height: 100%;
`;

const Outer = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.primary700};
  position: absolute;
  z-index: 10;
  top: ${(props) => (props.expanded ? 0 : 40)}px;
  width: ${(props) => (props.expanded ? 'auto' : 48)}px;
  max-width: 180px;
  height: ${(props) => (props.expanded ? '100%' : '48px')};
  cursor: pointer;
  min-height: 0;
  border-radius: ${(props) => (props.expanded ? 0 : '0 6px 6px 0')};
  background-color: ${(props) =>
    props.expanded
      ? transparentize(0.45, props.theme.colors.primary700)
      : transparentize(0.8, props.theme.colors.primary700)};
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
`;

export default ThumbnailPreview;
