import { Command } from 'prosemirror-state';
import { findNextNodeOfType, findParentNodeOfType } from '../util';
import { CLAIM, CLAIM_PART } from '../schema/nodes/nodeNames';
import {
  claimPartBackspace,
  handleNumberingChangeToNone,
  mergeClaimParts,
} from './claimPartBackspace';

export const claimPartDelete: Command = (state, dispatch) => {
  const { selection, schema, tr } = state;
  const { $from, empty } = selection;
  const { claimPart, claim } = schema.nodes;

  // Find the claimPart and claim nodes at the cursor position
  const claimPartOnCursor = findParentNodeOfType(claimPart)(selection);
  const claimOnCursor = findParentNodeOfType(claim)(selection);

  if (!claimPartOnCursor) {
    return false;
  }

  // when claim part is empty instead of joining it with next claimPart delete it
  if ($from.parent.content.size === 0) {
    claimPartBackspace(state, dispatch);
  }

  const isAtEndOfClaimPart =
    $from.parentOffset === $from.parent.content.size && empty;
  if (!isAtEndOfClaimPart) {
    return false;
  }

  const nextClaimPart = findNextNodeOfType(
    state.doc,
    claimPartOnCursor.pos,
    CLAIM_PART
  );

  const nextClaim = findNextNodeOfType(state.doc, claimOnCursor!.pos, CLAIM);

  if (nextClaimPart) {
    if (nextClaimPart.node.attrs.numberingType !== 'none') {
      handleNumberingChangeToNone(state, tr, nextClaimPart);
    } else {
      mergeClaimParts(state, tr, nextClaimPart, claimPartOnCursor, nextClaim);
    }
  }

  if (dispatch) {
    dispatch(tr.scrollIntoView());
  }

  return false;
};
