import { T } from '../../index';
import Api from './base';

const API_BASE = '/v1/contacts';

export const getContact = (contactId: string): Promise<T.Contact> =>
  Api.get<T.Contact>(`${API_BASE}/${contactId}`).then(
    (response) => response.data
  );

export const getContactByUserIdentity = (): Promise<T.Contact> =>
  Api.get<T.Contact>(`${API_BASE}`).then((response) => response.data);

export const getContacts: T.PaginatedApiFunction<
  T.Contact,
  T.ContactsQueryParams
> = (data: T.ContactsQueryParams): Promise<T.Contact[]> =>
  Api.post<T.Contact[]>(`${API_BASE}/search`, data).then(
    (response) => response.data
  );

export const putUpdateContact = (
  contactId: string,
  data: T.Contact
): Promise<T.Contact> =>
  Api.put<T.Contact>(`${API_BASE}/${contactId}`, data).then(
    (response) => response.data
  );

export const postCreateContact = (data: T.NewContact): Promise<T.Contact> =>
  Api.post<T.Contact>(`${API_BASE}`, data).then((response) => response.data);

export const deleteContact = (contactId: string): Promise<void> =>
  Api.delete<void>(`${API_BASE}/${contactId}`).then(
    (response) => response.data
  );
