import { Command, Selection } from 'prosemirror-state';
import { NUMBERED_PARAGRAPH } from '../schema/nodes/nodeNames';

export const numberedParagraphCommand: Command = (state, dispatch) => {
  const { selection } = state,
    { $from, $to } = selection;
  const nodeType = $from.node().type;
  const parentEmpty = $from.parent.content.size === 0;
  if (parentEmpty) {
    return false;
  }

  if (!selection.empty) {
    return false;
  }

  const paragraphEnd = state.doc.resolve($from.end());
  if ($to.pos !== paragraphEnd.pos) {
    return false;
  }

  if (nodeType && nodeType.name === NUMBERED_PARAGRAPH) {
    const newNumberedParagraph = nodeType.createAndFill();
    if (newNumberedParagraph) {
      const pos = $from.after();
      const tr = state.tr.insert(pos, newNumberedParagraph);
      tr.setSelection(Selection.near(tr.doc.resolve(pos)));
      dispatch?.(tr.scrollIntoView());
      return true;
    }
  }
  return false;
};
