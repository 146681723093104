import React from 'react';
import styled from 'styled-components';
import {
  useVeryRichContext,
  useVeryRichEditorStateContext,
} from '../../../contexts';
import { COMMAND_GROUPS, CommandGroup } from './config';
import ToolbarButton from './ToolbarButton';

import './styles.less';

const DISABLED_COMMAND_GROUPS = ['Text Size', 'Headings...'];

const Toolbar: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { cleanEditorRef } = useVeryRichContext();
  const { editorState } = useVeryRichEditorStateContext();
  // Editor view will always be defined because of the editor. This is a bit hacky, but this is React for you.
  const editorView = cleanEditorRef?.current;
  if (!editorView || !editorState) {
    return null;
  }
  const EnabledCommandGroups: CommandGroup[] = [];

  COMMAND_GROUPS.forEach((commandGroup) => {
    const filteredCommandGroup = { ...commandGroup };

    Object.keys(commandGroup).forEach((label) => {
      if (DISABLED_COMMAND_GROUPS.includes(label)) {
        delete filteredCommandGroup[label];
      }
    });

    EnabledCommandGroups.push(filteredCommandGroup);
  });

  return (
    <Outer>
      {EnabledCommandGroups.map((group, index) => (
        <ToolbarButton
          key={index}
          group={group}
          index={index}
          editorView={editorView}
          editorState={editorState}
        />
      ))}
      {children}
    </Outer>
  );
};

const Outer = styled.div`
  padding: 8px;
  z-index: 10000;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
`;

export default Toolbar;
