import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Api } from '../..';
import { QueryKey } from '../queries';

const useTutorialVideoUrl = (
  tutorialId: string,
  options?: UseQueryOptions<string, AxiosError>
): UseQueryResult<string, AxiosError> =>
  useQuery<string, AxiosError>(
    [QueryKey.TutorialVideoUrl, tutorialId],
    () => Api.tutorials.getTutorialVideoUrl(tutorialId),
    options
  );

export default useTutorialVideoUrl;
