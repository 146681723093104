import type { NodeJSONRaw, Normalizer } from '../types';

const getIndent = (input: NodeJSONRaw): number => {
  const indentLevel = input.attrs?.indentLevel ?? 0;
  return Math.max(0, Math.floor(indentLevel));
};

export const buildNestedClaimByIndent: Normalizer = (input, instance) => {
  if (input.type !== 'claim') {
    return {
      ...input,
      content:
        input.content?.map((child) =>
          buildNestedClaimByIndent(child, instance)
        ) ?? null,
    };
  }

  const indentedClaims: NodeJSONRaw[] = [];
  const stack: NodeJSONRaw[] = [];
  const items = input.content ?? [];

  for (const item of items) {
    if (item.type !== 'claimPart') {
      throw new TypeError(
        'Unexpected node type, the content of claim should be claimPart'
      );
    }

    const newItem = {
      ...item,
      content: item.content ? [...item.content] : item.content,
    };
    const indent = getIndent(item);
    while (stack.length > 0 && getIndent(stack[stack.length - 1]) >= indent) {
      stack.pop();
    }

    if (stack.length === 0) {
      indentedClaims.push(newItem);
    } else {
      let parent = stack[stack.length - 1];
      while (getIndent(parent) < indent - 1) {
        const intermediateItem = {
          type: 'claimPart',
          attrs: { indentLevel: getIndent(parent) + 1 },
          content: [],
        };
        if (!parent.content) {
          parent.content = [];
        }
        parent.content.push(intermediateItem);
        parent = intermediateItem;
        stack.push(parent);
      }
      if (!parent.content) {
        parent.content = [];
      }
      parent.content.push(newItem);
    }

    stack.push(newItem);
  }

  return {
    ...input,
    content: indentedClaims,
  };
};
