import React, { useRef } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { useNodeViewContext } from '../../ReactNodeView/nodeView';
import claimsPlugin from '../../plugins/claims';
import ClaimActionsMenu from './ClaimActionsMenu';
import ClaimStatusIdentifier from './ClaimStatusIdentifier';
import AmendmentNotification from './AmendmentNotification';

const ClaimNodeView = () => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const { contentRef, node, view, getPos } = useNodeViewContext();
  const {
    calculatedClaimNumber,
    claimNumber,
    baseClaimNumber,
    isDeleted,
    isInserted,
    comment,
    claimTraceId,
    isStale,
  } = node.attrs;

  const claimsPluginState =
    view?.state && claimsPlugin.key.getState(view?.state);

  const { patentOffice, undoClaim, redoClaim, retranslateClaim } =
    claimsPluginState || {};

  const pos = getPos();
  const currentClaimNumber = calculatedClaimNumber || claimNumber;
  const isTranslationEditor = !!undoClaim && !!redoClaim && !!retranslateClaim;

  const isInsertedClass =
    baseClaimNumber && baseClaimNumber !== currentClaimNumber ? 'inserted' : '';

  const className = cx('claim', {
    deleted: isDeleted,
    inserted: isInserted,
  });

  return (
    <div
      className={className}
      data-claim-number={claimNumber}
      data-claim-trace-id={claimTraceId}
      data-base-claim-number={baseClaimNumber}
      data-calculated-claim-number={calculatedClaimNumber}
      data-is-deleted={isDeleted}
      data-is-inserted={isInserted}
    >
      <div className="number" contentEditable={false}>
        {baseClaimNumber && baseClaimNumber !== currentClaimNumber && (
          <span className="old-claim-number">{baseClaimNumber}</span>
        )}
        <span
          className={cx('claim-number', {
            [isInsertedClass]: isInsertedClass,
          })}
        >
          {currentClaimNumber}
        </span>
      </div>
      <FlexColumn>
        <ClaimStatusIdentifier
          node={node}
          view={view}
          pos={pos}
          patentOffice={patentOffice}
          isTranslationEditor={isTranslationEditor}
        />
        <div
          className="content"
          ref={(element) => {
            contentRef(element);
            nodeRef.current = element;
          }}
        >
          <ClaimActionsMenu
            editorView={view}
            active={!isDeleted}
            root={nodeRef}
            patentOffice={patentOffice}
            node={node}
            undoClaim={undoClaim}
            redoClaim={redoClaim}
            retranslateClaim={retranslateClaim}
            isTranslationEditor={isTranslationEditor}
          />
        </div>
      </FlexColumn>
      <AmendmentNotification
        isStale={isStale}
        isTranslationEditor={isTranslationEditor}
        view={view}
      />
      {comment && <span className="note">{comment}</span>}
    </div>
  );
};

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default ClaimNodeView;
