import React, { PropsWithChildren, ReactElement } from 'react';
import { Select as AntSelect } from 'antd';
import { useTheme } from 'styled-components';
import 'rc-select/assets/index.less';
const { Option } = AntSelect;

import './styles.less';

interface Props<T> {
  value: T;
  entries: { value: T; name: string; label?: React.ReactNode }[];
  listHeight?: number;
  dropdownMatchSelectWidth?: number | boolean;
  onChange: (value: T) => void;
}

const DropdownSelect = <T,>({
  value,
  entries,
  listHeight,
  dropdownMatchSelectWidth,
  onChange,
}: PropsWithChildren<Props<T>>): ReactElement | null => {
  const theme = useTheme();

  const _onChange = (newValue: string) => {
    onChange(entries[parseInt(newValue, 10)].value);
  };

  return (
    <AntSelect
      value={entries.find((e) => e.value === value)?.name}
      onChange={_onChange}
      data-testid="ipos-dropdown-select"
      className="ant-select-customize-input dropdown-select"
      showSearch={false}
      dropdownStyle={{
        backgroundImage: `linear-gradient(129deg, ${theme.colors.primary600}, ${theme.colors.primary800})`,
      }}
      listHeight={listHeight}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
    >
      {entries.map((entry, index) => (
        <Option data-testid="ipos-dropdown-select-item" key={index}>
          {entry.label ?? entry.name}
        </Option>
      ))}
    </AntSelect>
  );
};

export default DropdownSelect;
