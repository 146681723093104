import { Command, EditorState, Selection } from 'prosemirror-state';
import { Node } from 'prosemirror-model';
import { findParentNodeOfType } from '../util';
import { claimsPlugin } from '../plugins';
import { PatentOffice } from '../../../types';
import { CLAIM } from '../schema/nodes/nodeNames';
import { StatusIdentifier } from '../nodeView';

export type ClaimPosition = 'above' | 'below';

const getLastClaimNumber = (state: EditorState): number | undefined => {
  if (!state) {
    return;
  }
  const claimNodes: Node[] = [];
  state.doc.descendants((node) => {
    if (node.type.name === CLAIM) {
      claimNodes.push(node);
    }
  });
  if (claimNodes.length > 0) {
    const lastClaim = claimNodes[claimNodes.length - 1];
    return lastClaim.attrs.claimNumber;
  }

  return;
};

export const claimEnter =
  (insertPosition: ClaimPosition = 'below'): Command =>
  (state, dispatch) => {
    const { patentOffice, includeClaimAdvantage } =
      claimsPlugin.key.getState(state) || {};

    const claimNode = findParentNodeOfType(state.schema.nodes.claim)(
      state.selection
    );
    if (!claimNode) {
      return false;
    }

    const currentClaimNumber = claimNode.node.attrs.calculatedClaimNumber;
    const lastClaimNumber = getLastClaimNumber(state) || currentClaimNumber;

    const nextClaimNumber =
      insertPosition === 'above'
        ? currentClaimNumber
        : patentOffice === PatentOffice.USPTO
        ? lastClaimNumber + 1
        : currentClaimNumber + 1;

    const emptyClaimPart = state.schema.nodes.claimPart.createAndFill(
      { isInserted: true },
      state.schema.nodes.paragraph.create({})
    );

    if (!emptyClaimPart) {
      return false;
    }

    const emptyClaimAdvantage = includeClaimAdvantage
      ? state.schema.nodes.claimAdvantage.createAndFill(
          { claimNumber: nextClaimNumber },
          state.schema.nodes.paragraph.create({})
        )
      : null;

    const newClaimContent = emptyClaimAdvantage
      ? [emptyClaimPart, emptyClaimAdvantage]
      : [emptyClaimPart];

    const newClaim = state.schema.nodes.claim.createAndFill(
      {
        isInserted: true,
        statusIdentifier:
          patentOffice === PatentOffice.USPTO ? StatusIdentifier.New : null,
        calculatedClaimNumber: nextClaimNumber,
        claimNumber: nextClaimNumber,
      },
      newClaimContent
    );

    if (!newClaim) {
      return false;
    }

    if (dispatch) {
      const tr = state.tr;
      const claimPosition = findParentNodeOfType(state.schema.nodes.claim)(
        tr.selection
      );

      if (claimPosition) {
        const insertPos =
          insertPosition === 'above'
            ? claimPosition.pos
            : patentOffice === PatentOffice.USPTO
            ? tr.doc.content.size - 1
            : claimPosition.pos + claimPosition.node.nodeSize;
        tr.insert(insertPos, newClaim);
        tr.setSelection(Selection.near(tr.doc.resolve(insertPos)));
        tr.scrollIntoView();
      }
      dispatch(tr);
    }

    return true;
  };
