import type { Node } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import type { ReactPortal } from 'react';
import { schema } from '../schema/schema';
import { preventEmptyParagraphRemovalPlugin } from '../plugins';
import { basePlugins } from '../config/plugins';
import { trackChangesPlugin } from '../trackChangesPlugin/trackChangesPlugin';
import imageDescriptionPlugin from '../images/imageDescriptionPlugin';
import { EditorDocument } from '../../../types';

export type CreateEditorStateProps = {
  initialValue?: EditorDocument | null;
  baseInitialValue?: EditorDocument | null;
  parsedDoc?: Node | null;
  editorDocumentId: string;
  readonlyState: boolean;
  handleCreatePortal: (portal: ReactPortal) => void;
};
export function createEditorState({
  initialValue,
  baseInitialValue,
  editorDocumentId,
  handleCreatePortal,
  readonlyState,
  parsedDoc,
}: CreateEditorStateProps) {
  const plugins = [
    preventEmptyParagraphRemovalPlugin,
    ...basePlugins,
    trackChangesPlugin({
      initialChanges: initialValue?.changeSet?.changes || null,
      referenceDoc: baseInitialValue || null,
    }),
    imageDescriptionPlugin({
      schema,
      editorDocumentId,
      handleCreatePortal,
      readOnly: readonlyState,
    }),
  ];
  return EditorState.create({
    schema,
    doc: parsedDoc == null ? undefined : parsedDoc,
    plugins,
  });
}
