import { InputRule } from 'prosemirror-inputrules';
import { Node, Schema } from 'prosemirror-model';
import { EditorState, TextSelection, Transaction } from 'prosemirror-state';
import { isNodeEmpty } from '../util';

export function claimPartInputRule(schema: Schema): InputRule {
  return new InputRule(/./, (state: EditorState, match): Transaction | null => {
    const { tr, selection } = state;

    if (!(selection instanceof TextSelection) || selection.empty) {
      return null;
    }

    const { from, to } = selection;
    const resolvedPos = state.doc.resolve(from);
    let claimPartPos: number | null = null;
    let claimPartDepth: number | null = null;
    let claimPartNode: Node | null = null;

    for (let depth = resolvedPos.depth; depth > 0; depth--) {
      const parentNode = resolvedPos.node(depth);
      if (parentNode.type === schema.nodes.claimPart) {
        claimPartPos = resolvedPos.before(depth);
        claimPartDepth = depth;
        claimPartNode = parentNode;
        break;
      }
    }

    if (claimPartPos === null || !claimPartNode || claimPartDepth === null) {
      return null;
    }

    tr.delete(from, to);

    const newDoc = tr.doc;

    const newResolvedPos = newDoc.resolve(claimPartPos);
    const updatedClaimPart = newResolvedPos.nodeAfter;

    if (updatedClaimPart && isNodeEmpty(updatedClaimPart)) {
      const newClaimPart = schema.nodes.claimPart.createAndFill(
        {},
        schema.nodes.paragraph.createAndFill({}, schema.text(match[0]))
      );

      if (newClaimPart) {
        tr.replaceWith(
          claimPartPos,
          claimPartPos + updatedClaimPart.nodeSize,
          newClaimPart
        );

        const selectionStartPos =
          claimPartPos + newClaimPart.nodeSize - newClaimPart.content.size + 1;
        tr.setSelection(TextSelection.create(tr.doc, selectionStartPos));
      }

      return tr.scrollIntoView();
    }

    return null;
  });
}
