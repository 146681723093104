import { DocumentSource, Language } from '../types';

export const LOGIN_REDIRECT_URL_KEY = 'ipos_login_redirect_url';

export const DATE_TIME_FORMAT = {
  DATE: 'MMMM DD YYYY',
  TIME: 'h:mm A',
  DATE_TIME: 'MMM DD YYYY, h:mm A',
};

export const DEFAULT_PAGE_SIZE = 20;

export const A4_ASPECT_RATIO = 1.4142;

export const PDF_DOCUMENT_OPTIONS = {
  cMapUrl: `/cmaps/`,
  enableHWA: true,
};

export const APP_LANGUAGES = [
  { languageCode: 'en-US', LCID: 'en-US', name: 'English', default: true },
  { languageCode: 'de-de', LCID: 'de-de', name: 'German', default: false },
];

export const DOCUMENT_SOURCES_CAN_SEARCH = [
  DocumentSource.Imported,
  DocumentSource.Epo,
  DocumentSource.PatBase,
  DocumentSource.Google,
  DocumentSource.FormatConversion,
];

export const DOCX_MIME =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const DOCX_EXTENSION = '.docx';
export const XML_MIME = 'text/xml';
export const PDF_MIME = 'application/pdf';
export const CSV_MIME = 'text/csv';
export const XML_EXTENSION = '.xml';
export const PDF_EXTENSION = '.pdf';
export const CSV_EXTENSION = '.csv';
export const FASTA_EXTENSION = '.fasta';
export const XLSX_EXTENSION = '.xlsx';
export const PNG_EXTENSION = '.png';
export const JPG_EXTENSION = '.jpg';
export const SVG_EXTENSION = '.svg';
export const PPTX_EXTENSION = '.pptx';
export const TXT_EXTENSION = '.txt';

export const DOCX_FORMAT = `${DOCX_MIME},${DOCX_EXTENSION}`;
export const PDF_FORMAT = `${PDF_MIME},${PDF_EXTENSION}`;
export const CSV_FORMAT = `${CSV_MIME},${CSV_EXTENSION}`;

export const DOCX_AND_PDF_FORMATS = `${DOCX_FORMAT},${PDF_FORMAT}`;
export const DOCX_TXT_PDF_FORMATS = `${DOCX_FORMAT},${PDF_FORMAT},${TXT_EXTENSION}`;
export const DOCX_TXT_PDF_FASTA_XLSX_PNG_JPG_SVG_PPTX_FORMATS = `${DOCX_TXT_PDF_FORMATS},${FASTA_EXTENSION},${XLSX_EXTENSION},${PNG_EXTENSION},${JPG_EXTENSION},
${SVG_EXTENSION},${PPTX_EXTENSION}`;
export const PNG_JPG_SVG_FORMATS = `${PNG_EXTENSION},${JPG_EXTENSION},${SVG_EXTENSION}`;

// TODO: should come from API
export const DEEPL_SUPPORTED_LANGUAGES = [
  Language.Bg,
  Language.Zh,
  Language.Cs,
  Language.Da,
  Language.Nl,
  Language.En,
  Language.Et,
  Language.Fi,
  Language.Fr,
  Language.De,
  Language.El,
  Language.Hu,
  Language.Id,
  Language.It,
  Language.Ja,
  Language.Ko,
  Language.Lv,
  Language.Lt,
  Language.No,
  Language.Pl,
  Language.Pt,
  Language.Ro,
  Language.Ru,
  Language.Sk,
  Language.Sl,
  Language.Es,
  Language.Sv,
  Language.Tr,
  Language.Uk,
];

// TODO: should come from API
// NOTE: Albanian (sq), Croatian (hr), Icelandic (is), Serbian (sr), Macedonian (mk), Norwegian (no) are not supported by deepL
export const EPC_LANGUAGES = [
  Language.Bg,
  Language.Cs,
  Language.Da,
  Language.Nl,
  Language.En,
  Language.Et,
  Language.Fi,
  Language.De,
  Language.El,
  Language.Hu,
  Language.It,
  Language.Lv,
  Language.Lt,
  Language.Pl,
  Language.Pt,
  Language.Ro,
  Language.Sk,
  Language.Sl,
  Language.Es,
  Language.Sv,
  Language.Tr,
];

// TODO: Remove these fallbacks when Azure group management is handled properly

// App access groups
AzureGroupsConfig.AppAccessAdmin =
  AzureGroupsConfig.AppAccessAdmin || 'a34a24ef-1143-4837-aaaa-522129171815';
AzureGroupsConfig.AppAccessComp =
  AzureGroupsConfig.AppAccessComp || 'd08337e1-ba88-4779-8ffc-c49cdb1108b6';
AzureGroupsConfig.AppAccessDdude =
  AzureGroupsConfig.AppAccessDdude || '98723e13-15a4-441b-8a73-03b6ef1da98e';
AzureGroupsConfig.AppAccessEval =
  AzureGroupsConfig.AppAccessEval || 'e0123eaf-4a34-43f2-a8a5-48e0f477bae7';
AzureGroupsConfig.AppAccessFto =
  AzureGroupsConfig.AppAccessFto || '56e905d4-2e52-465f-a976-a23826f8953e';
AzureGroupsConfig.AppAccessOah =
  AzureGroupsConfig.AppAccessOah || '38237cdd-884a-4e0c-a68d-c5bc744398eb';
AzureGroupsConfig.AppAccessPat =
  AzureGroupsConfig.AppAccessPat || '3143e14d-a246-405f-98fc-65a2ed92ce98';
AzureGroupsConfig.AppAccessTsd =
  AzureGroupsConfig.AppAccessTsd || '88a1dcb1-967c-4983-89b6-c8bdf0339cf7';

// In-app permissions groups
AzureGroupsConfig.PermissionCompanyDocxTemplateEdit =
  AzureGroupsConfig.PermissionCompanyDocxTemplateEdit ||
  'b8209dfe-f537-4e2d-bc7a-bdbcfc0aa394';
AzureGroupsConfig.PermissionCompanyGlossaryEdit =
  AzureGroupsConfig.PermissionCompanyGlossaryEdit ||
  '660e8740-21af-414c-be79-73eab7bce1b9';
AzureGroupsConfig.PermissionCompanyOahTextBlockEdit =
  AzureGroupsConfig.PermissionCompanyOahTextBlockEdit ||
  '09c916d4-3289-4d84-ab28-f93727240c88';
AzureGroupsConfig.PermissionCompanyServiceProviderQuotaEdit =
  AzureGroupsConfig.PermissionCompanyServiceProviderQuotaEdit ||
  '026fbf29-b1e7-4479-92b5-f08747719453';
AzureGroupsConfig.PermissionCompanyTsdDelete =
  AzureGroupsConfig.PermissionCompanyTsdDelete ||
  '57b6c009-0fed-4e80-8de9-288fe76446f1';
AzureGroupsConfig.PermissionCompanyTsdEdit =
  AzureGroupsConfig.PermissionCompanyTsdEdit ||
  '43266dac-b761-4919-b3d2-b8f2720f0560';
AzureGroupsConfig.PermissionCompanyTsdExport =
  AzureGroupsConfig.PermissionCompanyTsdExport ||
  '90d9b53e-ee32-482d-b9ea-7dc731826aa0';
AzureGroupsConfig.PermissionCompanyTsdCompanyGroupEdit =
  AzureGroupsConfig.PermissionCompanyTsdCompanyGroupEdit ||
  'c6a6f5b2-293f-47f9-815e-54404b694d08';
AzureGroupsConfig.PermissionCompanyUseAi =
  AzureGroupsConfig.PermissionCompanyUseAi ||
  '1d8f0927-d5eb-465c-b08b-1ea708c7ac00';
AzureGroupsConfig.PermissionCompanyReceiveBugReport =
  AzureGroupsConfig.PermissionCompanyReceiveBugReport ||
  'c67d59ae-becb-4fe0-ac14-f14491f0cf92';

// Sys admin group
AzureGroupsConfig.PermissionSysAdm =
  AzureGroupsConfig.PermissionSysAdm || 'ee1e073c-4b68-43f1-b783-7e72b3711eb0';
