import { css } from 'styled-components';
import { Colors } from '../../../theme';

export default css`
  .claim {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
    position: relative;

    .number {
      display: flex;
      gap: 2px;
      user-select: none;
      background-color: transparent;
    }

    .old-claim-number {
      color: ${Colors.red500};
      text-decoration: line-through;
      user-select: none;
    }

    .content,
    & > [data-node-view-content='true'] {
      flex: 1;
      display: grid;
      grid-template-columns: auto 1fr;

      & > * {
        grid-column: 2;
      }
    }

    .note {
      font-size: 12px;
      font-style: italic;
      color: rgba(0, 0, 0, 0.5);
      bottom: -25px;
      position: absolute;
    }
    transition: opacity 0.2s;
    > .icn-drag {
      opacity: 0;
    }
    :hover {
      .icn-drag {
        opacity: 1;
      }
    }
  }

  [data-node-type='claim'] {
    &::after {
      content: attr(data-old-claim-number);
      color: ${Colors.red500};
      text-decoration: line-through;
      position: absolute;
      top: 18px;
      right: 100%;
    }
  }
`;
