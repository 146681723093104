import { v4 as uuid } from 'uuid';
import { T } from '../../index';
import {
  randomBoolean,
  randomDate,
  randomEnum,
  randomIntFromInterval,
  randomString,
} from '../utils';

export function range(length: number): number[] {
  return [...Array(length).keys()];
}

export function generateBaseDto(overrides?: T.BaseDto): T.BaseDto {
  return {
    id: uuid(),
    createdById: uuid(),
    createdOn: randomDate(new Date(2012, 0, 1)).toISOString(),
    modifiedOn: randomDate(new Date(2012, 0, 1)).toISOString(),
    modifiedById: uuid(),
    ...overrides,
  };
}

export function generateTsdTree(depth: number, currentDepth = 0): T.TsdNode {
  currentDepth = currentDepth === undefined ? 0 : currentDepth;
  const spread = randomIntFromInterval(1, 10);
  const node = generateTsdNode();

  if (currentDepth < depth) {
    for (let i = 0; i < spread; i++) {
      node.children.push(generateTsdTree(depth, currentDepth + 1));
    }
  }
  return node;
}

export function generateContact(overrides?: T.Contact): T.Contact {
  return {
    ...generateBaseDto(),
    displayName: randomString(),
    email: randomString(),
    phone: randomString(),
    company: randomString(),
    department: randomString(),
    representation: randomString(),
    city: randomString(),
    languages: [],
    firstName: randomString(),
    lastName: randomString(),
    middleName: randomString(),
    mobile: randomString(),
    nationality: randomString(),
    postcode: randomString(),
    socialMedia: [],
    title: randomString(),
    ...overrides,
  };
}

export function generateStakeholder(
  role: T.PatentApplicationStakeholderRole,
  overrides?: T.PatentApplicationStakeholder
): T.PatentApplicationStakeholder {
  return {
    ...generateBaseDto(),
    role,
    contact: generateContact(),
    ...overrides,
  };
}

export function generateStakeholderExaminer(): T.PatentApplicationStakeholder {
  return generateStakeholder(
    T.PatentApplicationStakeholderRole.InHouseCoCounsel
  );
}

export function generateStakeholderApplicant(): T.PatentApplicationStakeholder {
  return generateStakeholder(T.PatentApplicationStakeholderRole.Applicant);
}

export function generateStakeholderInventor(): T.PatentApplicationStakeholder {
  return generateStakeholder(T.PatentApplicationStakeholderRole.Inventor);
}

export function generateStakeholderLeadAttorney(): T.PatentApplicationStakeholder {
  return generateStakeholder(T.PatentApplicationStakeholderRole.InHouseCounsel);
}

export function generateStakeholderCoAttorney(): T.PatentApplicationStakeholder {
  return generateStakeholder(
    T.PatentApplicationStakeholderRole.InHouseCoCounsel
  );
}

export function generateOfficeActionListItem(
  overrides?: T.OfficeActionListItem
): T.OfficeActionListItem {
  return {
    ...generateBaseDto(),
    stakeholders: [],
    applicationCountryCode: 'EP',
    patentOffice: randomEnum(T.PatentOffice),
    currentStage: randomEnum(T.UploadStageIndex),
    currentStageState: randomEnum(T.ProcessStageState),
    applicationKind: 'A',
    patentApplicationId: uuid(),
    fullApplicationNumber: randomIntFromInterval(1, 10000).toString(),
    fullPublicationNumber: randomIntFromInterval(1, 10000).toString(),
    applicationNumber: randomIntFromInterval(1, 10000).toString(),
    daysLeft: randomIntFromInterval(-500, 500),
    daysPassed: randomIntFromInterval(-500, 500),
    dueDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    fileName: randomString(),
    applicantReference: randomString(),
    importProcessId: uuid(),
    workflowState: T.WorkflowState.InProgress,
    lossOfRights: randomEnum(T.LossOfRights),
    extensionOfTimeLimit: randomEnum(T.ExtensionOfTimeLimit),
    legalRemedies: {
      furtherProcessing: randomEnum(T.LegalRemediesFurtherProcessing),
      reestablishment: randomEnum(T.LegalRemediesReestablishment),
      appeal: randomEnum(T.LegalRemediesAppeal),
      other: range(10).map(() => randomEnum(T.LegalRemediesOther)),
    },
    lawFirmReference: null,
    domain: null,
    tsdS: null,
    numCitations: null,
    projectReference: null,
    productReference: null,
    value: null,
    applicationDate: null,
    publicationDate: null,
    priorityNumber: null,
    priorityDate: null,
    expirationDate: null,
    uploadDate: null,
    figureUri: null,
    title: randomString(),
    filingType: randomEnum(T.FilingType),
    ...overrides,
  };
}

export function generateTsd(overrides?: T.Tsd): T.Tsd {
  return {
    ...generateBaseDto(),
    name: randomString(),
    description: randomString(),
    names: {
      [T.Language.De]: {
        language: T.Language.De,
        value: randomString(),
        isAutoTranslation: false,
      },
    },
    nodeCount: 0,
    rootNode: generateTsdTree(3),
    isPublished: randomBoolean(),
    ...overrides,
  };
}

export function generateTsdNode(overrides?: T.TsdNode): T.TsdNode {
  return {
    ...generateBaseDto(),
    name: randomString(),
    tsdId: uuid(),
    parentNodeId: uuid(),
    tsdElementId: uuid(),
    children: [],
    sequence: randomIntFromInterval(0, 3000),
    ...overrides,
  };
}

export function generateClaim(overrides?: Partial<T.Claim>): T.Claim {
  const text = randomString(300);
  const parts: T.ClaimPart[] = [];
  const dependencies = [1];
  return {
    ...generateBaseDto(),
    text,
    parts,
    dependencies,
    claimNumber: randomIntFromInterval(1, 40),
    claimTraceId: randomString(100),
    advantage: null,
    ...overrides,
  };
}

export function generateDataPoint(overrides?: T.DataPoint): T.DataPoint {
  return {
    name: randomString(),
    x: randomIntFromInterval(0, 100),
    y: randomIntFromInterval(0, 100),
    ...overrides,
  };
}

export function generateDescriptionParagraphs(overrides?: string[]): string[] {
  const result = range(100).map(() => randomString(100));

  if (overrides) {
    return [...result, ...overrides];
  }
  return result;
}

export function generatePatentRevision(
  overrides?: T.PatentRevision
): T.PatentRevision {
  return {
    ...generateBaseDto(),
    patentApplicationId: uuid(),
    previousRevisionId: uuid(),
    baseRevisionId: uuid(),
    comment: randomString(100),
    revisionName: randomString(20),
    combinationInfo: '',
    amendmentDescription: randomString(100),
    isFiled: randomBoolean(),
    description: randomString(1000),
    descriptionContentId: uuid(),
    title: randomString(10),
    abstract: randomString(10),
    language: randomEnum(T.Language),
    claims: range(10).map((_, index) =>
      generateClaim({ claimNumber: index + 1 })
    ),
    figureDescriptions: {},
    figureImages: [],
    referenceSignList: {},
    importedDocuments: {},
    ...overrides,
  };
}

const NUMBER_OF_PAGES = 10;

export function generateLocation(
  overrides?: Partial<T.DocumentLocation>
): T.DocumentLocation {
  return {
    pageNumber: randomIntFromInterval(0, NUMBER_OF_PAGES),
    pageLocation: {
      width: randomIntFromInterval(1000, 2000),
      height: randomIntFromInterval(1000, 2000),
      x: randomIntFromInterval(0, 500),
      y: randomIntFromInterval(0, 500),
    },
    ...overrides,
  };
}

export function generateAnnotation(
  overrides?: Partial<T.Annotation>
): T.Annotation {
  return {
    popUpText: randomEnum(T.TOaDetailsFields).toString(),
    navigationLinks: [],
    propertyId: randomString(10),
    locations: range(5).map(() => generateLocation()),
    ...overrides,
  };
}

export function generateOaDocumentMetadata(
  overrides?: T.DocumentMetadata
): T.DocumentMetadata {
  return {
    ...generateBaseDto(),
    source: randomEnum(T.DocumentSource),
    name: randomString(40),
    numPages: NUMBER_OF_PAGES,
    contentLanguage: randomEnum(T.Language),
    uri: `/${randomString(40)}`,
    pdfUri: `/${randomString(40)}`,
    thumbnailUri: `/${randomString(40)}`,
    selectableTextUris: null,
    annotations: [
      generateAnnotation({
        propertyId: 'multiple-match-property',
      }),
      generateAnnotation({
        propertyId: 'multiple-match-property',
      }),
      generateAnnotation({
        propertyId: 'multiple-match-property',
      }),
      ...range(NUMBER_OF_PAGES).map(() => generateAnnotation()),
    ],
    blobResource: {
      ...generateBaseDto(),
      fileName: randomString(30),
      uri: randomString(50),
      contentSize: randomIntFromInterval(1, 20000),
      contentType: randomString(20),
    },
    ...overrides,
  };
}

export function generateUserProcessStage(
  overrides?: Partial<T.UserProcessStage | T.UserProcessReviewStage>
): T.UserProcessStage | T.UserProcessReviewStage {
  return {
    ...generateBaseDto(),
    uploadId: uuid(),
    uploadUri: `/${randomString(30)}`,
    fileName: randomString(10),
    fileSize: randomIntFromInterval(1, 20000),
    name: randomString(10),
    executionOrder: randomIntFromInterval(0, 10),
    nextStages: [randomIntFromInterval(0, 10)],
    state: randomEnum(T.ProcessStageState),
    progressPercentage: randomIntFromInterval(0, 100),
    progressDescription: randomString(100),
    warnings: {},
    isLastStage: randomBoolean(),
    ...overrides,
  };
}
const NUMBER_OF_STAGES = 3;
const NUMBER_OF_FEATURES = 100;

export function generateUserProcess(
  overrides?: Partial<T.OfficeActionImportProcess>
): T.OfficeActionImportProcess {
  return {
    ...generateBaseDto(),
    patentApplicationId: uuid(),
    currentStage: randomIntFromInterval(0, NUMBER_OF_STAGES),
    stages: [
      ...range(NUMBER_OF_STAGES).map((i) =>
        generateUserProcessStage({
          executionOrder: i,
        })
      ),
    ],
    ...overrides,
  };
}

export function generateDocumentBlob() {
  return new Blob(['(⌐□_□)'], {
    type: 'document/pdf',
  });
}

export function generateOaListFilters(
  overrides?: Partial<T.OfficeActionListFilters>
): T.OfficeActionListFilters {
  return {
    applicants: {
      [randomString()]: generateContact().displayName || '',
    },
    inHouseStakeholders: {
      [randomString()]: generateContact().displayName || '',
    },
    patentOffices: range(10).map(() => randomEnum(T.PatentOffice)),
    inventors: {
      [randomString()]: generateContact().displayName || '',
    },
    externalCounsels: {
      [randomString()]: generateContact().displayName || '',
    },
    ...overrides,
  };
}

export function generatePatentHistoryItem(
  overrides?: Partial<T.PatentRevisionHistoryItem>
): T.PatentRevisionHistoryItem {
  return {
    id: uuid(),
    previousRevisionId: uuid(),
    revisionName: randomString(10),
    isFiled: randomBoolean(),
    ...overrides,
  };
}

export function generateObjection(
  overrides?: Partial<T.Objection>
): T.Objection {
  const claimNumbers = range(randomIntFromInterval(0, 5)).map(() =>
    randomIntFromInterval(0, NUMBER_OF_FEATURES)
  );
  return {
    objectionTexts: range(3).map(() => randomString(100)),
    claimNumbers,
    claimNumbersAsText: claimNumbers.toString(),
    ...overrides,
  };
}

export function generateObjections(
  overrides?: Partial<T.Objections>
): T.Objections {
  return {
    Patentability: generateObjection(),
    Novelty: randomBoolean() ? generateObjection() : undefined,
    Inventiveness: randomBoolean() ? generateObjection() : undefined,
    Clarity: generateObjection(),
    UnsupportedAmendments: randomBoolean() ? generateObjection() : undefined,
    Formalities: randomBoolean() ? generateObjection() : undefined,
    InsufficientDisclosure: randomBoolean() ? generateObjection() : undefined,
    IndustrialApplicability: randomBoolean() ? generateObjection() : undefined,
    NonUnity: randomBoolean() ? generateObjection() : undefined,
    ...overrides,
  };
}

export function generateClaimFeatureReference(
  overrides?: Partial<T.ClaimFeatureReference>
): T.ClaimFeatureReference {
  return {
    feature: randomString(),
    reference: randomString(),
    ...overrides,
  };
}

export function generatePatentIdentifier(
  overrides?: Partial<T.PatentIdentifier>
): T.PatentIdentifier {
  return {
    countryCode: randomString(),
    number: randomString(),
    kind: randomString(),
    ...overrides,
  };
}
export function generateCitedPriorArt(
  overrides?: Partial<T.OfficeActionCitedPriorArt>
): T.OfficeActionCitedPriorArt {
  return {
    citationNumber: randomIntFromInterval(0, 10),
    citedPatentPublicationId: randomString(),
    citationNumberFormat: randomString(),
    citedLiterature: randomString(),
    isNonPatentLiterature: randomBoolean(),
    citedDocumentId: uuid(),
    ...overrides,
  };
}

export function generateClaimFeatureMatrix(
  overrides?: Partial<T.ClaimFeatureCitationParserOutput>
): T.ClaimFeatureCitationParserOutput {
  return {
    citedPriorArtNumbers: randomIntFromInterval(0, 10).toString(),
    claimNumbers: randomIntFromInterval(0, 10).toString(),
    context: randomString(100),
    claimFeatureReferences: range(NUMBER_OF_FEATURES).map(() =>
      generateClaimFeatureReference()
    ),
    ...overrides,
  };
}

export function generateResponseLetterSection(
  overrides?: Partial<T.ResponseLetterSectionContent>
): T.ResponseLetterSectionContent {
  return {
    headline: randomString(20),
    text: randomString(100),
    objectionAgreed: randomBoolean(),
    included: randomBoolean(),
    order: randomIntFromInterval(0, 10),
    ...overrides,
  };
}

export function generateResponseLetter(
  overrides?: Partial<T.ResponseLetter>
): T.ResponseLetter {
  return {
    ...generateBaseDto(),
    patentRevisionId: randomString(),
    claimsAmendedOnSamePatentRevisionSinceLastChange: randomBoolean(),
    claimsAmendedOnOtherPatentRevisionSinceLastChange: randomBoolean(),
    content: {
      [T.ResponseLetterSection.General]: generateResponseLetterSection(),
      [T.ResponseLetterSection.ClaimAmendmentDescription]:
        generateResponseLetterSection(),
      [T.ResponseLetterSection.FormalityObjections]:
        generateResponseLetterSection(),
      [T.ResponseLetterSection.Priority]: generateResponseLetterSection(),
      [T.ResponseLetterSection.ClarityObjections]:
        generateResponseLetterSection(),
      [T.ResponseLetterSection.UnsupportedAmendments]:
        generateResponseLetterSection(),
      [T.ResponseLetterSection.PatentabilityObjections]:
        generateResponseLetterSection(),
      [T.ResponseLetterSection.NoveltyObjections]:
        generateResponseLetterSection(),
      [T.ResponseLetterSection.InventivenessObjections]:
        generateResponseLetterSection(),
      [T.ResponseLetterSection.InsufficientDisclosureObjections]:
        generateResponseLetterSection(),
      [T.ResponseLetterSection.IndustrialApplicabilityObjections]:
        generateResponseLetterSection(),
      [T.ResponseLetterSection.NonUnityObjections]:
        generateResponseLetterSection(),
      [T.ResponseLetterSection.ClosingStatement]:
        generateResponseLetterSection(),
      [T.ResponseLetterSection.Attachments]: generateResponseLetterSection(),
    },
    ...overrides,
  };
}

export function generateClaimCitationMatrix(
  overrides?: Partial<T.ClaimCitationParserOutput>
): T.ClaimCitationParserOutput {
  return {
    citedPriorArtNumbers: randomIntFromInterval(0, 10).toString(),
    claimNumbers: randomIntFromInterval(0, 10).toString(),
    context: randomString(100),
    ...overrides,
  };
}

export function generateOfficeActionType(
  overrides?: Partial<T.OfficeActionType>
): T.OfficeActionType {
  return {
    form: randomString(),
    communication: randomString(),
    ...overrides,
  };
}

export function generateParsedOfficeAction(
  overrides?: Partial<T.OfficeActionParserOutput>
): T.OfficeActionParserOutput {
  return {
    ...generateBaseDto(),
    officeActionId: uuid(),
    applicationCountryCode: randomString(),
    applicationNumber: randomString(),
    applicationKind: randomString(),
    applicationDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    applicant: randomString(),
    language: randomEnum(T.Language),
    officeActionDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    dueDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    officeActionTypes: { [randomString()]: randomString() },
    examiner: randomString(),
    priority: randomString(),
    lastResortDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    reminderDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    priorityDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    personSkilledInTheArt: randomString(),
    internalReference: randomString(),
    uploadProcessId: uuid(),
    ownerGroupId: uuid(),
    suggestedAmendments: range(10).map(() => randomString(20)),
    closestPriorArt: range(10).map(() => randomString(20)),
    dueDateMatchText: randomString(20),
    applicationNumberMatchText: randomString(20),
    priorityDateMatchText: randomString(20),
    patentOfficeMatchText: randomString(20),
    applicationDateMatchText: randomString(20),
    officeActionDateMatchText: randomString(20),
    patentOffice: randomEnum(T.PatentOffice),
    citedPriorArt: range(10).map(() => generateCitedPriorArt()),
    objections: generateObjections(),
    claimFeatureMatrix: range(2).map(() => generateClaimFeatureMatrix()),
    claimCitationMatrix: range(2).map(() => generateClaimCitationMatrix()),
    usptoConfirmationNumber: randomString(20),
    usptoArtUnit: randomString(20),
    ...overrides,
  };
}

export function generatePatentApplication(
  overrides?: Partial<T.PatentApplication>
): T.PatentApplication {
  return {
    ...generateBaseDto(),
    applicantReference: randomString(),
    lawFirmReference: randomString(),
    projectReference: randomString(),
    title: randomString(),
    mostImportantAspect: randomString(),
    language: randomEnum(T.Language),
    dueDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    engineeringDomain: randomEnum(T.EngineeringDomain),
    currentState: randomEnum(T.PatentLifeCycleState),
    patentPublicationFamilyId: uuid(),
    applicationDocumentId: uuid(),
    stakeholders: range(10).map(() =>
      generateStakeholder(randomEnum(T.PatentApplicationStakeholderRole))
    ),
    publicationIds: range(10).map(() => uuid()),
    officeActionIds: range(10).map(() => uuid()),
    ...overrides,
  };
}

export function generateTranslation(
  overrides?: Partial<T.DocTranslations>
): T.DocTranslations {
  return {
    [T.Language.En]: generateOaDocumentMetadata(),
    [T.Language.De]: generateOaDocumentMetadata(),
    ...overrides,
  };
}

export function generateAnnotationSets(): Record<string, string> {
  return {
    [uuid()]: randomString(),
    [uuid()]: randomString(),
  };
}

export function generateOfficeAction(
  overrides?: Partial<T.OfficeAction>
): T.OfficeAction {
  return {
    ...generateBaseDto(),
    patentApplicationId: uuid(),
    uploadProcessId: uuid(),
    workflowState: T.WorkflowState.InProgress,
    language: T.Language.En,
    lastResortDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    reminderDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    numberOfClaims: randomIntFromInterval(1, 20),
    closestPriorArt: [],
    citedPriorArtDisclosure: [],
    distinguishingFeatures: [],
    technicalEffects: [],
    objectiveTechnicalProblems: [],
    patentOffice: T.PatentOffice.DPMA,
    applicationCountryCode: 'EP',
    applicationNumber: randomIntFromInterval(1, 10000).toString(),
    applicationKind: 'A',
    applicationDate: randomDate(new Date(2012, 0, 1)).toISOString(),
    personSkilledInTheArt: randomString(),
    officeActionDate: randomDate(new Date(2012, 2, 1)).toISOString(),
    dueDate: randomDate(new Date(2012, 4, 1)).toISOString(),
    priorityDate: randomDate(new Date(2012, 4, 1)).toISOString(),
    priority: randomString(),
    officeActionTypes: { [randomString()]: randomString() },
    internalReference: randomString(),
    objections: {
      [T.OfficeActionObjectionCategory.Novelty]: {
        objectionTexts: [randomString(), randomString()],
        claimNumbers: [1],
        claimNumbersAsText: '1',
      },
      [T.OfficeActionObjectionCategory.Clarity]: {
        objectionTexts: [randomString(), randomString()],
        claimNumbers: [2, 3],
        claimNumbersAsText: '2, 3',
      },
    },
    suggestedAmendments: [],
    ...overrides,
  };
}

export function generatePatentRevisions(): T.PatentRevision[] {
  const patentRevisions: T.PatentRevision[] = [
    generatePatentRevision(),
    generatePatentRevision(),
    generatePatentRevision(),
    generatePatentRevision(),
  ];
  patentRevisions[1].previousRevisionId = patentRevisions[0].id;
  patentRevisions[2].previousRevisionId = patentRevisions[0].id;
  patentRevisions[3].previousRevisionId = patentRevisions[1].id;
  return patentRevisions;
}

export function generateTextBlock(
  overrides?: Partial<T.TextBlock>
): T.TextBlock {
  return {
    ...generateBaseDto(),
    section: randomEnum(T.ResponseLetterSection),
    textType: randomEnum(T.ResponseLetterTextType),
    domains: range(10)
      .map(() => randomEnum(T.EngineeringDomain))
      .filter((d) => d !== T.EngineeringDomain.DomainIndependent),
    claimsAmended: randomBoolean(),
    order: randomIntFromInterval(0, 1000),
    keywordPatterns: range(10).map(() => randomString()),
    text: {
      [T.Language.En]: randomString(),
      [T.Language.Fr]: randomString(),
      [T.Language.De]: randomString(),
    },
    addByDefault: randomBoolean(),
    libraryType: randomEnum(T.TextBlockLibraryType),
    patentOffice: randomEnum(T.PatentOffice),
    ...overrides,
  };
}

export function generateAadUser(overrides?: Partial<T.AadUser>): T.AadUser {
  return {
    id: uuid(),
    displayName: `${randomString()} ${randomString()}`,
    givenName: randomString(),
    surname: randomString(),
    mail: `${randomString()}@test.com`,
    mobilePhone: `+${randomIntFromInterval(1, 10000000)}`,
    ...overrides,
  };
}

export function generateSession(overrides?: Partial<T.Session>): T.Session {
  return {
    userId: uuid(),
    userName: randomString(),
    ownerGroupId: uuid(),
    ownerGroupName: randomString(),
    fullName: randomString(),
    email: 'test@user.com',
    azureGroups: [
      AzureGroupsConfig.PermissionCompanyTsdEdit,
      AzureGroupsConfig.PermissionCompanyTsdDelete,
      AzureGroupsConfig.PermissionCompanyTsdExport,
      AzureGroupsConfig.PermissionCompanyTsdCompanyGroupEdit,
    ],
    ...overrides,
  };
}

export function generateOwnerGroupSettings(
  overrides?: Partial<T.OwnerGroupSettings>
): T.OwnerGroupSettings {
  return {
    id: uuid(),
    ownerGroupId: uuid(),
    createdOn: randomDate(new Date(2021, 0, 1)).toISOString(),
    tsdLanguages: [T.Language.De, T.Language.En],
    inComPortalServerUrl: '',
    inComPortalIntegrationActive: false,
    patBaseEnabled: false,
    ...overrides,
  };
}

export function generateTranslationService(
  overrides?: Partial<T.PayPerUseServiceStatus>
): T.PayPerUseServiceStatus {
  return {
    settings: {
      monthlyLimit: randomIntFromInterval(100000, 1000000),
      singleRequestLimit: randomIntFromInterval(10000, 50000),
    },
    maxMonthlyCost: randomIntFromInterval(100, 1000),
    quantityThisMonth: randomIntFromInterval(10000, 100000),
    costThisMonth: randomIntFromInterval(100, 1000),
    usageLast30Days: [],
    usagePerMonth: [],
    ...overrides,
  };
}
