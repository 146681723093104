import { css } from 'styled-components';
import { Colors } from '../../../theme';

export default css`
  .claim-reference {
    color: ${Colors.blue600};
    font-size: 15px;
    font-weight: 700;
  }
`;
