import { Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { PatentOffice } from '../../../types';

interface Options {
  editorContentId?: string;
  ignoreTrackChanges?: boolean;
  includeClaimAdvantage?: boolean;
  patentOffice?: PatentOffice;
  undoClaim?: (claimId: string) => void;
  redoClaim?: (claimId: string) => void;
  retranslateClaim?: (claimId: string) => void;
}

function removetrackChangesAttr(tr: Transaction) {
  let changed = false;
  tr.doc.descendants((node, pos) => {
    if (node.type.name !== 'claim') {
      return;
    }
    const attrs = {
      ...node.attrs,
    };

    if (attrs.isDeleted) {
      attrs.isDeleted = false;
    }
    if (attrs.isInserted) {
      attrs.isInserted = false;
    }
    if (attrs.baseClaimNumber) {
      attrs.baseClaimNumber = undefined;
    }

    const before = JSON.stringify(node.attrs);
    const after = JSON.stringify(attrs);

    if (before !== after) {
      changed = true;
      tr.setNodeMarkup(pos, undefined, attrs);
    }
  });

  return changed;
}

interface ClaimsPluginState {
  ignoreTrackChanges: boolean;
  includeClaimAdvantage: boolean;
  changed: boolean;
  editorContentId: string;
  patentOffice?: PatentOffice;
  undoClaim?: (claimId: string) => void;
  redoClaim?: (claimId: string) => void;
  retranslateClaim?: (claimId: string) => void;
}

const claimsPluginKey = new PluginKey<ClaimsPluginState>('claims_plugin');
const claimsPlugin = (options?: Options) =>
  new Plugin<ClaimsPluginState>({
    key: claimsPluginKey,
    state: {
      init: (_, state) => {
        const changed = options?.ignoreTrackChanges
          ? removetrackChangesAttr(state.tr)
          : false;
        return {
          editorContentId: options?.editorContentId ?? '',
          ignoreTrackChanges: options?.ignoreTrackChanges ?? false,
          includeClaimAdvantage: options?.includeClaimAdvantage ?? false,
          changed,
          undoClaim: options?.undoClaim,
          redoClaim: options?.redoClaim,
          retranslateClaim: options?.retranslateClaim,
          patentOffice: options?.patentOffice,
        };
      },
      apply: (tr, value) => {
        if (!options?.ignoreTrackChanges) {
          return value;
        }
        if (!tr.docChanged) {
          return value;
        }
        const changed = removetrackChangesAttr(tr);
        return {
          ...value,
          changed,
        };
      },
    },
    appendTransaction: (transactions, oldState, newState) => {
      const isDocChanged = transactions.some((tr) => tr.docChanged);
      if (!isDocChanged) {
        return null;
      }
      if (!options?.ignoreTrackChanges) {
        return null;
      }
      const pluginState = claimsPluginKey.getState(newState);
      if (pluginState?.changed) {
        return newState.tr;
      }
      return null;
    },
  });

claimsPlugin.key = claimsPluginKey;

export default claimsPlugin;
