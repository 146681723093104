import { EditorState, Transaction } from 'prosemirror-state';
import UICommand from '../../toolbar/commands/UICommand';
import { findNextNodeOfType } from '../../util';
import { CLAIM_PART } from '../../schema/nodes/nodeNames';

export type AlignType = 'left' | 'center' | 'right';

class TextAlignCommand extends UICommand {
  _type: AlignType;

  constructor(type: AlignType) {
    super();
    this._type = type;
  }

  execute = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void
  ): boolean => {
    const { schema, tr, selection } = state;
    const { from, to } = selection;
    let modified = false;

    state.doc.nodesBetween(from, to, (node, pos) => {
      const nodeAttrs = { ...node.attrs, align: this._type };

      if (node.type === schema.nodes.paragraph) {
        tr.setNodeMarkup(pos, null, nodeAttrs);
        modified = true;
      }

      if (node.type === schema.nodes.claimPart) {
        tr.setNodeMarkup(pos, null, {
          ...nodeAttrs,
          startNewParagraph: true,
        });
        const nextClaimPart = findNextNodeOfType(state.doc, pos, CLAIM_PART);
        if (nextClaimPart) {
          tr.setNodeMarkup(nextClaimPart.pos, null, {
            ...nextClaimPart.node.attrs,
            startNewParagraph: true,
          });
        }
        modified = true;
      }
    });

    if (!modified) {
      return false;
    }

    if (dispatch) {
      dispatch(tr);
    }

    return true;
  };
}

export default TextAlignCommand;
