import { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { DocumentMetadata, DocumentSource } from '../../types';
import { QueryKey } from '../../queries';
import { getMetadata } from '../../api/document';
import { queryClient } from '../../services/queryClient';

interface HookShape {
  (props: {
    viewerIndex?: number;
    selectedAnnotationSets: Record<string, boolean>;
    defaultDocument: DocumentMetadata;
    onDocumentChange?: (d: DocumentMetadata, viewerIndex?: number) => void;
  }): {
    currentDocument: DocumentMetadata;
    invalidateQuery: () => void;
    handleDocChange: (d: DocumentMetadata) => void;
  };
}

const useCurrentDocMetadata: HookShape = ({
  viewerIndex,
  defaultDocument,
  selectedAnnotationSets,
  onDocumentChange,
}) => {
  const shouldFetchDoc = useRef(
    defaultDocument.source !== DocumentSource.Generated
  );

  const [currentDocument, setCurrentDocument] =
    useState<DocumentMetadata>(defaultDocument);

  const [documentId, setDocumentId] = useState<string>(defaultDocument?.id);

  const makeQueryKey = (index: number | undefined) => [
    QueryKey.DocumentMetadata,
    index,
    documentId,
    JSON.stringify(selectedAnnotationSets),
  ];

  const { isIdle, isLoading } = useQuery(
    makeQueryKey(viewerIndex),
    () => getMetadata(documentId, selectedAnnotationSets),
    {
      onSuccess: (m: DocumentMetadata) => {
        handleDocChange(m);
      },
      initialData: currentDocument,
      enabled: shouldFetchDoc.current,
    }
  );

  const handleDocChange = (d: DocumentMetadata) => {
    if (d.source === DocumentSource.Generated) {
      shouldFetchDoc.current = false;
      queryClient.setQueryData([QueryKey.DocumentMetadata, d.id], d);
    } else {
      shouldFetchDoc.current = true;
    }
    onDocumentChange?.(d, viewerIndex);
    setCurrentDocument(d);
    setDocumentId(d.id);
  };

  const invalidateQuery = () => {
    // invalidate queries for all SingleDocument components inside MultiDocViewer
    queryClient.invalidateQueries(makeQueryKey(undefined));
    queryClient.invalidateQueries(makeQueryKey(0));
    queryClient.invalidateQueries(makeQueryKey(1));
    queryClient.invalidateQueries(makeQueryKey(2));
  };

  return {
    handleDocChange,
    currentDocument,
    invalidateQuery,
    isLoading,
    isIdle,
  };
};

export default useCurrentDocMetadata;
