import { T } from '../../index';
import Api from './base';

const getApiBase = (isSysAdmin: boolean) =>
  isSysAdmin ? '/v1/sysadmin' : '/v1/admin';

export const getCompanyLicenses = (): Promise<T.License[]> =>
  Api.get<T.License[]>(`${getApiBase(true)}/licenses`).then(
    (response) => response.data
  );

export const getGroupMembers: T.PaginatedApiFunction<
  T.AzureUser,
  T.UserSearchQuery
> = (params: T.UserSearchQuery): Promise<T.AzureUser[]> =>
  Api.post<T.AzureUser[]>(
    `${getApiBase(params.isSysAdmin ?? false)}/users/search`,
    {
      ...params,
    }
  ).then((response) => response.data);

export const getCompanyUsersByGroups: T.PaginatedApiFunction<
  T.AzureUser,
  { companyGroupId: string; azureGroupIds: string[]; isSysAdmin: boolean }
> = ({ companyGroupId, azureGroupIds, isSysAdmin }): Promise<T.AzureUser[]> =>
  Api.post<T.AzureUser[]>(
    `${getApiBase(isSysAdmin)}/users/groups/${companyGroupId}/search`,
    azureGroupIds
  ).then((response) => response.data);

export const addUsersToGroup = (
  azureGroupId: string,
  userIds: string[],
  isSysAdmin: boolean
): Promise<T.AzureUser[]> =>
  Api.put<T.AzureUser[]>(
    `${getApiBase(isSysAdmin)}/groups/${azureGroupId}/users/add`,
    userIds
  ).then((response) => response.data);

export const removeUsersFromGroup = (
  azureGroupId: string,
  userIds: string[],
  isSysAdmin: boolean
): Promise<T.AzureUser[]> =>
  Api.put<T.AzureUser[]>(
    `${getApiBase(isSysAdmin)}/groups/${azureGroupId}/users/remove`,
    userIds
  ).then((response) => response.data);

export const setUserInAppPermissionGroups = (
  userId: string,
  azureGroupIds: string[],
  isSysAdmin: boolean
): Promise<T.AzureUser> =>
  Api.put<T.AzureUser>(
    `${getApiBase(isSysAdmin)}/users/${userId}/in-app-permissions`,
    azureGroupIds
  ).then((response) => response.data);

export const setUsersInAppPermissionGroups = (
  data: T.MultiUserAdminUpdate,
  isSysAdmin: boolean
): Promise<T.AzureUser[]> =>
  Api.put<T.AzureUser[]>(
    `${getApiBase(isSysAdmin)}/users/in-app-permissions`,
    data
  ).then((response) => response.data);

export const setAllUsersInAppPermissionGroups = (
  data: T.AllUserAdminUpdate,
  isSysAdmin: boolean
): Promise<void> =>
  Api.put<void>(
    `${getApiBase(isSysAdmin)}/users/all/in-app-permissions`,
    data
  ).then((response) => response.data);

export const setUserAppAccessGroups = (
  userId: string,
  azureGroupIds: string[],
  isSysAdmin: boolean
): Promise<T.AzureUser> =>
  Api.put<T.AzureUser>(
    `${getApiBase(isSysAdmin)}/users/${userId}/app-access`,
    azureGroupIds
  ).then((response) => response.data);

export const setUsersAppAccessGroups = (
  data: T.MultiUserAdminUpdate,
  isSysAdmin: boolean
): Promise<T.AzureUser[]> =>
  Api.put<T.AzureUser[]>(
    `${getApiBase(isSysAdmin)}/users/app-access`,
    data
  ).then((response) => response.data);

export const setAllUsersAppAccessGroups = (
  data: T.AllUserAdminUpdate,
  isSysAdmin: boolean
): Promise<void> =>
  Api.put<void>(`${getApiBase(isSysAdmin)}/users/all/app-access`, data).then(
    (response) => response.data
  );

export const deleteUsers = (
  userIds: string[],
  isSysAdmin: boolean
): Promise<void> =>
  Api.post<void>(`${getApiBase(isSysAdmin)}/users/delete`, userIds).then(
    (response) => response.data
  );

export const inviteUsers = (
  data: T.InviteUsersPayload,
  isSysAdmin: boolean
): Promise<void> =>
  Api.post<void>(`${getApiBase(isSysAdmin)}/users/invite`, data).then(
    (response) => response.data
  );

export const countGroupMembers = (
  data: T.UserSearchQuery,
  isSysAdmin: boolean
): Promise<number> =>
  Api.post<number>(`${getApiBase(isSysAdmin)}/users/count`, data).then(
    (response) => response.data
  );
