import { Node } from 'prosemirror-model';
import { EditorState, Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

interface Options {
  userName: string;
}
interface ClaimTextModificationDetectorPluginState {
  dirtyClaimNodes: Node[];
  initialNodes: Record<string, Node>;
  userName: string;
}

export const claimTextModificationDetectorPluginKey =
  new PluginKey<ClaimTextModificationDetectorPluginState>(
    'claim-text-modification-detector'
  );

const claimTextModificationDetector = (options: Options) =>
  new Plugin<ClaimTextModificationDetectorPluginState>({
    key: claimTextModificationDetectorPluginKey,
    state: {
      init: (_, editorState: EditorState) => {
        const initialNodes: Record<string, Node> = {};

        editorState.doc.descendants((node) => {
          if (node.type.name === 'claim') {
            initialNodes[node.attrs.nodeId] = node;
          }
        });

        return {
          dirtyClaimNodes: [],
          initialNodes,
          userName: options.userName,
        };
      },
      apply(tr, value) {
        const meta = tr.getMeta(claimTextModificationDetectorPluginKey);
        if (meta) {
          return {
            dirtyClaimNodes: meta.dirtyClaimNodes,
            initialNodes: meta.initialNodes || value.initialNodes,
            userName: options.userName,
          };
        }

        return value;
      },
    },
    view: () => new ClaimTextModificationDetectorView(),
  });

class ClaimTextModificationDetectorView {
  update(editorView: EditorView, prevState: EditorState) {
    if (editorView.state.doc.eq(prevState.doc)) {
      return;
    }

    const pluginState = claimTextModificationDetectorPluginKey.getState(
      editorView.state
    );

    if (!pluginState?.initialNodes) {
      return;
    }

    const dirtyClaimNodes: Node[] = [];
    const tr = editorView.state.tr;

    editorView.state.doc.descendants((node, pos) => {
      if (node.type.name !== 'claim') {
        return;
      }

      const nodeIds = Object.keys(pluginState.initialNodes);

      if (!nodeIds.includes(node.attrs.nodeId)) {
        return;
      }

      const initialNode = pluginState.initialNodes[node.attrs.nodeId];

      if (initialNode.textContent !== node.textContent) {
        const newAttrs = {
          ...node.attrs,
          comment: `Edited by ${pluginState.userName}`,
        };

        tr.setNodeMarkup(pos, null, newAttrs);
        dirtyClaimNodes.push(node);
      }
    });

    if (tr.docChanged) {
      editorView.dispatch(
        tr.setMeta(claimTextModificationDetectorPluginKey, { dirtyClaimNodes })
      );
    }
  }
}

export default claimTextModificationDetector;
